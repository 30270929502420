import {getClientConfig} from "../helpers"
import {openPopup} from "./client"
import {Translation} from "../store/language/types";

export const getConfigAndOpenClient = async (ocClientRef: Window | null) => {
  if(!ocClientRef || ocClientRef.closed) {
    const config = await getClientConfig()
    const dataForLogin = {
      user: JSON.parse(sessionStorage.getItem('user')!),
      token: sessionStorage.getItem('token')!,
      config
    }
    openPopup(dataForLogin)
  }
  else {
    ocClientRef.focus()
  }
}

export const verifyOCClientOpenedAndExecuteAction = (ocClientRef: Window | null, translation: Translation, callback: () => void) => {
  if(!ocClientRef || ocClientRef.closed) {
    alert(translation.input.clientNotOpened)
  }
  else {
    callback()
    ocClientRef.focus()
  }
}