import logger  from '../../helpers/logger'

/**
 * Gestione della cache per le chiamate rest in modo da non fare più volte la stessa chiamata in pochi secondi
 */
class CacheManager {
  private _cache: Map<string, { timeout: number; results: Promise<any> | any }>

  constructor() {
    this._cache = new Map()
  }

  /**
   * recupera una entry dalla cache, azzerando il timeout se l'entry esiste
   * @param url
   */
  get(url: string): any {
    const entry = this._cache.get(url)
    if (entry) {
      clearTimeout(entry.timeout)
      entry.timeout = this.cleanUpFactory(url, 10000)
      return entry.results
    } else return null
  }

  /**
   * elimina le entry dalla cache che sono associate ad un url
   * @param url
   */
  deleteEntities(url: string) {
    const entities = [...this._cache.keys()]
    entities.forEach((entity) => {
      if(entity.includes(url)) this._cache.delete(entity)
    })
  }

  /**
   * salva una nuova entry nella cache
   * @param url
   * @param obj
   */
  set(url: string, obj: any) {
    const entry = this._cache.get(url)
    if (entry) {
      clearTimeout(entry.timeout)
    }
    this._cache.set(url, { timeout: this.cleanUpFactory(url, 2000), results: obj })
  }

  /**
   * genera funzioni di cleanup
   * @param url
   * @param timer
   */
  cleanUpFactory(url: string, timer: number) {
    return window.setTimeout(() => {
      logger.silly(`Removing ${url} from cache`)
      this._cache.delete(url)
    }, timer)
  }
}

const cacheManager = new CacheManager()

export default cacheManager
