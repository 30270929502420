import React from 'react'
import { AutoSizer } from 'react-virtualized'
import { MuiTableProps, VirtualizedTable } from './VirtualizedTable'

type Props<RowData> = Omit<MuiTableProps<RowData>, 'height' | 'width'>

export function AutoSizeTable<RowData>(props: Props<RowData>) {
  return (
    // @ts-ignore
    <AutoSizer>
      {({ width, height }) => <VirtualizedTable<RowData> resizable width={width} height={height} {...props} />}
    </AutoSizer>
  )
}
