import {Card, Typography} from '@mui/material'
import { SxProps } from '@mui/system'
import React, {ReactNode, useMemo} from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { DetailsButton } from './DetailsButton'
import { useTheme } from '@mui/material/styles'
import {Relations} from "./Relations";

type Props = {
  title: string
  closePath?: string
  isDirty?: boolean
  refreshFields?: () => void
  remove?: Function
  entity?: string
  isNew?: boolean
  sx?: SxProps
  withScrollBar?: boolean
  children?: ReactNode
  retrievedEntity?: any
}

export const CardWithButtons: React.FC<Props> = (props) => {
  const { title, closePath, isDirty, refreshFields, children, remove, entity, isNew, sx, withScrollBar = true, retrievedEntity} = props

  const navigate = useNavigate()
  const theme = useTheme()

  const containerStyle = useMemo(() => ({
    width:'100%',
    '&>div': {
      height: 'auto'
    },
    height: `${entity && retrievedEntity && retrievedEntity?.relations ? 'calc(100% - 10rem)' : 'calc(100% - 5rem)'}`,
    overflow: !withScrollBar ? 'hidden' : 'inherit',
    padding: `0 ${theme.spacing(1)}`}), [withScrollBar, theme, entity, retrievedEntity])

  const Container = useMemo(() => (withScrollBar ? ScrollBar : 'div'), [withScrollBar])

  return (
    <StyledCard sx={{ gridTemplateRows: retrievedEntity! ? `min-content min-content 1fr` : 'min-content 1fr' ,...sx
    }}>
      <FirstRow>
        <Typography
          variant={'subtitle1'}
          sx={{ placeSelf: 'center start', fontWeight: 600, color: 'primary.dark' }}>
          {title}
        </Typography>

        <DetailsButton
          close={closePath ? () => navigate (closePath) : undefined}
          reset={refreshFields}
          dirty={isDirty}
          entity={entity}
          remove={remove}
          isNew={isNew}
        />
      </FirstRow>
      {entity && retrievedEntity && retrievedEntity?.relations && <SecondRow>
        <Relations  entityType={entity} entity={retrievedEntity}/>
      </SecondRow>}
      <Container style={containerStyle}>{children}</Container>
    </StyledCard>
  )
}

//region Style

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`

const FirstRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  flex-shrink: 0;
  height: 5rem;
  padding: ${(props) => props.theme.spacing(1)};
`

const SecondRow = styled.div`
  padding: ${(props) => props.theme.spacing(1)};
  z-index: ${({theme}) => theme.zIndex.drawer};
  height: 5rem;
  overflow: visible !important;
`
//endregion
