import { getTranslation } from '../store/selectors'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

export interface LabeledData {
  value: string | number
  label: string
}

export type SelectableData = string | number

const useConfigurationPbxConstants = (): HookObject => {
  const translation = useSelector(getTranslation)

  const [availableEngagementStrategies] =  useState<SelectableData[]>(['ringall', 'leastrecent', 'fewestcalls', 'random', 'rrmemory', 'wrandom'])
  const [availableRecordingStrategies] = useState<SelectableData[]>(['force', 'yes', 'dontcare', 'no', 'never'])
  const [availableMaxWaitTimeStrategies] = useState<LabeledData[]>([{label: 'Strict', value: 'app'}, {label: 'Loose', value: 'conf'}])
  const [availableJoinAnnouncementStrategies] = useState<LabeledData[]>([
    { value: 'always', label: 'Always' },
    { value: 'ready', label: 'When No Ready Agents' }
  ])
  const [availableMohStrategies] = useState<LabeledData[]>([
    { label: 'MoH Only', value: '0' },
    { label: 'Ring Only', value: '1' },
    { label: 'Agent Ringing', value: '2' }
  ])
  const [availableAutoFillStrategies] = useState<LabeledData[]>([{ label: translation.no, value: 'no' }, { label: translation.yes, value: 'yes' }])
  const [availableSkipBusyAgentsStrategies] = useState<LabeledData[]>([
    { label: translation.no, value: 0 },
    { label: translation.yes, value: 1 },
    { label: 'Yes + (ringuse=no)', value: 2 },
    { label: 'Queue calls only + (ringuse=no)', value: 3 }
  ])
  const [availableAgentRestrictionsStrategies] = useState<LabeledData[]>([
    { label: 'Call as Dialed', value: 0 },
    { label: 'No Follow-Me or Call Forward', value: 1 },
    { label: 'Extensions Only', value: 2 }])
  const [availableWrapUpTimes, setAvailableWrapUpTimes] = useState<LabeledData[]>([])
  const [availableMaxwaitTimes, setAvailableMaxwaitTimes] = useState<LabeledData[]>([])

  useEffect(() => {
    const newAvailableWrapUpTimes = []
    for (let i = 0; i < 60; i++) newAvailableWrapUpTimes.push({
      label: `${i} ${i === 1 ? translation.time.second : translation.time.seconds}`,
      value: i
    })

    for (let i = 1; i < 60; i++) {
      newAvailableWrapUpTimes.push({
        label: `${i} ${i === 1 ? translation.time.minute : translation.time.minutes}`,
        value: i * 60
      })
      newAvailableWrapUpTimes.push({
        label: `${i} ${i === 1 ? translation.time.minute : translation.time.minutes}, 30 ${translation.time.seconds}`,
        value: (i * 60) + 30
      })
    }
    newAvailableWrapUpTimes.push({ label: `1 ${translation.time.hour}`, value: 3600 })

    setAvailableWrapUpTimes(newAvailableWrapUpTimes)
  }, [translation])
  useEffect(() => {
    const newAvailableMaxwaitTimes: LabeledData[] = [{ label: translation.unlimited, value: 'unlimited' }]
    for (let i = 1; i <= 55; i++) {
      newAvailableMaxwaitTimes.push({
        label: `${i} ${i === 1 ? translation.time.second : translation.time.seconds}`,
        value: `${i}`
      })
    }
    for (let i = 1; i <= 4; i++) {
      newAvailableMaxwaitTimes.push({
        label: `${i} ${translation.time.minutes}`,
        value: `${i * 60}`
      })
      newAvailableMaxwaitTimes.push({
        label: `${i} ${translation.time.minutes} 20 ${translation.time.seconds}`,
        value: `${i * 60 + 20}`
      })
      newAvailableMaxwaitTimes.push({
        label: `${i} ${translation.time.minutes} 40 ${translation.time.seconds}`,
        value: `${i * 60 + 40}`
      })
    }
    for (let i = 5; i <= 55; i++) {
      newAvailableMaxwaitTimes.push({
        label: `${i} ${translation.time.minutes}`,
        value: `${i * 60}`
      })
    }
    newAvailableMaxwaitTimes.push({
      label: `${1} ${translation.time.hour}`,
      value: `${60 * 60}`
    })
    for (let i = 5; i <= 55; i+= 5) {
      newAvailableMaxwaitTimes.push({
        label: `1 ${translation.time.hour} ${i} ${translation.time.minutes}`,
        value: `${60 * 60 + i * 60}`
      })
    }
    newAvailableMaxwaitTimes.push({
      label: `${2} ${translation.time.hour}`,
      value: `${2 * 60 * 60}`
    })

    setAvailableMaxwaitTimes(newAvailableMaxwaitTimes)
  }, [translation])

  return {
    availableEngagementStrategies,
    availableRecordingStrategies,
    availableJoinAnnouncementStrategies,
    availableMaxWaitTimeStrategies,
    availableMohStrategies,
    availableAutoFillStrategies,
    availableSkipBusyAgentsStrategies,
    availableAgentRestrictionsStrategies,
    availableWrapUpTimes,
    availableMaxwaitTimes
  }
}

export default useConfigurationPbxConstants

// region types
interface HookObject {
  availableEngagementStrategies: SelectableData[],
  availableRecordingStrategies: SelectableData[],
  availableJoinAnnouncementStrategies: LabeledData[],
  availableMaxWaitTimeStrategies: LabeledData[],
  availableMohStrategies: LabeledData[],
  availableAutoFillStrategies: LabeledData[],
  availableSkipBusyAgentsStrategies: LabeledData[],
  availableAgentRestrictionsStrategies: LabeledData[],
  availableWrapUpTimes: LabeledData[],
  availableMaxwaitTimes: LabeledData[]
}
// endregion