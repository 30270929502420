import { getTranslation } from '../../../../../store/selectors'
import React from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import StatCard from '../common/StatCard'

const Inbounds: React.FC = () => {
  const translation = useSelector(getTranslation)

  return (
    <Container>
      <StatCard
        type="bar"
        entity="inbounds"
        title={translation.stats.inbounds.lostCalls.title}
        description={translation.stats.inbounds.lostCalls.description}
        path="/tools/stats/inbounds/lost-calls"
      />
      <StatCard
        type="bar"
        entity="inbounds"
        title={translation.stats.inbounds.lostCallsByHourBands.title}
        description={translation.stats.inbounds.lostCallsByHourBands.description}
        path="/tools/stats/inbounds/lost-calls-by-hourbands"
      />
      <StatCard
        type="bar"
        entity="inbounds"
        title={translation.stats.inbounds.detailsByUsers.title}
        description={translation.stats.inbounds.detailsByUsers.description}
        path="/tools/stats/inbounds/details-by-users"
      />
      <StatCard
        type="line"
        entity="inbounds"
        title={translation.stats.inbounds.detailsByInterval.title}
        description={translation.stats.inbounds.detailsByInterval.description}
        path="/tools/stats/inbounds/details-by-interval"
      />
      <StatCard
        type="line"
        entity="inbounds"
        title={translation.stats.inbounds.detailsByWeekDays.title}
        description={translation.stats.inbounds.detailsByWeekDays.description}
        path="/tools/stats/inbounds/details-by-weekdays"
      />
      <StatCard
        type="line"
        entity="inbounds"
        title={translation.stats.inbounds.detailsByService.title}
        description={translation.stats.inbounds.detailsByService.description}
        path="/tools/stats/inbounds/details-by-services"
      />
      <StatCard
        type="table"
        entity="inbounds"
        title={translation.stats.inbounds.operatorsActivity.title}
        description={translation.stats.inbounds.operatorsActivity.description}
        path="/tools/stats/inbounds/operators-activity"
      />
      {/*<StatCard*/}
      {/*  type="table"*/}
      {/*  entity="inbounds"*/}
      {/*  title={translation.stats.inbounds.topnetworkInbounds.title}*/}
      {/*  description={translation.stats.inbounds.topnetworkInbounds.description}*/}
      {/*  path="/tools/stats/inbounds/top-network"*/}
      {/*/>*/}
      <StatCard
        type="table"
        entity="inbounds"
        title={translation.stats.inbounds.bySource.title}
        description={translation.stats.inbounds.bySource.description}
        path="/tools/stats/inbounds/by-source-and-month"
      />
    </Container>
  )
}

export default Inbounds

const Container = styled(ScrollBar)`
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-template-rows: repeat(auto-fit, 240px);
  gap: ${(props) => props.theme.spacing(3)};
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(3)};
`
