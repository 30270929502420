import {DeleteOutlineRounded, ReorderRounded} from '@mui/icons-material'
import {IconButton, Paper, Typography} from '@mui/material'
import {getTranslation} from '../../store/selectors'
import React from 'react'
import {DragDropContext, Draggable, DraggableProvided, Droppable, DropResult} from 'react-beautiful-dnd'
import Dropzone from 'react-dropzone'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {maxRecordingFileSize, RecordingAllowedFileType} from '../../configuration'
import {AudioPlayer} from './AudioPlayer'
import {Hint} from './Hint'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {notify} from "../../store/actions";

/**
 * Componente drop box e lista per le registrazioni
 * @param props.files {FilesObject} oggetto contenente i file da caricare
 * @param props.id {string} id contenitore per il riordinamento della lista
 * @param props.setFiles {(files: FilesObject) => void} setter per i file
 * @param props.fileNames { string[]} ordine dei file nella lista
 * @param props.setFileNames {(newOrder: string[]) => void} setter per l'ordine
 * @param props.render {({ filename, file }: { filename: string, file?: File }) => React.FC}  funzione per il render del componente contentente le info del file
 * @param props.reorder {boolean} opzione per il riordinamento della lista
 * @param props.entity {string} entità per la riproduzione del file
 * @param props.entityName {string} nome dell'entità serve per le moh
 * @param props.audioFile {boolean} opzione per la riproduzione del file audio
 * @constructor
 */
export const FileDropBox: React.FC<Props> = (props) => {
  const {
    files,
    id,
    title = '',
    setFiles,
    fileNames,
    setFileNames,
    reorder = false,
    audioFile = false,
    entity,
    entityName,
    render = ({ filename }) => {
      const lastDotIndex = filename?.lastIndexOf('.') > 0 ? filename?.lastIndexOf('.') : filename?.length
      return (<FileInfo>
        <div>{filename ? filename.slice(0, lastDotIndex) : ''}</div>
      </FileInfo>)
    }
  } = props

  const translation = useSelector(getTranslation)
  const dispatch = useDispatch()

  const onDrop = (file: File[]) => {
    if (file && file.length > 0) {
      const newFiles = Object.assign(files, {})
      const newOrder = [...fileNames]
      file.forEach((f) => {
        const name = f.name.slice(0, f.name.lastIndexOf('.'))
        if (!name.match(/^[A-Za-z0-9-_]+$/g)) {
          dispatch(notify({ message: translation.input.invalidFileName, severity: 'error' }))
          return
        }
        if (newOrder.find((fn) => fn === f.name)) return
        newFiles[`${f.name}`] = f
        newOrder.push(`${f.name}`)
      })
      setFileNames(newOrder)
      setFiles(newFiles)
    }
  }
  const removeRecording = (key: string) => {
    const newFiles = Object.assign(files, {})
    const newOrder = [...fileNames].filter((o) => o !== key)
    delete newFiles[key]
    setFiles(newFiles)
    setFileNames(newOrder)
  }

  const onDragEnd = (result: DropResult) => {
    if (!reorder) return
    const { destination, source, draggableId } = result

    if (!destination) return

    if (destination.index === source.index) return

    //creazione nuovo ordine
    const newOrder = [...fileNames]
    newOrder.splice(source.index, 1)
    newOrder.splice(destination.index, 0, draggableId)
    setFileNames(newOrder)
  }

  const renderFileInfoComponent = (key: string, provided?: DraggableProvided) => {
    return (
      <FileInfoContainer
        key={key}
        reorder={reorder}
        audioFiles={audioFile}
        {...provided?.draggableProps}
        ref={provided?.innerRef}>
        <>
          {reorder && (
            <IconButton style={{ placeSelf: 'center' }} {...provided?.dragHandleProps}>
              <ReorderRounded fontSize={'small'} />
            </IconButton>
          )}
          <IconButton onClick={() => removeRecording(key)} style={{ placeSelf: 'center' }}>
            <DeleteOutlineRounded fontSize={'small'} sx={{ color: 'error.main' }} />
          </IconButton>
          {render({ filename: key, file: files[key] })}
          {<AudioPlayer entity={entity} entityName={entityName} name={key} file={files[key]} />}
        </>
      </FileInfoContainer>
    )
  }

  return (
    <MohFilesContainer>
      <TitleBox>
        <Typography variant={'subtitle2'} sx={{ color: 'primary.dark', fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Hint title={title} content={translation.pages.CONFIGURATION_RECORDING.fileHint} />
      </TitleBox>

      <CardContainer>
        <Dropzone
          onDrop={(recordFile) => onDrop(recordFile)}
          maxSize={maxRecordingFileSize}
          accept={RecordingAllowedFileType as any}>
          {({ getRootProps, getInputProps }) => (
            <DragAndDrop>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Typography>{translation.input.recordingFiles}</Typography>
              </div>
            </DragAndDrop>
          )}
        </Dropzone>
      </CardContainer>
      <CardContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={id} key={id}>
            {(provided) => (
              <PerfectScrollbar style={{ width: '100%' }}>
                <FileList ref={provided.innerRef} {...provided.droppableProps}>
                  {fileNames.length > 0 &&
                    fileNames.map((o, index) => {
                      if (reorder)
                        return (
                          <Draggable draggableId={o} index={index} key={o}>
                            {(provided) => renderFileInfoComponent(o, provided)}
                          </Draggable>
                        )
                      else return renderFileInfoComponent(o)
                    })}
                  {provided.placeholder}
                </FileList>
              </PerfectScrollbar>
            )}
          </Droppable>
        </DragDropContext>
      </CardContainer>
    </MohFilesContainer>
  )
}

// region types
interface FilesObject {
  [key: string]: File | null
}

interface Props {
  files: FilesObject
  setFiles: (files: FilesObject) => void
  id: string
  title?: string
  fileNames: string[]
  setFileNames: (newOrder: string[]) => void
  render?: ({ filename, file }: { filename: string; file: File | null }) => React.FC
  reorder?: boolean
  audioFile?: boolean
  fileType?: string
  entity: string
  entityName?: string
}
// endregion

//region Style
const CardContainer = styled.div`
  display: flex;
  margin: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`
const DragAndDrop = styled(Paper)`
  opacity: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px dashed #bbbbbb;
  text-align: -webkit-center;
  min-height: 5rem;
  min-width: 100%;
`
const FileList = styled.div`
  overflow: auto;
  width: 100%;
`
const MohFilesContainer = styled.div`
  display: grid;
  grid-template-rows: 2rem 1fr;
  padding: 20px;
`

const FileInfoContainer = styled.div<{ reorder: boolean; audioFiles: boolean }>`
  display: grid;
  grid-template-columns: ${({ reorder }) => (reorder ? 'max-content' : '')} max-content 2.5fr ${({ audioFiles }) =>
      audioFiles ? '1.5fr' : ''};
  max-height: 5rem;
  margin: 0.5rem 1rem 0 0;
  gap: 2rem;
`
const FileInfo = styled.div`
  align-self: center;
  opacity: 0.7;
  font-size: 0.95rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: max-content;

  & > div {
    place-self: start center;
  }
`
const TitleBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
// endregion
