// richiede la traduzione a un url se non è presente nel localstorage la traduzione richiesta
import axios from 'axios'
import { translations } from '../configuration'

export const getTranslation = async (language: string) => {
  //let data: Translation = language === 'it_IT' ? { ...it } : { ...en }

  //richiesta http
  const { data } = await axios.get(`${translations}/${language}.json`)

  return data
}
