import { TitledCard } from './TitledCard'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { CSSProperties } from '@mui/styles'


export const NumberPlot: React.FC<Props> = ({ title, number, sx }) => {
  return (
    <TitledCard title={title} sx={sx}>
      <Container>
        <FractionContainer>
            <Typography sx={{fontSize: '4rem', fontWeight: 600}}>{number}</Typography>
        </FractionContainer>
      </Container>
    </TitledCard>
  )
}

// region style
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
`
const FractionContainer = styled.div`
  height: 7rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-content: center;
`
// endregion

// region types
type Props = {
  title: string
  number: number
  sx?: CSSProperties
}
// endregion
