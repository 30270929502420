import { Checkbox } from '@mui/material'
import React, { useMemo } from 'react'
import { AutoSizeTable } from './AutoSizeTable'
import { MuiTableProps } from './VirtualizedTable'

function SelectableTableControlled<RowData>({ showCheckboxes = true, checked, onChange, ...props }: Props<RowData>) {
  const checkboxes = useMemo(() => {
    if (showCheckboxes) {
      return {
        name: 'checkbox',
        header: (
          <Checkbox
            size="small"
            sx={{width: 40, height: 40}}
            checked={checked.length > 0}
            onChange={() => {
              if (checked.length > 0) {
                // deselect all
                onChange([])
              } else {
                // select all
                onChange(props.data.map((d) => d[props.idField]))
              }
            }}
            {...(checked.length > 0 &&
              checked.length !== props.data.length && {
                indeterminate: true,
                color: 'default'
              })}
          />
        ),
        cell: (rowData: RowData) => (
          <Checkbox
            size="small"
            sx={{width: 40, height: 40}}
            checked={checked.some((id: any) => rowData[props.idField] === id)}
            onChange={(e) => {
              if (!e.target.checked) {
                onChange(checked.filter((sr: any) => sr !== rowData[props.idField]))
              } else {
                onChange(checked.concat(rowData[props.idField]))
              }
            }}
          />
        ),
        width: 50
      }
    }
  }, [showCheckboxes, props.data, props.idField, checked, onChange])

  const columns = useMemo(() => {
    if (checkboxes) {
      return [checkboxes, ...props.columns]
    } else {
      return [...props.columns]
    }
  }, [checkboxes, props.columns])

  return (
    <AutoSizeTable
      {...props}
      columns={columns}
      isCellSelected={(column: any, rowData: any) =>
        checked.some((id: any) => rowData && rowData[props.idField] === id)
      }
    />
  )
}

type Props<RowData> = Omit<MuiTableProps<RowData>, 'height' | 'width'> & {
  showCheckboxes?: boolean
  checked: any[]
  onChange: (newChecked: any[]) => void
}

export { SelectableTableControlled }
