import logger from '../../../helpers/logger'
import imgBackground from '../../../images/login-background.jpeg'
import methodLogo from '../../../images/Oc.svg'
import methodLogoDark from '../../../images/OC_white.svg'
import MSAD from '../../../images/MSAD.svg'
import Azure from '../../../images/Azure.svg'
import Okta from '../../../images/Okta.svg'
import { useMediaQuery } from '@mui/material'
import { selectLoginMethod } from '../../../store/actions'
import { getIsAuthenticated, getLoginMethods, getSelectedMethod, getTheme, getTranslation } from '../../../store/selectors'
import React, { useCallback, useEffect } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as LogoIcon } from '../../../images/Oc.svg'
import { ReactComponent as Logo } from '../../../images/OC_full_white.svg'
import { ReactComponent as LogoIconDark } from '../../../images/OC_white.svg'
import qs from 'query-string'
import { host } from '../../../configuration'

const Login: React.FC = () => {
  const loginMethods = useSelector(getLoginMethods)
  const selectedMethod = useSelector(getSelectedMethod)
  const isAuthenticated = useSelector(getIsAuthenticated)
  const translation = useSelector(getTranslation)
  const theme = useSelector(getTheme)

  const dispatch = useDispatch()

  const matches = useMediaQuery('(min-width:1280px)')

  const selectMethodIcon = useCallback((name: string) => {
    switch (name) {
      case 'OCAuthMSAD':
        return  MSAD
      case 'OCAuthAzure':
        return Azure
      case 'OCAuthOkta':
        return Okta
      case 'OCAuth':
        return theme === 'light' ? methodLogo : methodLogoDark
      default:
        return theme === 'light' ? methodLogo : methodLogoDark
    }
  }, [theme])

  useEffect(() => {
    logger.debug('Login use effect')
    //se presente un solo metodo di autenticazione o ne ho gia uno selezionato faccio un redirect sul SSO
    if (selectedMethod && !isAuthenticated) {
      const body = {
        redirect_uri: host
      }
      const separator = selectedMethod.login_url.includes("?") ? "&" : "?"
      window.location.href = selectedMethod.login_url + separator + qs.stringify(body)
    }
  }, [selectedMethod, isAuthenticated])

  return !sessionStorage.getItem('token') ? (
    <Container>
      <LeftContainer>
        <LogoContainer>
          {theme === 'light' ? <LogoIcon /> : <LogoIconDark />}
          <LogoDisclaimer>{translation.pages.LOGIN.disclaimer}</LogoDisclaimer>
        </LogoContainer>
        <MethodsContainer>
          {loginMethods.length > 0 &&
            loginMethods.map((m) => (
              <MethodButton
                key={m.id}
                onClick={() => {
                  dispatch(selectLoginMethod(m))
                }}>
                <ButtonLogo src={selectMethodIcon(m.type)} />
                <ButtonText>{m.name}</ButtonText>
              </MethodButton>
            ))}
        </MethodsContainer>
      </LeftContainer>
      {matches && (
        <RightContainer>
          <FullLogoContainer>
            <Logo />
          </FullLogoContainer>
          <DarkeningEffect />
        </RightContainer>
      )}
    </Container>
  ) : (
    <Navigate
      replace
      to={
          sessionStorage.getItem('pathBeforeUnload') && sessionStorage.getItem('pathBeforeUnload') !== '/login' ? sessionStorage.getItem('pathBeforeUnload')! : '/tools/dashboard'
      }
    />
  )
}
/*sessionStorage.getItem('pathBeforeUnload') ||*/

export default Login

//region Style
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @media (min-width: 1280px) {
    grid-template-columns: 1.5fr 2fr;
  }
`

const LeftContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1.5fr;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.palette.background.paper};
`

const LogoContainer = styled.div`
  place-self: center start;
  text-align: center;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row-gap: 5px;
  place-items: center;
  & > svg {
    width: auto;
    height: 20%;
    max-height: 180px;
    min-height: 80px;
    place-self: end center;
  }
`

const LogoDisclaimer = styled.div`
  color: #e24e13;
  font-size: 0.8rem;
  width: 80%;
  max-width: 300px;
`

const MethodsContainer = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  place-self: start center;
  padding-top: 0.5rem;
`
const MethodButton = styled.div`
  position: relative;
  place-self: center;
  border: 1px solid #979797;
  height: 45px;
  width: 80%;
  max-width: 450px;
  cursor: pointer;
  font-size: 0.8rem;
  text-align: center;
  vertical-align: middle;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 0.3rem;
  color: ${(props) => props.theme.palette.text.primary};
  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }

  &:last-child {
    margin-bottom: 0;
  }
`
const ButtonLogo = styled.img`
  grid-area: logo;
  place-self: center;
  width: 20px;
  height: auto;
  position: absolute;
  left: 10px;
  top: calc(50% - 10px);
`
const ButtonText = styled.div`
  grid-area: text;
  place-self: center;
`

const RightContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr;

  width: 100%;
  height: 100%;
  background-image: url('${imgBackground}');
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: relative;
`

const DarkeningEffect = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgb(17, 91, 114), rgba(17, 91, 114, 0));
  mix-blend-mode: multiply;
`

const FullLogoContainer = styled.div`
  place-self: center start;
  text-align: center;
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 1000;
  & > svg {
    width: auto;
    height: 20%;
    max-height: 180px;
    min-height: 80px;
    place-self: end center;
  }
`

//endregion
