import {Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper} from '@mui/material'
import {SxProps} from '@mui/system'
import React, {PropsWithChildren, useEffect, useState} from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import {Searchbar} from './Searchbar'

type Props<TItem> = {
  itemIds: any[]
  itemIdsView: any[]
  setItemIdsView: (v: any[]) => void
  items: TItem[]
  checked: any[]
  getItemById: Function
  handleToggle: Function
  searchFields: Array<keyof TItem>
  idField: keyof TItem
  labelField: keyof TItem
  disabled: boolean
  searchPlaceHolder?: string
  containerStyle?: SxProps
  renderItem?: (item: TItem) => React.ReactNode
  maxHeight?: number
}

export function ListWithSearchbar<TItem>(props: PropsWithChildren<Props<TItem>>) {
  const {
    itemIds,
    itemIdsView,
    setItemIdsView,
    items,
    checked,
    getItemById,
    handleToggle,
    searchFields,
    idField,
    labelField,
    disabled,
    searchPlaceHolder,
    containerStyle = {},
    renderItem
  } = props

  const [itemsToShow, setItemsToShow] = useState<TItem[]>([])

  useEffect(() => {
    setItemsToShow(itemIdsView.map((i) => getItemById(items, i)).filter((x) => !!x).sort((a, b) => a.label > b.label ? 1 : -1))
  }, [itemIdsView, items, getItemById])

  const search = (s: string) => {
    if (!s) {
      setItemIdsView([...itemIds])
      return
    }
    const query = s.toLowerCase()
    const results = itemIds.filter((v) => {
      // recupero l'item dall'id
      const item = getItemById(items, v)
      return searchFields.some((sf) => {
        const field = item[sf]
        if (typeof field === 'string') {
          return field.toLowerCase().includes(query)
        }
        if (typeof field === 'number') {
          return field === parseInt(query)
        }
        return false
      })
    })
    setItemIdsView(results)
  }

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflow: 'hidden',
        pt: 1,
        minHeight: '150px',
        ...containerStyle
      }}>
      <Searchbar handleChange={search} placeholder={searchPlaceHolder} disabled={disabled} />
      <List dense component="div" role="list" sx={{ overflowY: 'hidden' }}>
        <ScrollBar>
          {itemsToShow.map((item, index) => (
            <ListItemButton
              key={`${item[idField]}-${index}`}
              role="listitem"
              disabled={disabled}
              onClick={handleToggle(item[idField])}
              sx={{ minHeight: 0, py: 0, height: 30 }}>
              <ListItemIcon>
                <Checkbox
                  size="small"
                  checked={checked.indexOf(item[idField]) !== -1}
                  tabIndex={-1}
                  disableRipple
                  disabled={disabled}
                />
              </ListItemIcon>
              {renderItem ? (
                renderItem(item)
              ) : (
                <ListItemText primary={(item[labelField]) as string} sx={{ justifySelf: 'center' }} />
              )}
            </ListItemButton>
          ))}
        </ScrollBar>
        <ListItem />
      </List>
    </Paper>
  )
}
