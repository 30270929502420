import log  from '../../helpers/logger'
import { LoginMethod, LoginPayload } from '../../store/login/types'
import axios from 'axios'
import qs from 'query-string'
import { coreApi, coreUrl, host } from '../../configuration'

/**
 * Dopo il redirect da parte di OC AUTH, viene preso il code dall'url presente nella barra del browser
 * e viene spedito di nuovo ad OC AUTH per ottenere un token da mandare al CORE
 * @param selectedLoginMethod
 * @param code
 *
 * @returns access_token { Promise<string> }
 */
export const getAuthToken = async (selectedLoginMethod: LoginMethod, code: string): Promise<string> => {
  //a partire dal codice ricevuto da auth faccio una POST per reperire il token validato del sistema di autenticazione
  const otherParams = qs.parse(selectedLoginMethod.token_url.split('?')[1])
  const body = {
    code,
    redirect_uri: host,
    ...otherParams
  }

  try {
    const { data } = await axios.post(selectedLoginMethod.token_url, qs.stringify(body), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    return data.access_token
  } catch (e) {
    throw e
  }
}

/**
 * Con il token ottenuto da OC AUTH mandando il code di autorizzazione, si fa la richiesta al CORE
 * per ottenere un suo token
 * @param loginMethodId
 * @param payload
 */
export const coreLogin = async (loginMethodId: number, payload: any): Promise<LoginPayload> => {
  // eseguo il login sul core per ricevere token e user
  log.silly('Executing login against oc-core with method', loginMethodId)
  try {
    const { data } = await axios.post(`${coreUrl}/authentication/${loginMethodId}`, payload)
    return data.payload as LoginPayload
  } catch (e) {
    log.error(e)
    throw e
  }
}

/**
 * Ottiene un nuovo token mandando l'attuale token (ancora non scaduto)
 * @param token
 */
export const getNewToken = async (token: string): Promise<LoginPayload> => {
  try {
    log.silly('Getting new token from core')
    const { data } = await axios.get(`${coreApi}/login/renew`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data.payload as LoginPayload
  } catch (e) {
    log.error(e)
    throw e
  }
}

export const getPayloadByLoginMethod = (loginMethodId: number, token: string, others: Object = {}) => {
  let payload
  switch (loginMethodId) {
    default:
      payload = { ...others, token }
  }
  return payload
}
