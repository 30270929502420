import { Typography } from '@mui/material'
import { getStatsFavourites, getTranslation } from '../../../../store/selectors'
import React from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import StatCard from './common/StatCard'

const Favourites: React.FC = () => {
  const favourites = useSelector(getStatsFavourites)
  const translation = useSelector(getTranslation)

  return (
    <Container>
      {favourites?.length === 0 && <Typography sx={{ opacity: 0.8 }}>{translation.stats.noFavourites}</Typography>}
      {favourites.map((f) => (
        <StatCard
          key={f.title}
          type={f.type}
          entity={f.entity}
          title={f.title}
          description={f.description}
          path={f.path}
        />
      ))}
    </Container>
  )
}

export default Favourites

const Container = styled(ScrollBar)`
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-template-rows: repeat(auto-fit, 240px);
  gap: ${(props) => props.theme.spacing(3)};
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(3)};
`
