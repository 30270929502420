import {getErrorMessage} from '../../helpers'
import {ErrorMessage} from '@hookform/error-message'
import {Autocomplete, AutocompleteProps, Checkbox, ListItemText, MenuItem, Paper, TextField} from '@mui/material'
import {getTranslation} from '../../store/selectors'
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import {useController, UseControllerProps, useFormState} from 'react-hook-form'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {Hint} from './Hint'
import {FieldValues} from "react-hook-form/dist/types";
import {useTheme} from "@mui/material/styles";

export interface OptionsType {
  key: string | number
  label: string
  value: string | number
  deleted?: boolean
}

type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> &
  Partial<AutocompleteProps<any, true, any, any>> & {
    label: string
    options: OptionsType[]
    variant?: 'outlined' | 'filled' | 'standard'
    hint?: string
    menuPlacement?: 'bottom' | 'top'
    leaveSpaceForHint?: boolean
    leaveSpaceForError?: boolean
    containerStyle?: React.CSSProperties
    hasPermission?: boolean
    withSelectAll?: boolean
    placeholder?: string
  }

/**
 * Componente comune per la MultipleAutocomplete
 * @constructor
 * @param props
 */
export function MultipleAutoComplete<TFieldValues extends FieldValues>(props: PropsWithChildren<Props<TFieldValues>>) {
  const {
    variant = 'standard',
    control,
    options,
    label,
    name,
    style,
    menuPlacement,
    rules,
    containerStyle,
    hint,
    disabled,
    hasPermission,
    size = 'small',
    leaveSpaceForHint = true,
    leaveSpaceForError = true,
    withSelectAll = true,
    ...rest
  } = props

  const { field } = useController<TFieldValues>({ control, name, rules })
  const { errors } = useFormState<TFieldValues>({ control, name })

  const translation = useSelector(getTranslation)
  const theme = useTheme()

  const [values, setValues] = useState<OptionsType[]>([])

  useEffect(() => {
    if (options.length === 0) return
    // Costringo TFieldValues ad essere un array
    const newValue = (field.value as TFieldValues[]).map((val) => options.find((o) => val === o.value))
    setValues(newValue)
  }, [field.value, options])

  const isEnabled = useMemo((): boolean => {
    // inverte disabled se passato come prop
    const enabled = typeof disabled === 'boolean' ? !disabled : true
    // se non viene passato hasPermission mi affido alle altre prop
    if (hasPermission === undefined) {
      return enabled
    } else {
      // altrimenti deve anche avere il permesso oltre a dipendere dalle altre props
      return hasPermission && enabled
    }
  }, [hasPermission, disabled])

  const getMessage = (message = '') => getErrorMessage(translation, errors, name, message)

  const findFieldErrors = useCallback(() => {
    const [father, child, grandChild] = name.split('.')

    if(grandChild) return ((errors[father] as any)?.[child] as any)?.[grandChild]
    if (child) return ((errors[father] as any)?.[child] as any)
    return errors[father]
  }, [errors, name])

  const allSelected = options.length === values.length

  return (
    <Container style={containerStyle} leaveSpaceForHint={leaveSpaceForHint} leaveSpaceForError={leaveSpaceForError}>
      <Autocomplete
        limitTags={1}
        size={size}
        disableCloseOnSelect
        multiple={true}
        disablePortal
        fullWidth
        {...rest}
        {...field}
        sx={{...(!!findFieldErrors() ? {fieldset: {borderColor: theme.palette.error.main}} : {})}}
        componentsProps={menuPlacement ? {
          popper: {
            placement: menuPlacement === 'bottom' ? 'bottom-start' : 'top-start'
          }
        } : {}}
        value={values}
        disabled={!isEnabled}
        options={
          withSelectAll
            ? [{ key: 'select-all-option', label: translation.input.selectAll, value: 'all' }, ...options]
            : options
        }
        PaperComponent={(props) => (<Paper sx={{background: 'background.paper', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'}} {...props}/>)}
        getOptionLabel={(option) => {
          if (!option) return ''
          return option.label
        }}
        isOptionEqualToValue={(option, value) => value && option.value === value.value}
        onChange={(_, data) => {
          if (data.find((option) => option.value === 'all')) {
            if (allSelected) field.onChange([])
            else field.onChange(options.map((d) => d.value))
          } else {
            field.onChange(data.map((d) => d.value))
          }
        }}
        renderOption={(props, option) => {
          return (
            <MenuItem {...props} key={option.key} value={option.key}>
              <Checkbox
                checked={!!values.find((values) => values?.key === option.key) || allSelected}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
              />
              <ListItemText
                primary={option.label}
                sx={{ marginLeft: '0.3rem', color: option.deleted ? 'action.disabled' : 'inherit' }}
              />
            </MenuItem>
          )
        }}
        renderInput={({ InputProps, ...params }) => {
          const startAdornment =
            values.length > 0 ? (
              <div
                style={{
                  maxHeight: `calc((${size === 'small' ? '24px' : '32px'} + 5px) * 2)`,
                  overflowY: 'auto'
                }}>
                {InputProps.startAdornment}
              </div>
            ) : null
          return (
            <TextField
              variant={variant}
              color="primary"
              {...params}
              fullWidth
              InputProps={{
                ...InputProps,
                readOnly: !isEnabled,
                startAdornment: startAdornment
              }}
              label={label}
            />
          )
        }}
      />
      <ErrorMessage errors={errors} name={name as any} render={({ message }) => <Error>{getMessage(message)}</Error>} />
      {hint && <Hint title={label} content={hint} />}
    </Container>
  )
}

// region style
const Container = styled.div<{ leaveSpaceForHint: boolean; leaveSpaceForError: boolean }>`
  display: grid;
  grid-template-areas: 'input hint' 'error error';
  grid-template-columns: 1fr ${(props) => (props.leaveSpaceForHint ? '30px' : 'auto')};
  grid-template-rows: auto ${(props) => (props.leaveSpaceForError ? '20px' : 'auto')};
`

const Error = styled.div`
  grid-area: error;
  color: ${(props) => props.theme.palette.error.main};
  font-size: 0.8rem;
  margin-left: ${(props) => props.theme.spacing(1)};
`
// endregion
