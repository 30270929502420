import { getTranslation } from '../../../../../store/selectors'
import React from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import StatCard from '../common/StatCard'

const Users: React.FC = () => {
  const translation = useSelector(getTranslation)

  return (
    <Container>
      <StatCard
        type="donut"
        entity="users"
        title={translation.stats.users.operatorStats.title}
        description={translation.stats.users.operatorStats.description}
        path="/tools/stats/users/operator-stats"
      />
      <StatCard
        type="donut"
        entity="users"
        title={translation.stats.users.consultantsOperativity.title}
        description={translation.stats.users.consultantsOperativity.description}
        path="/tools/stats/users/consultants-operativity"
      />
      <StatCard
        type="donut"
        entity="users"
        title={translation.stats.users.consultantsOperativityByHourBands.title}
        description={translation.stats.users.consultantsOperativityByHourBands.description}
        path="/tools/stats/users/consultants-operativity-by-hour-bands"
      />
    </Container>
  )
}

export default Users

const Container = styled(ScrollBar)`
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-template-rows: repeat(auto-fit, 240px);
  gap: ${(props) => props.theme.spacing(3)};
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(3)};
`
