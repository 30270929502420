import {AddRounded, SearchRounded, Sync} from '@mui/icons-material'
import {Fab, MenuItem, Tooltip, Typography} from '@mui/material'
import {getButtonTranslation} from '../../store/selectors'
import React, {PropsWithChildren, useEffect} from 'react'
import {DefaultValues, Path, SubmitHandler, useForm} from 'react-hook-form'
import {Control, FieldValues} from 'react-hook-form/dist/types'
import {UseFormHandleSubmit} from 'react-hook-form/dist/types/form'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {DateTimePicker} from './DateTimePicker'
import {Input} from './Input'
import {MultipleAutoComplete, OptionsType} from './MultipleAutoComplete'
import {TitledCard} from './TitledCard'
import {Select} from './Select'

/**
 * Componente comune per la gestione dei filtri
 * @param props.filters {Filter[]} array di filtri da visualizzare nella card (va ordinato)
 * @param props.onSubmit {(data:FormData)=>void}
 * @param props.newButton {()=>void} funzione per la creazione di una nuova entità
 * @param props.goBack {()=>void}
 * @param props.spaceBetween {boolean} flag per spaziare o no i filtri tra di loro
 * @param props.title {string} titolo della card contenente i filtri
 * @constructor
 */
export function Filters<FormData extends FieldValues>(props: PropsWithChildren<Props<FormData>>) {
  const {
    filters,
    onSubmit,
    defaultValues,
    spaceBetween = true,
    options = [],
    newButton,
    additionalButton,
    title
  } = props

  const translation = useSelector(getButtonTranslation)
  // 3 è il massimo numero di filtri che posso mettere su una riga
  const lastRowFiltersNumber = filters.length % 3

  const computeFiltersWidth = (index: number) => {
    if (index >= filters.length - lastRowFiltersNumber) {
      return lastRowFiltersNumber !== 1 ? `${100 / lastRowFiltersNumber - 1}%` : `50%`
    }
    return `32%`
  }

  const { control, handleSubmit, reset } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: { ...defaultValues as DefaultValues<FormData>},
    shouldUnregister: false
  })

  const getComponentByType = (filter: Filter<FormData>, index: number) => {
    switch (filter.type) {
      case 'text':
        return (
          <Input<FormData>
            label={filter.label || ''}
            key={index}
            name={filter.fieldName}
            control={props.control || control}
            variant={'standard'}
            hasPermission={true}
            containerStyle={{ width: computeFiltersWidth(index) }}
            placeholder={filter.placeholder}
          />
        )
      case 'multi-autocomplete':
        if (!filter.options) throw new Error('options must be defined for type "multi-autocomplete"')
        return (
          <MultipleAutoComplete<FormData>
            key={index}
            label={filter.label || ''}
            options={filter.options}
            control={props.control || control}
            name={filter.fieldName}
            containerStyle={{ width: computeFiltersWidth(index) }}
            placeholder={filter.placeholder}
          />
        )
      case 'date-picker':
        return (
          <DateTimePicker<FormData>
            name={filter.fieldName}
            control={props.control || control}
            label={filter.label || ''}
            key={index}
          />
        )
      case 'select':
        return (
          <Select<FormData>
            name={filter.fieldName}
            placeholder={filter.placeholder}
            control={props.control || control}
            label={filter.label}
            key={index}
            variant={'standard'}
            hint={props.hint}
            leaveSpaceForHint={props.leaveSpaceForHint}
            leaveSpaceForError={props.leaveSpaceForError}
            containerStyle={{ width: computeFiltersWidth(index) }}
            hasPermission={props.hasPermission}>
            <MenuItem key={'empty'} value={''}>
              &nbsp;
            </MenuItem>
            {filter.selectOptions &&
              filter.selectOptions.length > 0 &&
              filter.selectOptions.map((el) => (
                <MenuItem key={el.key} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
          </Select>
        )
      default:
        return null
    }
  }

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  return (
    <TitledCard title={title || ''}>
      <Container onSubmit={props.handleSubmit ? props.handleSubmit(onSubmit) : handleSubmit(onSubmit)}>
        <FormContainer spaceBetween={spaceBetween}>
          {filters.map((f: any, index: number) => getComponentByType(f, index))}
        </FormContainer>
        {!!options.length && <OptionsContainer>{options.map((option) => option)}</OptionsContainer>}
        <ButtonsContainer>
          {additionalButton && (
            <Tooltip title={<Typography>{additionalButton.name}</Typography>}>
              <Fab size="small" sx={{ mx: 1 }} onClick={additionalButton.onClick}>
                <Sync />
              </Fab>
            </Tooltip>
          )}
          <Tooltip title={<Typography>{translation.search}</Typography>}>
            <Fab type={'submit'} size="small" sx={{ mx: 1 }}>
              <SearchRounded />
            </Fab>
          </Tooltip>
          {newButton && (
            <Tooltip title={<Typography>{translation.add}</Typography>}>
              <Fab size="small" sx={{ mx: 1 }} onClick={newButton} color="primary">
                <AddRounded />
              </Fab>
            </Tooltip>
          )}
        </ButtonsContainer>
      </Container>
    </TitledCard>
  )
}

//region style
const Container = styled.form`
  display: flex;
  column-gap: 1rem;
  padding: ${(props) => props.theme.spacing(1.5)} 0;
`

const FormContainer = styled.div<{ spaceBetween: boolean }>`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  align-content: center;
  justify-content: flex-start;
  flex-grow: 1;
`

const OptionsContainer = styled.div`
  display: flex;
  width: auto;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-content: start;
  height: auto;
  max-width: 170px;
  justify-content: flex-end;
`
//endregion

// region type
interface Props<FormData extends FieldValues> {
  filters: Filter<FormData>[]
  onSubmit: SubmitHandler<FormData>
  newButton?: () => void
  additionalButton?: {
    name: string
    onClick: () => void
  }
  options?: React.ReactNode[]
  spaceBetween?: boolean
  defaultValues: FormData
  title?: string
  control?: Control<FormData>
  handleSubmit?: UseFormHandleSubmit<FormData>
  hint?: string
  leaveSpaceForHint?: boolean
  leaveSpaceForError?: boolean
  containerStyle?: React.CSSProperties
  hasPermission?: boolean
  children?: any[]
}

type Filter<FormData> = {
  label?: string
  fieldName: Path<FormData>
  default?: string | number
  type?: FilterType
  width?: string
  options?: OptionsType[]
  placeholder?: string
  selectOptions?: OptionsType[]
}
// endregion

export type FilterType = 'text' | 'multi-autocomplete' | 'autocomplete' | 'select' | 'date-picker'
