import React, {useCallback, useMemo, useState} from 'react'
import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import {Card, Chip, Collapse, Fab, FormControl, InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import {Department, DepartmentMember, Outbound, OutboundMember, Queue} from "../../api/types";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import {useTheme} from "@mui/material/styles";
import {useSelector} from "react-redux";
import {getTranslation} from "../../store/selectors";


type AllowedTypes = Queue | Outbound | Department

type Props<T extends AllowedTypes> = {
  items: T[]
  put: (id: string, value: any) => void
  currentId: string
}

export const ImportMembersFrom = <T extends AllowedTypes>({items, put, currentId}: Props<T>) => {
  const theme = useTheme()
  const translation = useSelector(getTranslation)

  const [importFrom, setImportFrom] = useState<string | ''>('')
  const [showSelect, setShowSelect] = useState<boolean>(false)

  const currentlyAssigned = useMemo(() => items.filter(item => {
    if (String(item.id).includes('@')) {
      return item.id !== currentId
    } else {
      return parseInt(item.id as string) !== parseInt(currentId)
    }
  }), [currentId, items])

  const handleChange = useCallback((event: any) => {
    put(currentId, event.target.value)
    setImportFrom(event.target.value)
    setShowSelect(false)

  }, [put, currentId, setShowSelect])

  return (<Container>
    <Card sx={{width: 'auto', p: '10px 17px', boxShadow: showSelect ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px;' : ''}}>
      <ButtonGrid hide={!showSelect}>

        <ButtonContainer>
          <Tooltip title={showSelect ? translation.input.undo : translation.input.import}>
            <span>
              <Fab size="small" sx={{
                mx: 1,
                bgcolor: showSelect ? (theme.palette.mode === 'dark' ? 'error.main' : 'error.main') : (theme.palette.mode === 'dark' ? 'info.main' : 'info.main'),
                '&:hover': {bgcolor: showSelect ? 'error.light' : 'info.light'}
              }}
                   onClick={() => setShowSelect((prev) => !prev)}>
                {showSelect ? <CloseIcon sx={{color: theme.palette.mode !== 'dark' ? 'grey.300' : 'grey.300'}}/> :
                  <ContentPasteIcon sx={{color: theme.palette.mode !== 'dark' ? 'grey.300' : 'grey.300'}}
                                    color="action"/>}
              </Fab>
            </span>
          </Tooltip>
        </ButtonContainer>
        <Collapse orientation="horizontal" in={showSelect}>
          <SelectContainer>
            <FormControl fullWidth>
              <InputLabel id="import-members-from">{translation.input.importFrom}</InputLabel>
              <Select
                labelId="import-members-from"
                value={importFrom}
                onChange={handleChange}
                variant={'outlined'}
                fullWidth={true}
                name={'importFrom'}>
                {currentlyAssigned.length > 0 && currentlyAssigned.map((item) => {
                  const members = item.members?.filter((m: any) => !m?.deleted)
                  return item && item?.members && item?.members.length > 0 ?
                    (
                      <MenuItem key={`import-members-from-${item.id}`} value={item.id}>
                        <Tooltip
                          sx={{bgcolor: theme.palette.mode === 'dark' ? 'grey.300' : 'grey.500'}}
                          placement='right'
                          title={members && members.length > 0 && members.map((member) => member && (
                            <Chip
                              key={`chip-${(member as DepartmentMember)?.user?.username || (member as OutboundMember)?.voip?.extension} `}
                              size={'small'}
                              sx={{m: '2px', color: 'white'}}
                              label={(member as DepartmentMember)?.user?.username || (member as OutboundMember)?.voip?.extension || ''}/>))}>

                          <div style={{width: '100%', height: '100%'}}>{`${item.name || (item as any).descr} (${item.id})`}</div>
                        </Tooltip>
                      </MenuItem>)
                    :
                    <Tooltip title={translation.input.noMembers} placement='right'>
                      <span>
                        <MenuItem key={`import-members-from-${item.id}`} value={item.id} disabled>
                        {`${item.name || (item as any).descr} (${item.id})`}
                      </MenuItem>
                      </span>
                    </Tooltip>
                })}
              </Select>
            </FormControl>
          </SelectContainer>

        </Collapse>

      </ButtonGrid>
    </Card>
  </Container>)
}

const Container = styled.div`
  width: 100%;
  height: min-content;
  padding: 1.6rem 16px;
  display: flex;
  flex-shrink: 1;
`


const ButtonContainer = styled.div`
  width: 50px;
  min-width: 50px;
  min-height: 60px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonGrid = styled.div<{ hide: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  transition-delay: 0.1s;
  transition: 0.1s;
  gap: ${({hide}) => hide ? '0' : '17px'};

`

const SelectContainer = styled.div`
  min-width: 500px;
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
