import {Pbx} from '../../api/types'

export const SET_TITLE = 'SET_TITLE'
export const SET_BREADCRUMB = 'SET_BREADCRUMB'
export const SET_PATH = 'SET_PATH'
export const SET_THEME = 'SET_THEME'
export const SET_PBXS = 'SET_PBXS'
export const SELECT_PBX = 'SELECT_PBX'
export const NOTIFY = 'NOTIFY'
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION'
export const SET_SELECTED_TABLE_ROWS = 'SET_SELECTED_TABLE_ROWS'
export const SET_WARNINGS = 'SET_WARNINGS'
export const SET_LOADING = 'SET_LOADING'
export const SET_BUTTON_LOADING = 'SET_BUTTON_LOADING'
export const SET_HELP_PAGE = 'SET_HELP_PAGE'
export const SET_OCCLIENT_REF = 'SET_OCCLIENT_REF'

//region Types
export type Theme = 'light' | 'dark'

export interface RestNotification {
  open: boolean
  message: string
  severity: 'success' | 'error' | 'warning' | 'info'
}

export interface WarningItem {
  severity: string
  timestamp: string
  message: string
  actionButton?: {
    onClick: () => void
    label: string
  }
  confirmationAlert?: {
    message: string
    title: string
  }
}

interface HelpTab {
  label: string
  content: string
}

export interface HelpPage {
  title: string
  tabs: HelpTab[]
}

export interface ApplicationState {
  title: string
  breadcrumb: string[]
  helpPage: HelpPage
  path: string
  theme: Theme
  pbxs: Pbx[]
  selectedPbx: number | null
  restNotification: RestNotification
  selectedTableRows: any
  warnings: WarningItem[]
  loading: boolean
  buttonLoading: boolean
  ocClientRef: Window | null
}

//endregion

//region Actions

export interface SetPath {
  type: typeof SET_PATH
  payload: string
}

export interface SetTitleAction {
  type: typeof SET_TITLE
  payload: { title: string; breadcrumb: string[] }
}

export interface SetThemeAction {
  type: typeof SET_THEME
  payload: Theme
}

export interface SetPbxs {
  type: typeof SET_PBXS
  payload: Pbx[]
}

export interface SelectPbx {
  type: typeof SELECT_PBX
  payload: number
}

export interface NotifyAction {
  type: typeof NOTIFY
  payload: RestNotification
}

export interface ResetNotificationAction {
  type: typeof RESET_NOTIFICATION
}

export interface selectedTableRows {
  type: typeof SET_SELECTED_TABLE_ROWS
  payload: any[]
}

export interface SetWarnings {
  type: typeof SET_WARNINGS
  payload: WarningItem[]
}

export interface SetLoading {
  type: typeof SET_LOADING
  payload: boolean
}

export interface SetButtonLoading {
  type: typeof SET_BUTTON_LOADING
  payload: boolean
}

export interface SetBreadCrumb {
  type: typeof SET_BREADCRUMB
  payload: string[]
}

export interface SetHelpPage {
  type: typeof SET_HELP_PAGE
  payload: HelpPage
}

export interface SetOCClientRef {
  type: typeof SET_OCCLIENT_REF
  payload: Window | null
}


export type ApplicationStateActionTypes =
  | SetPath
  | SetTitleAction
  | SetThemeAction
  | NotifyAction
  | ResetNotificationAction
  | SetPbxs
  | SelectPbx
  | selectedTableRows
  | SetWarnings
  | SetLoading
  | SetButtonLoading
  | SetBreadCrumb
  | SetHelpPage
  | SetOCClientRef
//endregion
