import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../store/selectors'
import { useRest } from '../../hooks'
import { logger } from '../../helpers'
import { FractionPlot } from './FractionPlot'
import styled from 'styled-components'

export const AuthMethodSideColumn: React.FC = () => {
  const translation = useSelector(getTranslation)

  const { result: licenses, getById } = useRest('')

  useEffect(() => {
    getById({id: 'available-licenses'}).catch((e) => logger.error(e))
  }, [getById])

  return (
    <ColumnContainer >
      <FractionPlot title={translation.input.availableUserLicenses} numerator={licenses.userLicenses?.available || 0} denominator={licenses.userLicenses?.all || 0} />
      <FractionPlot title={translation.input.availableOperatorLicenses} numerator={licenses.operatorLicenses?.available || 0} denominator={licenses.operatorLicenses?.all || 0} />
      <FractionPlot title={translation.input.availableChatLicenses} numerator={licenses.chatLicenses?.available || 0} denominator={licenses.chatLicenses?.all || 0} />
    </ColumnContainer>
  )
}

const ColumnContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 13rem 13rem 13rem 1fr;
  width: 20rem;
  row-gap: ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(2)};
`