import { StatusesLabels } from '../api/types'

type StatusesColors = {
  [key in StatusesLabels]: string
}

const statusesColors: StatusesColors = {
  DISCONNECTED: '#a5a5a5',
  NOT_FOUND: '#000',
  IDLE: '#3aa619',
  BUSY_IN: '#d4721b',
  BUSY_OUT: '#d4721b',
  IN_CALL: '#d50d0d',
  IN_CALL_IN: '#d50d0d',
  IN_CALL_OUT: '#d50d0d',
  ON_HOLD: '#EE82EE'
}

export default statusesColors
