import { Translation } from '../store/language/types'

const getErrorTranslation = (rawError: {message: string, type: string}, translation: Translation) => {
  if (!translation.errors[rawError.message]) {
    return rawError.message
  } else {
    return translation.errors[rawError.message]
  }
}


/**
 * metodo per tradurre l'errore rispetto all'oggetto errors ottenuto da hook form
 * @param translation oggetto con la traduzione
 * @param errors oggetto degli errori della form
 * @param name nome del campo corrente
 * @param message messaggio di default
 */
function getErrorMessage(translation: Translation, errors: any, name: string, message: string) {
  const [entity, index, field] = name.split('.')
  // @ts-ignore
  if (!errors[entity]) return message
  // @ts-ignore
  if (!errors[entity].type) {
    if(Array.isArray(errors[entity])) {
      return translation.errors[errors[entity][index][field].message]
    }
    if(errors[entity][index]?.type === 'validate') {
      return getErrorTranslation(errors[entity][index], translation)
    }
    return message
  }
  // Nel caso di validatori custom il type è sempre validate e la discrimante è nel message
  if (errors[entity].type === 'validate') {
    return getErrorTranslation(errors[entity], translation)
  }
  // Altrimenti la discriminante è nel campo type per i validatori di default
  // @ts-ignore
  if (!translation.errors[errors[entity].type]) {
    // @ts-ignore
    console.log('******** NEW ERROR TYPE, field: ', entity, 'type: ', errors[entity].type)
    return message
  }

  return translation.errors[errors[entity].type]
}

export default getErrorMessage
