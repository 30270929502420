import { Box, Card, Typography } from '@mui/material'
import React, { ReactElement, useMemo } from 'react'
import styled from 'styled-components'
import { Hint } from './Hint'

export const TitledCard: React.FC<{
  title: string
  children: any
  hint?: string
  component?: 'Card' | 'div'
  overflowContentAuto?: boolean
  sx?: object
  buttons?: ReactElement[]
}> = ({ title, children, hint = '', component = 'Card',overflowContentAuto= false, sx = {}, buttons }) => {
  const Component = component === 'Card' ? Card : 'div'

  const componentStyle = useMemo(()=> ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    ...sx,
    gap: 2
  }) as any, [sx])

  return (
    <Component
      variant={'elevation'}
      style={component !== 'Card' ? componentStyle : {}}
      sx={component === 'Card' ? componentStyle : {}}>
      <TitleContainer>
        <TitleBox>
          <Typography
            variant={'subtitle1'}
            sx={{ placeSelf: 'center start', fontWeight: 600, color: 'primary.dark' }}>
            {title}
          </Typography>
          {hint && <Hint title={title} content={hint} />}
        </TitleBox>
        {buttons && buttons.length > 0 && <ButtonsContainer>{buttons}</ButtonsContainer>}
      </TitleContainer>
      <Box sx={{ px: 2, height: 'calc(100% - 1rem)', overflow: overflowContentAuto ? 'auto' : 'hidden' }}>{children}</Box>
    </Component>
  )
}

const TitleContainer = styled.div`
  display: flex;
  margin: 0.6rem;
  height: 1rem;
`

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
`

const ButtonsContainer = styled.div`
  display: flex;
  width: 20%;
  gap: 30px;
  justify-content: flex-end;
  margin-right: 32px;
  height: 100%;
`
