import {history} from '../../constants'
import logger from '../../helpers/logger'
import log from '../../helpers/logger'
import {getLogin, getOtpRequired, getRenewTimeout} from '../selectors'
import {LoginMethod, RootState, SetRenewTimeout} from '../../store/types'
import {ThunkDispatch} from 'redux-thunk'
import {coreLogin, getAuthToken, getNewToken, getPayloadByLoginMethod} from '../../api/authentication'
import {openPopup} from '../../api/client'
import * as t from './types'
import {setTranslation} from "../language/actions"
import {getTranslation} from "../../helpers/getTranslation"
import {connectToSocket, disconnectFromSocket, setTheme} from '../applicationState/actions'
import {setStatsFavourites} from '../stats/actions'

export const login = (payload: t.LoginPayload) => (dispatch: ThunkDispatch<any, any, any>) => {
  sessionStorage.setItem('token', payload.token)
  sessionStorage.setItem('user', JSON.stringify({...payload.user}))
  dispatch(handleRenewToken(payload.token))
  !!payload?.user?.roles?.length && openPopup(payload)
  connectToSocket(payload)
  dispatch({type: t.LOGIN, payload})
}

export const tokenRenewed = (payload: t.LoginPayload): t.LoginActionTypes => {
  sessionStorage.setItem('token', payload.token)
  window.occlient?.renewToken(payload)
  return {type: t.RENEW_TOKEN, payload}
}

export const handleLogout = () => (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
  const timeout = getRenewTimeout(getState())
  timeout && clearTimeout(timeout)
  disconnectFromSocket()
  dispatch(logout())
}


export const logout = (): t.LoginActionTypes => {
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('user')
  window.occlient?.logout()
  //toggleClientMountAndSavePreference()
  return {type: t.LOGOUT}
}
export const setLoginError = (payload: string): t.LoginActionTypes => ({
  type: t.LOGIN_ERROR,
  payload
})
export const setLoginMethods = (payload: { methods: LoginMethod[]; otp: boolean }): t.LoginActionTypes => ({
  type: t.SET_LOGIN_METHODS,
  payload
})
export const selectLoginMethod = (payload: t.LoginMethod | null): t.LoginActionTypes => {
  if (payload) sessionStorage.setItem('selectedLoginMethod', JSON.stringify(payload))
  else sessionStorage.removeItem('selectedLoginMethod')
  return {type: t.SELECT_LOGIN_METHOD, payload}
}

/** è stato reperito un code da querystring */
export const gotAuthCode = (code: string) => (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
  const {selectedMethod} = getLogin(getState())
  const selectedFromLS = sessionStorage.getItem('selectedLoginMethod')
  const selected = selectedMethod || (selectedFromLS ? JSON.parse(selectedFromLS) : null)
  const OTP = getOtpRequired(getState())

  selected &&
  getAuthToken(selected, code)
    .then((token: string | null) => {
      if (token) {
        if (OTP) {
          log.silly('OTP is required, redirecting to otp page')
          history.push('/login/otp', {token})
        } else {
          log.silly('Got authorization code, logging in to core')
          dispatch(loginWithPayload(selected.id, token))
        }
      } else {
        history.push('/login')
      }
    })
    .catch((e) => {
      log.error('getAuthToken failed', e)
      history.push('/login')
    })
}

/** è stato reperito un token da querystring */
export const gotAuthToken = (token: string) => (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
  const {selectedMethod} = getLogin(getState())
  const selectedFromLS = sessionStorage.getItem('selectedLoginMethod')
  const selected = selectedMethod || (selectedFromLS ? JSON.parse(selectedFromLS) : null)
  const OTP = getOtpRequired(getState())

  if (OTP) {
    history.push('/login/otp', {token})
  } else {
    selected && dispatch(loginWithPayload(selected.id, token))
    window.history.replaceState({}, '', window.location.href.replace(window.location.search, ''))
  }
}

export const gotTokenAndUser = (token: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  getNewToken(token)
    .then((loginPayload) => {
      try {
        dispatch(login(loginPayload))
        let pathToGo = sessionStorage.getItem('pathBeforeUnload') || sessionStorage.getItem('pathAfterLogin')
        if (!pathToGo || pathToGo === '/login') {
          if (!loginPayload.user?.actions?.view?.find((action) => action === 'dashboard')) {
            if (loginPayload.user?.roles?.length) {
              pathToGo = '/onlyclient'
            } else {
              pathToGo = '/norole'
            }
          } else {
            pathToGo = '/tools/dashboard'
          }
        }
        history.push(pathToGo)
      } catch (e) {
        logger.error(e)
      }
    })
    .catch((e) => {
      log.error('Could not renew token', e)
      dispatch(handleLogout())
      history.push('/login')
    })
}

export const loginWithPayload = (loginMethodId: number, token: string, payload: Object = {}) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => RootState
) => {
  const {isAuthenticated} = getLogin(getState())
  if (isAuthenticated) {
    log.error('Already authenticated')
    return
  }

  const payloadToSend = getPayloadByLoginMethod(loginMethodId, token, payload)

  coreLogin(loginMethodId, payloadToSend)
    .then((loginPayload) => {
      try {
        // se il core risponde correttamente cancello il metodo di login selezionato e salvo su redux le informazioni
        // avvio inoltre la routine per il rinnovo del token
        log.info('Login successful')

        const languageCode = loginPayload.user.language?.code || 'it_IT'
        getTranslation(languageCode).then((data) => {
          dispatch(setTranslation(languageCode, {...data}))
        })
        dispatch(setTheme(loginPayload.user.theme || sessionStorage.getItem('theme') || 'light'))
        dispatch(login(loginPayload))
        dispatch(setStatsFavourites(loginPayload.user.statsFavorites))
        let pathToGo = sessionStorage.getItem('pathBeforeUnload') || sessionStorage.getItem('pathAfterLogin')
        if (!pathToGo || pathToGo === '/login') {
          if (!loginPayload.user?.actions?.view?.find((action) => action === 'dashboard')) {
            if (loginPayload.user?.roles?.length) {
              pathToGo = '/onlyclient'
            } else {
              pathToGo = '/norole'
            }
          } else {
            pathToGo = '/tools/dashboard'
          }
        }
        history.push(pathToGo)
      } catch (e) {
        logger.error(e)
      }
    })
    .catch((e) => {
      log.error('coreLogin error', e)
      dispatch(setLoginError(e.message))
      history.push('/login')
    })
    .finally(() => {
      sessionStorage.removeItem('pathBeforeUnload')
      sessionStorage.removeItem('pathAfterLogin')
      sessionStorage.removeItem('selectedLoginMethod')
    })
}

export const handleRenewToken = (tokenToUse: string) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => RootState
) => {
  const timeout = window.setTimeout(() => {
    const {isAuthenticated} = getLogin(getState())
    isAuthenticated && getNewToken(tokenToUse)
      .then((renewPayload) => {
        dispatch(tokenRenewed(renewPayload))
        dispatch(handleRenewToken(renewPayload.token))
        return renewPayload
      })
      .catch(() => {
        dispatch(handleLogout())
        history.push('/login')
      })
  }, renewTokenInterval)
  dispatch(setRenewTimeout(timeout))
}

export const setRenewTimeout = (payload: number): SetRenewTimeout => ({
  type: t.RENEW_TOKEN_TIMEOUT,
  payload
})


const renewTokenInterval = (Math.random() * 20 + 30) * 60000
