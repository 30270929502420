import styled from 'styled-components'
import { Select, TitledCard } from '../../components/common'
import { useDispatch, useSelector } from 'react-redux'
import { getPbxs, getSelectedPbx, getTranslation } from '../../store/selectors'
import { useForm, useWatch } from 'react-hook-form'
import { MenuItem } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { selectPbx } from '../../store/actions'

export const PbxSelector: React.FC = () => {
  const translation = useSelector(getTranslation)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const availablePbxs = useSelector(getPbxs)
  const actualPbx = useSelector(getSelectedPbx)

  const { control, setValue } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...defaultValues },
    shouldUnregister: false
  })
  const selectedPbx = useWatch({ name: 'selectedPbx', control })

  const pbxId = useMemo(() => location.pathname.split('/')[2], [location.pathname])

  useEffect(() => {
    if (availablePbxs.length === 0) return
    availablePbxs.sort((a,b) => a.id - b.id)
    setValue('selectedPbx', `${actualPbx}` || `${availablePbxs[0].id}`)
  }, [availablePbxs, actualPbx, setValue])
  useEffect(() => {
    if (!selectedPbx || !pbxId || selectedPbx === pbxId) return
    const currentPathnameChunks = location.pathname.split('/')
    // cambio l'id del pbx nell'url ed in caso mi trovi in una pagina di dettaglio ritorno alla pagina di disambiguazione
    currentPathnameChunks[2] = selectedPbx
    if (currentPathnameChunks.length > 4) currentPathnameChunks.pop()
    const newPathName = currentPathnameChunks.join('/')
    navigate(newPathName)
    dispatch(selectPbx(parseInt(selectedPbx)))
  }, [selectedPbx, pbxId, location.pathname, dispatch, navigate])

  return (
    <TitledCard title={translation.input.pbx}>
      <SelectContainer>
        <Select<FormData>
          name="selectedPbx"
          control={control}
          label={''}
          variant={'standard'}
          containerStyle={{ width: '70%' }}
          placeholder={translation.input.pbxSelect}
          hasPermission={true}
          leaveSpaceForHint={false}>
          {availablePbxs.map((pbx) => (
            <MenuItem key={`${pbx.id}`} value={`${pbx.id}`}>
              {pbx.name}
            </MenuItem>
          ))}
        </Select>
      </SelectContainer>
    </TitledCard>
  )
}

export interface FormData {
  selectedPbx: string
}

const defaultValues: FormData = {
  selectedPbx: ''
}

// region style
const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 27px;
`
// endRegion
