import { CheckCircleRounded, ErrorRounded } from '@mui/icons-material'
import { SvgIcon } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

interface Props {
  result: 'success' | 'error'
  message: string
}

/**
 * Componente Wrapper per le notifiche
 * @param props.result {'success' | 'error'} tipo della notifica
 * @param props.message {string} messaggio da visualizzare sulla notifica
 * @constructor
 */
const NotificationContent: React.FC<Props> = (props) => {
  const { result, message } = props
  if (result === 'success')
    return (
      <Container>
        <ImgItem>
          <SvgIcon component={CheckCircleRounded} />
        </ImgItem>
        <Message>{message || 'Operazione eseguita con successo'}</Message>
      </Container>
    )
  else
    return (
      <Container>
        <ImgItem>
          <SvgIcon component={ErrorRounded} />
        </ImgItem>
        <Message>{message || 'Si è verificato un errore.'}</Message>
      </Container>
    )
}

export { NotificationContent }

//region Style

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`

const ImgItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  & img {
    width: 15px;
  }
`

const Message = styled.span`
  height: 100%;
`

//endregion
