import { getErrorMessage } from '../../helpers'
import { ErrorMessage } from '@hookform/error-message'
import { Popover, SvgIcon, TextField, TextFieldProps } from '@mui/material'
import { getTranslation } from '../../store/selectors'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { ChromePicker, Color } from 'react-color'
import { useController, UseControllerProps, useFormState } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Hint } from './Hint'
import {FieldValues} from "react-hook-form/dist/types";

type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> &
  TextFieldProps & {
    hint?: string
    leaveSpaceForHint?: boolean
    containerStyle?: React.CSSProperties
    hasPermission?: boolean
  }

export function ColorPicker<TFieldValues extends FieldValues>(props: PropsWithChildren<Props<TFieldValues>>) {
  const {
    control,
    name,
    rules,
    variant = 'outlined',
    hint,
    containerStyle,
    leaveSpaceForHint = true,
    disabled,
    InputProps,
    hasPermission,
    ...rest
  } = props

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'color-picker-popover' : undefined

  const { field } = useController({ control, name, rules })
  const { errors } = useFormState<TFieldValues>({ control, name })
  const translation = useSelector(getTranslation)

  const getMessage = (message = '') => getErrorMessage(translation, errors, name, message)

  const isEnabled = useMemo((): boolean => {
    // inverte disabled se passato come prop
    const enabled = typeof disabled === 'boolean' ? !disabled : true
    // prende readOnly se è defined, oppure enabled che è true di default
    const fromProps = InputProps?.readOnly ?? enabled
    // se non viene passato hasPermission mi affido alle altre prop
    if (hasPermission === undefined) {
      return fromProps
    } else {
      // altrimenti deve anche avere il permesso oltre a dipendere dalle altre props
      return hasPermission && fromProps
    }
  }, [hasPermission, disabled, InputProps])

  return (
    <Container style={containerStyle} leaveSpaceForHint={leaveSpaceForHint}>
      <TextField
        variant={variant}
        {...field}
        {...rest}
        sx={{ ...rest.sx, gridArea: 'input' }}
        fullWidth={true}
        onClick={handleClick}
        disabled={!isEnabled}
        InputProps={{
          ...InputProps,
          readOnly: !isEnabled,
          startAdornment: (
            <SvgIcon sx={{ mr: 1 }}>
              <ColoredCircle color={field.value as string} />
            </SvgIcon>
          )
        }}
      />
      <ErrorMessage errors={errors} name={name as any} render={({ message }) => <Error>{getMessage(message)}</Error>} />
      {hint && <Hint title={typeof rest.label === 'string' ? rest.label : ''} content={hint} />}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <ChromePicker color={field.value as Color} onChange={({ hex }) => field.onChange(hex)} />
      </Popover>
    </Container>
  )
}

const ColoredCircle = ({ color }: { color: string }) => {
  return (
    <svg viewBox="0 0 24 24" width={25} height={25}>
      <circle cx={12} cy={12} r={10} fill={color || 'transparent'} />
    </svg>
  )
}

//region Style

const Container = styled.div<{ leaveSpaceForHint: boolean }>`
  display: grid;
  grid-template-areas: 'input hint' 'error error';
  grid-template-columns: 1fr ${(props) => (props.leaveSpaceForHint ? '30px' : 'auto')};
  grid-template-rows: min-content 20px;
`

const Error = styled.div`
  grid-area: error;
  color: ${(props) => props.theme.palette.error.main};
  font-size: 0.8rem;
  margin-left: ${(props) => props.theme.spacing(1)};
`
//endregion
