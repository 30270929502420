import { LanguageCode } from '../../api/types'

export const SET_LANGUAGE = 'SET_LANGUAGE'

export interface Translation {
  menu: {
    [k: string]: any
  }
  status: {
    [k: string]: any
  }
  button: {
    [k: string]: any
  }
  pages: {
    [k: string]: any
  }

  [k: string]: any
}

export interface LanguageState {
  selected: LanguageCode
  translation: Translation
}

export interface SetTranslation {
  type: typeof SET_LANGUAGE
  payload: LanguageState
}

export type TranslationActionTypes = SetTranslation
