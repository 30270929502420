import { getTranslation } from '../../../../../store/selectors'
import React from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import StatCard from '../common/StatCard'

const Callbacks: React.FC = () => {
  const translation = useSelector(getTranslation)

  return (
    <Container>
      <StatCard
        type="bar"
        entity="callbacks"
        title={translation.stats.callbacks.detailsByUsers.title}
        description={translation.stats.callbacks.detailsByUsers.description}
        path="/tools/stats/callbacks/details-by-users"
      />
      <StatCard
        type="bar"
        entity="callbacks"
        title={translation.stats.callbacks.detailsByHourBands.title}
        description={translation.stats.callbacks.detailsByHourBands.description}
        path="/tools/stats/callbacks/by-interval"
      />
      <StatCard
        type="bar"
        entity="callbacks"
        title={translation.stats.callbacks.detailsByWeekDays.title}
        description={translation.stats.callbacks.detailsByWeekDays.description}
        path="/tools/stats/callbacks/details-by-weekdays"
      />
      <StatCard
        type="bar"
        entity="callbacks"
        title={translation.stats.callbacks.detailsByService.title}
        description={translation.stats.callbacks.detailsByService.description}
        path="/tools/stats/callbacks/details-by-service"
      />
    </Container>
  )
}

export default Callbacks

const Container = styled(ScrollBar)`
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-template-rows: repeat(auto-fit, 240px);
  gap: ${(props) => props.theme.spacing(3)};
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(3)};
`
