import {getToken} from '../../store/selectors'
import React from 'react'
import {useSelector} from 'react-redux'
import {Navigate} from 'react-router-dom'

interface withAuthenticationProps {}

const withAuthentication = <T extends withAuthenticationProps = withAuthenticationProps>(
  WrappedComponent: React.ComponentType<T>
) => {
  return (props: T) => {
    const token = useSelector(getToken)

    if (token) {
      return <WrappedComponent {...(props as T)} />
    } else {
      sessionStorage.setItem('pathAfterLogin', window.location.pathname)
      return <Navigate replace to="/login" />
    }
  }
}

export default withAuthentication
