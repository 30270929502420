import {Box, Tab, Tabs as MuiTabs, Tooltip} from '@mui/material'
import { CSSProperties } from '@mui/styles'
import React from 'react'

type Props = {
  tabs: TabProps[]
  orientation?: Orientation
  sx?: CSSProperties
  buttons?: Array<((tab: number) => React.ReactNode) | React.ReactNode>
}
export const Tabs: React.FC<Props> = ({ tabs, orientation = 'horizontal', sx = {}, buttons }) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ ...outerStyle(orientation), overflow: 'hidden', ...sx }}>
      <Box sx={innerStyle(orientation)} display="flex" justifyContent="space-between">
        <MuiTabs value={value} onChange={handleChange} aria-label="tabs" orientation={orientation}>
          {tabs.map(({ component, label, disabled, tooltipLabelForDisabled, ...tabProps }, index) => (
            disabled ? (
              <Tooltip key={`tooltip-${index}`} title={tooltipLabelForDisabled}>
                <span style={{ display: 'inline-block' }}>
                  <Tab
                    key={`tab-name-${index}`}
                    {...tabProps}
                    {...a11yProps(index)}
                    sx={{ minWidth: 0 }}
                    label={label}
                    disabled={disabled}
                  />
                </span>
              </Tooltip>
            ) : (
              <Tab
                key={`tab-name-${index}`}
                {...tabProps}
                {...a11yProps(index)}
                sx={{ minWidth: 0 }}
                label={label}
                disabled={disabled}
              />
            )
          ))}
        </MuiTabs>
        {buttons && <Box display="flex">{buttons.map((b) => (typeof b === 'function' ? b(value) : b))}</Box>}
      </Box>
      {tabs.map(({ component }, index) => (
        <div
          key={`tab-panel-${index}`}
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          style={{ height: orientation === 'vertical' ? '100%' : 'calc(100% - 4rem)', width: '100%' }}>
          {component}
        </div>
      ))}
    </Box>
  )
}

// region helpers
const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}
const outerStyle = (orientation: Orientation) => {
  return orientation === 'vertical'
    ? {
        flexGrow: 1,
        display: 'flex',
        width: '100%',
        height: '100%'
      }
    : {
        display: 'flex',
        flexDirection: 'column' as const,
        width: '100%',
        height: '100%'
      }
}
const innerStyle = (orientation: Orientation) => {
  return orientation === 'vertical'
    ? {
        color: 'divider',
        borderRight: 1
      }
    : { height: '4rem' }
}
// endregion

// region types
export type TabProps =  {
  value?: number
  label: string | number
  disabled?: boolean
  component?: any
  tooltipLabelForDisabled?: string
}

type Orientation = 'vertical' | 'horizontal'
// endregion
