import React from "react"
import {Box, Drawer, Typography} from "@mui/material"
import {Tabs} from "../../common"
import {getHelpPage} from "../../../store/selectors"
import {useSelector} from "react-redux"
import ReactHtmlParser from 'react-html-parser'

const HelpMenu: React.FC<{isHelpOpen: boolean, setIsHelpOpen: (isHelpOpen: boolean) => void}> = ({isHelpOpen, setIsHelpOpen}) => {
  const helpPage = useSelector(getHelpPage)

  return <Drawer open={isHelpOpen} anchor={'top'} onClose={() => setIsHelpOpen(false)}>
    <Typography sx={{width: '100%', whiteSpace: 'pre-wrap', color: 'primary.main', fontWeight: 500, padding: 1}} variant={'h2'}>{helpPage.title}</Typography>
    <Tabs orientation={'vertical'} sx={{py: 1}} tabs={helpPage.tabs.map((tab, index) => {
      return {
        label: tab.label,
        value: index,
        component: <Box sx={{height: 'min-content', padding: '16px'}}><Typography sx={{width: '100%'}} component={'div'} variant={'body1'}>{ReactHtmlParser(tab.content)}</Typography></Box>
      }
    })}/>
  </Drawer>
}

export {HelpMenu}