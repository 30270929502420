import { HelpRounded } from '@mui/icons-material'
import { TextField, Typography } from '@mui/material'
import { getTranslation } from '../../store/selectors'
import React, {ReactNode} from 'react'
import { FieldError } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

interface Props {
  label: string
  inputProps: any
  width?: string
  readOnly?: boolean
  select?: boolean
  type?: 'number' | 'time'
  min?: number
  max?: number
  placeHolder?: string
  error?: FieldError
  showInfo?: (name: string | null) => void
  selectProps?: any,
  children: ReactNode
}

/**
 * Stile per il textfield
 * @param props.label{string}
 * @param props.inputProps {any}
 * @param props.width {string}
 * @param props.readOnly {boolean}
 * @param props.select {boolean}
 * @param props.type {'number' | 'time'}
 * @param props.min {number}
 * @param props.max {number}
 * @param props.placeHolder {string}
 * @param props.showInfo {(name:string | null) => void}
 * @constructor
 */

export const StyledTextField: React.FC<Props> = (props) => {
  const {
    label,
    inputProps,
    width = '30%',
    readOnly = false,
    select = false,
    children,
    type,
    min = type ? 0 : undefined,
    max = undefined,
    placeHolder = undefined,
    error,
    showInfo,
    selectProps = {}
  } = props

  const translation = useSelector(getTranslation)

  const getErrorMessage = () => {
    if (!error) return
    const type = error.type
    return translation.error[type] || translation.error.generic
  }

  //ATTENZIONE DEVE ESSERE DOPPIO PERCHE SI PERDE LO SHRINK DELLA LABEL
  return (
    <InputContainer width={width}>
      <ErrorContainer width={width}>
        {type === 'time' ? (
          <Input
            ref={inputProps.ref}
            type={'time'}
            {...inputProps}
            label={label}
            InputProps={{
              readOnly: readOnly,
              inputProps: {
                min: min,
                max: max
              }
            }}
            InputLabelProps={{
              shrink: true
            }}
            select={select}
            placeholder={placeHolder}
            error={!!error}>
            {children && children}
          </Input>
        ) : (
          <Input
            ref={inputProps.ref}
            type={type}
            {...inputProps}
            label={label}
            InputProps={{
              readOnly: readOnly,
              inputProps: {
                min: min,
                max: max
              }
            }}
            select={select}
            SelectProps={selectProps}
            placeholder={placeHolder}
            error={!!error}>
            {children && children}
          </Input>
        )}
        {!!error && (
          <ErrorMessage variant="inherit" color="error">
            {getErrorMessage()}
          </ErrorMessage>
        )}
      </ErrorContainer>
      {!!showInfo && (
        <HelpRounded
          fontSize={'small'}
          style={{ marginTop: '1.2rem', placeSelf: 'center' }}
          onMouseLeave={() => showInfo(null)}
          onMouseEnter={() => showInfo(`${inputProps.name}Hint`)}
        />
      )}
    </InputContainer>
  )
}

const InputContainer = styled.div<{ width: string }>`
  display: grid;
  grid-template-columns: auto max-content;
  margin-right: 0.5rem;
  gap: 0.5rem;
  width: ${({ width }) => width};
  min-width: 10rem;
`
const ErrorContainer = styled.div<{ width: string }>`
  display: grid;
  min-width: ${({ width }) => width};
  grid-template-rows: max-content 1rem;
`
const Input = styled(TextField)`
  margin: 0.5rem;

  & svg {
    fill: ${({ theme }) => theme.palette.secondary.contrastText} !important;
  }
`
const ErrorMessage = styled(Typography)`
  place-self: center;
  font-size: 0.7rem;
`
