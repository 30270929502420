import imgBackground from '../../../images/login-background.jpeg'
import { Button, TextField, useMediaQuery } from '@mui/material'
import { loginWithPayload } from '../../../store/actions'
import { getLogin, getTheme, getTranslation } from '../../../store/selectors'
import React, { FormEventHandler, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as LogoIcon } from '../../../images/Oc.svg'
import { ReactComponent as Logo } from '../../../images/OC_full_white.svg'
import { ReactComponent as LogoIconDark } from '../../../images/OC_white.svg'

const OTP: React.FC = () => {
  const [otp, setOtp] = useState('')
  const { selectedMethod, error } = useSelector(getLogin)
  const theme = useSelector(getTheme)
  const translation = useSelector(getTranslation)

  const dispatch = useDispatch()

  const matches = useMediaQuery('(min-width:1280px)')

  const { state } = useLocation()

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    const selectedFromLS = sessionStorage.getItem('selectedLoginMethod')
    const selected = selectedMethod || (selectedFromLS ? JSON.parse(selectedFromLS) : null)
    selected && dispatch(loginWithPayload(selected.id, state.token, { otp }) as any)
  }

  return state.token ? (
    <Container>
      <LeftContainer autoComplete="off" onSubmit={handleSubmit}>
        <LogoContainer>
          {theme === 'light' ? <LogoIcon /> : <LogoIconDark />}
          <LogoDisclaimer>{translation.pages.LOGIN.disclaimerOTP}</LogoDisclaimer>
        </LogoContainer>
        <MethodsContainer>
          <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
          <TextField
            label="OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            InputProps={{ autoFocus: true }}
          />
          {error && <Error>{translation.loginErrors.otp}</Error>}
          <Button
            type="submit"
            variant="contained"
            sx={{ width: '100%', mt: 2, placeSelf: 'center', bgcolor: '#e24e13' }}>
            {translation.button.send}
          </Button>
        </MethodsContainer>
      </LeftContainer>
      {matches && (
        <RightContainer>
          <FullLogoContainer>
            <Logo />
          </FullLogoContainer>
          <DarkeningEffect />
        </RightContainer>
      )}
    </Container>
  ) : (
    <Navigate replace to="/login" />
  )
}

export default OTP

//region Style
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @media (min-width: 1280px) {
    grid-template-columns: 1.5fr 2fr;
  }
`

const LeftContainer = styled.form`
  display: grid;
  grid-template-rows: 1fr 1.5fr;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.palette.background.paper};
`

const LogoContainer = styled.div`
  place-self: center start;
  text-align: center;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row-gap: 5px;
  place-items: center;
  & > svg {
    width: auto;
    height: 20%;
    max-height: 180px;
    min-height: 80px;
    place-self: end center;
  }
`

const LogoDisclaimer = styled.div`
  color: #e24e13;
  font-size: 0.8rem;
  width: 80%;
  max-width: 300px;
`

const MethodsContainer = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  place-self: start center;
  padding-top: 0.5rem;
`

const RightContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr;

  width: 100%;
  height: 100%;
  background-image: url('${imgBackground}');
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: relative;
`

const DarkeningEffect = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgb(17, 91, 114), rgba(17, 91, 114, 0));
  mix-blend-mode: multiply;
`

const FullLogoContainer = styled.div`
  place-self: center start;
  text-align: center;
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 1000;
  & > svg {
    width: auto;
    height: 20%;
    max-height: 180px;
    min-height: 80px;
    place-self: end center;
  }
`

const Error = styled.div`
  font-size: 0.7rem;
  color: ${({ theme }) => theme.palette.error.main};
`

//endregion
