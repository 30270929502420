import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Button } from '@mui/material'
import { getUser } from '../../../store/selectors'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import DropdownMenu from './DropdownMenu'

const UserBar: React.FC = () => {
  const user = useSelector(getUser)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <Container onClick={handleClick} variant={'text'} sx={{ color: 'text.primary' }} endIcon={<ArrowDropDownIcon />}>
        {user?.surname} {user?.name}
      </Container>
      <DropdownMenu anchorEl={anchorEl || null} setAnchorEl={setAnchorEl} />
    </>
  )
}

// region style
const Container = styled(Button)`
  grid-area: user-bar;
  display: grid;
  grid-template-columns: auto 0.5rem 2rem;
  cursor: pointer;

  &.MuiButton-text {
    text-transform: capitalize;
  }
`
// endregion

export default UserBar
