import { getErrorMessage } from '../../helpers'
import { ErrorMessage } from '@hookform/error-message'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { TimePickerProps } from '@mui/lab'
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { TextField } from '@mui/material'
import { getLanguage, getTranslation } from '../../store/selectors'
import { DateTime } from 'luxon'
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useController, UseControllerProps, useFormState } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'
import { Hint } from './Hint'
import {amPmCountries} from "./helpers";
import {FieldValues} from "react-hook-form/dist/types";

export function TimePicker<TFieldValues extends FieldValues>(props: PropsWithChildren<Props<TFieldValues>>) {
  const {
    variant = 'outlined',
    control,
    name,
    rules,
    hint,
    containerStyle,
    leaveSpaceForHint = true,
    disabled,
    InputProps,
    hasPermission,
    ...rest
  } = props

  const { field } = useController({ control, name, rules })
  const { errors } = useFormState<TFieldValues>({ control, name })

  const translation = useSelector(getTranslation)
  const language = useSelector(getLanguage)

  const [value, setValue] = useState<DateTime | null>(null)

  useEffect(() => {
    if(field.value === null) return
    setValue(DateTime.fromMillis(field.value as number))
  }, [field.value])

  const getMessage = (message = '') => getErrorMessage(translation, errors, name, message)

  const isEnabled = useMemo((): boolean => {
    // inverte disabled se passato come prop
    const enabled = typeof disabled === 'boolean' ? !disabled : true
    // prende readOnly se è defined, oppure enabled che è true di default
    const fromProps = InputProps?.readOnly ?? enabled
    // se non viene passato hasPermission mi affido alle altre prop
    if (hasPermission === undefined) {
      return fromProps
    } else {
      // altrimenti deve anche avere il permesso oltre a dipendere dalle altre props
      return hasPermission && fromProps
    }
  }, [hasPermission, disabled, InputProps])

  return (
    <Container style={containerStyle} leaveSpaceForHint={leaveSpaceForHint}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={language === 'it_IT' ? 'it' : 'en'}>
        <MuiTimePicker
          {...rest}
          {...field}
          ampm={amPmCountries.includes(navigator.language)}
          ampmInClock={amPmCountries.includes(navigator.language)}
          value={value as any}
          onChange={(data: any) => {
            data && field.onChange((data as any)!.toMillis())
          }}
          renderInput={(params: any) => <TextField variant={variant} {...params} {...(InputProps as any)} disabled={!isEnabled} />}
        />
      </LocalizationProvider>
      <ErrorMessage errors={errors} name={name as any} render={({ message }) => <Error>{getMessage(message)}</Error>} />
      {hint && <Hint title={typeof rest.label === 'string' ? rest.label : ''} content={hint} />}
    </Container>
  )
}

// region type
type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> &
  Partial<TimePickerProps<TFieldValues>> & {
  hint?: string
  leaveSpaceForHint?: boolean
  containerStyle?: CSSProperties
  hasPermission?: boolean
  variant?: 'standard' | 'outlined'
}
// endregion

//region Style

const Container = styled.div<{ leaveSpaceForHint: boolean }>`
  display: grid;
  grid-template-areas: 'date-picker hint' 'error error';
  grid-template-columns: 1fr ${(props) => (props.leaveSpaceForHint ? '30px' : 'auto')};
  grid-template-rows: auto 20px;
`

const Error = styled.div`
  grid-area: error;
  color: ${(props) => props.theme.palette.error.main};
  font-size: 0.8rem;
  margin-left: ${(props) => props.theme.spacing(1)};
`

//endregion
