import React from 'react'
//Amministrazione
const AuthenticationMethod = React.lazy(() => import('./administration/authenticationMethods/AuthenticationMethod'))
const AuthenticationMethods = React.lazy(() => import('./administration/authenticationMethods/AuthenticationMethods'))
const CustomizedMessage = React.lazy(() => import('./administration/chat/customizedMessages/CustomizedMessage'))
const CustomizedMessages = React.lazy(() => import('./administration/chat/customizedMessages/CustomizedMessages'))
const Feedback = React.lazy(() => import('./administration/chat/feedbacks/Feedback'))
const Feedbacks = React.lazy(() => import('./administration/chat/feedbacks/Feedbacks'))
const PrivacyMessage = React.lazy(() => import('./administration/chat/privacyMessages/PrivacyMessage'))
const PrivacyMessages = React.lazy(() => import('./administration/chat/privacyMessages/PrivacyMessages'))
const CRM = React.lazy(() => import('./administration/crms/CRM'))
const CRMs = React.lazy(() => import('./administration/crms/CRMs'))
const SurveyQuestionType = React.lazy(()=> import('./administration/chat/surveyQuestionTypes/SurveyQuestionType'))
const SurveyQuestionTypes = React.lazy(()=> import('./administration/chat/surveyQuestionTypes/SurveyQuestionTypes'))

//Administration
const CustomersSummary = React.lazy(() => import('./administration/customers/CustomersSummary'))
const Customer = React.lazy(() => import('./administration/customers/management/Customer'))
const Customers = React.lazy(() => import('./administration/customers/management/Customers'))
const Logs = React.lazy(() => import('./administration/logs/Logs'))
const MonitoringPanel = React.lazy(() => import('./administration/monitoringPanels/MonitoringPanel'))
const MonitoringPanels = React.lazy(() => import('./administration/monitoringPanels/MonitoringPanels'))
const Organizations = React.lazy(() => import('./administration/organizations/Organizations'))
const OutboundRule = React.lazy(() => import('./administration/outboundRules/OutboundRule'))
const OutboundRules = React.lazy(() => import('./administration/outboundRules/OutboundRules'))
const Pauses = React.lazy(() => import('./administration/pauses/Pauses'))
const Pbx = React.lazy(() => import('./administration/pbx/PBX'))
const Pbxs = React.lazy(() => import('./administration/pbx/PBXs'))
const PredictiveCampaign = React.lazy(() => import('./administration/predictiveCampaigns/PredictiveCampaign'))
const PredictiveCampaigns = React.lazy(() => import('./administration/predictiveCampaigns/PredictiveCampaigns'))
const Provider = React.lazy(() => import('./administration/providers/Provider'))
const Providers = React.lazy(() => import('./administration/providers/Providers'))
const Role = React.lazy(() => import('./administration/roles/Role'))
const Roles = React.lazy(() => import('./administration/roles/Roles'))
const Tenant = React.lazy(() => import('./administration/tenants/Tenant'))
const Tenants = React.lazy(() => import('./administration/tenants/Tenants'))
const User = React.lazy(() => import('./administration/users/User/User'))
const Users = React.lazy(() => import('./administration/users/Users/Users'))

// Services
const Configuration = React.lazy(() => import('./configuration'))
const Announcement = React.lazy(() => import('./configuration/announcements/Announcement'))

// Configuration
const Announcements = React.lazy(() => import('./configuration/announcements/Announcements'))
const Blacklist = React.lazy(() => import('./configuration/blacklist/Blacklist'))
const Extension = React.lazy(() => import('./configuration/extensions/Extension'))
const Extensions = React.lazy(() => import('./configuration/extensions/Extensions'))
const Trunk = React.lazy(() => import('./configuration/trunks/Trunk'))
const Trunks = React.lazy(() => import('./configuration/trunks/Trunks'))
const InboundRoute = React.lazy(() => import('./configuration/inbound-routes/InboundRoute'))
const InboundRoutes = React.lazy(() => import('./configuration/inbound-routes/InboundRoutes'))
const Ivr = React.lazy(() => import('./configuration/ivrs/Ivr/IVR'))
const Ivrs = React.lazy(() => import('./configuration/ivrs/IVRs'))
const MusicOnHold = React.lazy(() => import('./configuration/music-on-hold/MusicOnHold'))
const MusicsOnHold = React.lazy(() => import('./configuration/music-on-hold/MusicsOnHold'))
const PbxQueue = React.lazy(() => import('./configuration/queues/queue/Queue'))
const PbxQueues = React.lazy(() => import('./configuration/queues/Queues'))
const Recording = React.lazy(() => import('./configuration/recordings/Recording'))
const Recordings = React.lazy(() => import('./configuration/recordings/Recordings'))
const RingGroup = React.lazy(() => import('./configuration/ring-groups/RingGroup'))
const RingGroups = React.lazy(() => import('./configuration/ring-groups/RingGroups'))
const TimeCondition = React.lazy(() => import('./configuration/time-conditions/TimeCondition'))
const TimeConditions = React.lazy(() => import('./configuration/time-conditions/TimeConditions'))
const TimeGroup = React.lazy(() => import('./configuration/time-groups/time-group/TimeGroup'))
const TimeGroups = React.lazy(() => import('./configuration/time-groups/TimeGroups'))
const Login = React.lazy(() => import('./login/Login'))
const ServiceDepartment = React.lazy(() => import('./tools/management/departments/ServiceDepartment'))
const ServiceDepartments = React.lazy(() => import('./tools/management/departments/ServiceDepartments'))
const InboundsQueue = React.lazy(() => import('./tools/management/inboundsQueues/InboundsQueue'))
const InboundsQueues = React.lazy(() => import('./tools/management/inboundsQueues/InboundsQueues'))
const Outbound = React.lazy(() => import('./tools/management/outbounds/Outbound'))
const Outbounds = React.lazy(() => import('./tools/management/outbounds/Outbounds'))
const Dashboard = React.lazy(() => import('./tools/dashboard/Dashboard'))

// Tools
const CallsHistory = React.lazy(() => import('./tools/history/calls/CallsHistory'))
const ChatsHistory = React.lazy(() => import('./tools/history/chats/ChatsHistory'))
const VocalOrderHistory = React.lazy(() => import('./tools/history/vocal-orders/VocalOrderHistory'))

//Monitoraggio
const MonitoringCustomer = React.lazy(() => import('./tools/monitoring/customer/Customers'))
const MonitoringDepartment = React.lazy(() => import('./tools/monitoring/department/Department'))
const MonitoringOutbound = React.lazy(() => import('./tools/monitoring/outbound/Outbound'))
const MonitoringQueue = React.lazy(() => import('./tools/monitoring/queue/Queues'))
const MonitoringPredictiveCampaigns = React.lazy(() => import('./tools/monitoring/predictive/PredictiveCampaigns'))
const MonitoringCallbackCalls = React.lazy(() => import('./tools/monitoring/callback/CallbackCalls'))

const Operators = React.lazy(() => import('./tools/operators/Operators'))
const Wizard = React.lazy(() => import('./wizard/Wizard'))

export {
  CallsHistory,
  AuthenticationMethods,
  AuthenticationMethod,
  Blacklist,
  ChatsHistory,
  Customers,
  Customer,
  CustomizedMessage,
  CustomizedMessages,
  ServiceDepartments,
  ServiceDepartment,
  Feedback,
  Feedbacks,
  InboundsQueues,
  InboundsQueue,
  PredictiveCampaigns,
  PredictiveCampaign,
  Logs,
  MonitoringPanels,
  MonitoringPanel,
  Pbxs,
  Pbx,
  Users,
  User,
  Trunks,
  Trunk,
  Role,
  Roles,
  Tenant,
  Tenants,
  CRM,
  CRMs,
  CustomersSummary,
  PrivacyMessage,
  PrivacyMessages,
  Provider,
  Providers,
  Announcements,
  Operators,
  Organizations,
  OutboundRule,
  OutboundRules,
  Outbound,
  Outbounds,
  Announcement,
  Extensions,
  Extension,
  InboundRoutes,
  InboundRoute,
  Ivrs,
  Ivr,
  MonitoringCustomer,
  MonitoringDepartment,
  MonitoringOutbound,
  MonitoringQueue,
  MonitoringPredictiveCampaigns,
  MonitoringCallbackCalls,
  MusicsOnHold,
  MusicOnHold,
  Pauses,
  PbxQueues,
  PbxQueue,
  Recordings,
  Recording,
  RingGroups,
  RingGroup,
  SurveyQuestionType,
  SurveyQuestionTypes,
  TimeConditions,
  TimeCondition,
  TimeGroups,
  TimeGroup,
  VocalOrderHistory
}
export { Dashboard, Login, Configuration, Wizard }
