import React, {useEffect, useState} from 'react'
import socketManager from '../../api/socket'

interface waitForSocketProps {}
export const waitForWebSocket = <T extends waitForSocketProps = waitForSocketProps>(
  WrappedComponent: React.ComponentType<T>
) => {
  return (props: T) => {
    const [canRender, setCanRender] = useState<boolean>(false)

    useEffect(() => {
      if(socketManager.socketReady) {
        setCanRender(true)
      }
      else {
        socketManager.once('websocket-open', () => {
          setCanRender(true)
        })
      }
    }, [])

    return canRender && <WrappedComponent {...(props as T)} />
  }
}