import { GetAppRounded, PlayArrowRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { getToken } from '../../store/selectors'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { coreApi, mediaResources } from '../../configuration'
import { notify } from '../../store/actions'
import {logger} from "../../helpers";

interface Props {
  entity: string
  entityName?: string
  name: string
  baseUrl?: string
  file?: any
  width?: string
  extension?: '.wav' | '.mp3'
}

/**
 * Conponente comune per la gestione degli audio
 * @param props.entity {string}
 * @param props.entityName {string}
 * @param props.name {string}
 * @param props.file {any}
 * @constructor
 */
export const AudioPlayer: React.FC<Props> = (props) => {
  const { entity, entityName, baseUrl = `media/asterisk`, name, file, extension = '.wav', width= '100%' } = props

  const token = useSelector(getToken)
  const dispatch = useDispatch()

  const [stream, setStream] = useState<string>('')

  const makeUrl = (action: string) => {
    let url = `${coreApi}/${baseUrl}/${entity}/`

    const nameWithExtension = name.split('.').at(-1) !== name ? name : `${name}${extension}`
    url += encodeURIComponent(entityName ? `${entityName}/${nameWithExtension}` : nameWithExtension)
    url += `/${action}`

    return url
  }

  const getStream = async () => {
    const coreUrl = makeUrl('stream')
    //chiedo al core un token valido per accedere allo stream
    return axios
      .get(coreUrl, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      .then(({ data: { payload } }) => {
        //il token ottenuto verra mandato a media per la validazione e l'invio dello stream
        return fetch(new Request(mediaResources), {
          headers: {
            Authorization: 'Bearer ' + payload
          }
        })
          .then((response) => {
            if (response.status !== 200) {
              dispatch(notify({ message: response.statusText, severity: 'error' }))
              return
            }
            return response.blob()
          })
          .catch((e) => {
            logger.error(e)
            dispatch(notify({ message: e.response.data.message, severity: 'error' }))
          })
      })
      .catch((err) => {
        logger.error(err)
        dispatch(notify({ message: err.response.data.message, severity: 'error' }))
      })
  }
  const audioSrc = async () => {
    if (!file) {
      const stream = await getStream()

      if (stream) {
        const audioUrl = URL.createObjectURL(stream)
        setStream(audioUrl)
      }
    } else {
      const audioUrl = URL.createObjectURL(file)
      setStream(audioUrl)
    }
  }
  const download = () => {
    if (stream) {
      const link = document.createElement('a')
      link.href = stream
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      //se non è presente lo stream devo chiedere il download al server tramite apposito endpoint
      const coreUrl = makeUrl('download')
      //chiedo al core un token valido per accedere al file
      return axios
        .get(coreUrl, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        .then(({ data: { payload } }) => {
          //il token ottenuto verra mandato a media per la validazione e l'invio del file
          fetch(mediaResources, {
            method: 'GET',
              headers: {
                Authorization: 'Bearer ' + payload
              }
            })
            .then((response) => {
              response.blob().then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${name}`)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
                .catch(e => {
                  logger.error(e)
                  dispatch(notify({ message: e.response.data.message, severity: 'error' }))
                })
            })
            .catch((err) => {
              logger.error(err)
              dispatch(notify({ message: err.response.data.message, severity: 'error' }))
            })
        })
        .catch((err) => {
          console.error(err)
          dispatch(notify({ message: err.response.data.message, severity: 'error' }))
        })
    }
  }

  return (
      (!!stream) ? (
        <AudioContainerFull width={width}>
          <audio src={stream} style={{ width: '100%' }} controls controlsList="nodownload" />
          {!file ? (
            <IconButton onClick={download} style={{ placeSelf: 'center' }} disabled={!name}>
              <GetAppRounded fontSize={'small'} />
            </IconButton>
          ) : (
            <div style={{ minWidth: '1fr' }} />
          )}
        </AudioContainerFull>
      ) : (
        <AudioContainerReduced>
          <IconButton onClick={audioSrc} style={{ placeSelf: 'center' }} disabled={!name}>
            <PlayArrowRounded fontSize={'small'} />
          </IconButton>
          {!file ? (
            <IconButton onClick={download} style={{ placeSelf: 'center' }} disabled={!name}>
              <GetAppRounded fontSize={'small'} />
            </IconButton>
          ) : (
            <div style={{ minWidth: '1fr' }} />
          )}
        </AudioContainerReduced>
      )
  )
}

const AudioContainerFull = styled.div<{width: string}>`
  display: flex;
  place-items: center flex-end;
  width: ${(props) => props.width};
`

const AudioContainerReduced = styled.div`
  display: flex;
  justify-content: flex-end;
`
