import * as React from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import {
  AddCircleOutlineOutlined,
  AdjustOutlined,
  CircleOutlined,
  RemoveCircleOutlineOutlined
} from '@mui/icons-material'
import {treeItemClasses, TreeView as MuiTreeView, TreeItem as MuiTreeItem, TreeItemProps} from '@mui/x-tree-view'
import styled from 'styled-components'
import { alpha, Typography } from '@mui/material'
import { SxProps } from '@mui/system'

export const TreeView: React.FC<Props> = (props) => {
  const { items, sortElements = false, hasAddElement = false, expandedItemIds = [], sx = {} } = props

  const [expanded, setExpanded] = React.useState<string[]>([])
  const [selected, setSelected] = React.useState<string>('')

  const sortedItems = useMemo(() => {
    if (!sortElements) return items
    const itemsToSort = hasAddElement ? items.slice(0, items.length - 1) : items
    const sorted = itemsToSort.sort(sortingFunction)
    return hasAddElement ? [...sorted, items[items.length - 1]] : sorted
  }, [items, hasAddElement, sortElements])

  const handleToggle = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }, [])

  const handleSelect = useCallback((event: React.SyntheticEvent, nodeId: string) => {
    setSelected(nodeId)
  }, [])

  const subItemsRenderer = useCallback(
    (item: TreeItem) => {
      if (!item) return
      const { hasSubItemsAddElement = false, subItems, id, sx, onClick } = item
      if (subItems && subItems.length > 0) {
        let sortedElements = subItems
        if (sortElements) {
          const subItemsToSort = hasSubItemsAddElement ? subItems.slice(0, subItems.length - 1) : subItems
          const sorted = subItemsToSort.sort(sortingFunction)
          sortedElements = items ? [...sorted, subItems[subItems.length - 1]] : sorted
        }
        return (
          <StyledTreeItem
            nodeId={id}
            key={id}
            sx={sx}
            label={
              <Box display={'flex'}>
                <Typography sx={{ color: 'inherit' }}>{item.label}</Typography>
                <Typography sx={{ alignSelf: 'center', color: 'text.secondary', ml: 2 }} variant={'caption'}>
                  {hasSubItemsAddElement ? subItems.length - 1 : subItems.length}
                </Typography>
              </Box>
            }
            onClick={onClick}
            endIcon={selected === id ? <AdjustOutlined /> : <CircleOutlined />}>
            {sortedElements.map((subItem: TreeItem) => subItemsRenderer(subItem))}
          </StyledTreeItem>
        )
      }
      return (
        <StyledTreeItem
          nodeId={item.id}
          key={item.id}
          label={item.label}
          sx={item.sx}
          onClick={item.onClick}
          endIcon={selected === item.id ? <AdjustOutlined /> : <CircleOutlined />}
        />
      )
    },
    [selected, sortElements, items]
  )

  useEffect(() => {
    if (!expandedItemIds.length) return
    setExpanded(expandedItemIds)
  }, [expandedItemIds])

  return (
    <Box sx={{ height: '100%', ...sx }}>
      <MuiTreeView
        aria-label="controlled"
        defaultCollapseIcon={<RemoveCircleOutlineOutlined />}
        defaultExpandIcon={<AddCircleOutlineOutlined />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}>
        {sortedItems.map((item) => subItemsRenderer(item))}
      </MuiTreeView>
    </Box>
  )
}

const sortingFunction = (a: TreeItem, b: TreeItem) => {
  if (a.label < b.label) return -1
  else return 1
}

// region types
type Props = {
  items: TreeItem[]
  sortElements?: boolean
  expandedItemIds?: string[]
  hasAddElement?: boolean
  sx?: SxProps
}

export interface TreeItem {
  id: string
  label: string
  onClick?: () => void
  subItems: TreeItem[]
  sx?: SxProps
  hasSubItemsAddElement?: boolean
}

// endregion

// region style
const StyledTreeItem = styled((props: TreeItemProps) => <MuiTreeItem {...props} />)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3
    }
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  }
}))
// endregion
