import Menu from '@mui/icons-material/Menu'
import {AppBar, Breadcrumbs, IconButton, Link as MuiLink, Toolbar, Tooltip, Typography} from '@mui/material'
import {getBreadCrumb, getTitle, getTranslation, getUser} from '../../../store/selectors'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {NotificationButton} from './NotificationButton'
import UserBar from './UserBar'
import {HelpOutlineOutlined} from "@mui/icons-material";
import {HelpMenu} from "./HelpMenu";

/**
 * Componente TopBar contiene il titolo della pagina, il link all'user profile e il logout
 * @param minimize {boolean} indica se il menu laterale è minimizzato
 * @param setMinimize {(menu: boolean) => void} callback per la minimizzazzione del menu
 * @param disableMinimize {boolean} indica se il minimized del menu laterale è disabilitato
 */

const Topbar: React.FC<Props> = ({setMinimize, minimize, disableMinimize}) => {
  const title = useSelector(getTitle)
  const breadcrumb = useSelector(getBreadCrumb)
  const user = useSelector(getUser)
  const translation = useSelector(getTranslation)

  const [isHelpOpen, setIsHelpOpen] = useState(false)

  // Splitto il titolo tenendo conto anche degli /
  const titleChunks = title.split('/')

  return (
    <AppBar position="static" sx={{height: 'var(--header-height)'}}>
      <MenuContainer style={{minHeight: 0}}>
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          sx={{mr: 2}}
          onClick={() => setMinimize(!minimize)}
          disabled={disableMinimize}>
          <Menu/>
        </IconButton>
        <StyledBreadcrumbs aria-label="breadcrumb">
          {titleChunks.map((title, index) =>
            breadcrumb[index] ? (
              <MuiLink component={Link} underline="hover" color={'text.primary'} key={title} to={breadcrumb[index]}>
                {title}
              </MuiLink>
            ) : (
              <Typography key={title} color={'text.secondary'}>
                {title}
              </Typography>
            )
          )}
        </StyledBreadcrumbs>
        <UserInfoContainer>
          <Tooltip title={translation.input.help}>
            <IconButton sx={{display: 'flex', height: '3rem'}} onClick={() => {
              setIsHelpOpen((isOpen: boolean) => !isOpen)
            }}>
              <HelpOutlineOutlined/>
            </IconButton>
          </Tooltip>
          <HelpMenu isHelpOpen={isHelpOpen} setIsHelpOpen={setIsHelpOpen}/>
          {user?.actions?.create?.includes('pbxs-commands') && <NotificationButton/>}
          <UserBar/>
        </UserInfoContainer>
      </MenuContainer>
    </AppBar>
  )
}

export default Topbar

type Props = {
  setMinimize: (open: boolean) => void
  minimize: boolean
  disableMinimize: boolean
}
//region style

const MenuContainer = styled(Toolbar)`
  width: 100%;
  height: var(--header-height);
  background-color: ${(props) => (props.theme.palette.mode === 'light' ? '#E6E6E6' : '#1E1E1E')};
`
const UserInfoContainer = styled.div`
  margin: 0.5rem;
  display: flex;
  gap: 10px;
  align-items: center;
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  place-self: center;
  justify-self: left;
  flex-grow: 1;
`
//endregion
