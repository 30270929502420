import { TitledCard } from './TitledCard'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { CSSProperties } from '@mui/styles'


export const FractionPlot: React.FC<Props> = ({ title, numerator, denominator, sx }) => {
  return (
    <TitledCard title={title} sx={sx}>
      <Container>
        <FractionContainer>
          <NumbersContainer alignItems={'flex-start'}>
            <Typography sx={{fontSize: '4rem', fontWeight: 600}}>{numerator}</Typography>
          </NumbersContainer>
          <NumbersContainer alignItems={'flex-end'}>
            <Typography sx={{fontSize: '3rem', color: 'text.secondary', fontWeight: 600}}>/{denominator}</Typography>
          </NumbersContainer>
        </FractionContainer>
      </Container>
    </TitledCard>
  )
}

// region style
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
`
const FractionContainer = styled.div`
  height: 7rem;
  width: 10rem;
  display: flex;
  justify-content: center;
`
const NumbersContainer = styled.div<{ alignItems: 'flex-start' | 'flex-end' }>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
`
// endregion

// region types
type Props = {
  title: string
  numerator: number
  denominator: number
  sx?: CSSProperties
}
// endregion
