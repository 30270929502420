import {BorderAllRounded, BorderClearRounded, HistoryToggleOffRounded} from '@mui/icons-material'
import {Box, Button, Card, IconButton, Popover, TextField, Typography} from '@mui/material'
import {SxProps} from '@mui/system'
import {getTranslation} from '../../store/selectors'
import {Info} from 'luxon'
import React, {useMemo, useState} from 'react'
import {useController, UseControllerProps} from 'react-hook-form'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {amPmCountries} from "./helpers";
import {FieldValues} from "react-hook-form/dist/types";

type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> & {
  sx?: SxProps
}

/**
 * componente per la selezione di fasce orarie per giorni della settimana
 * */
export function TimeBandPicker<TFieldValues extends FieldValues>(props: Props<TFieldValues>) {
  const {control, name, rules, sx = {}} = props

  // struttura dati comoda per la gestione dei giorni ed orari selezionati, è fatta come giorno -> Set(orario1,orario2..)
  // nel set di numeri viene messa ogni ora selezionata, verrà parsata dopo per trovare gli intervalli
  const [weeklyRanges, setWeeklyRanges] = useState<WeeklyRanges>({...defaultRanges})
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const [startDragPoint, setStartDragPoint] = useState<{ day: number; hour: number; selected: boolean } | null>(null)

  const translation = useSelector(getTranslation)
  const open = Boolean(anchorEl)

  const {field} = useController({control, name, rules})

  const isSelected = (day: number, hour: number) => {
    const weekdayRanges = weeklyRanges[day]
    if (weekdayRanges === undefined) return false
    return weekdayRanges.has(hour)
  }

  /** gestisce il click su un orario per selezionare o deselezionare tutta la colonna */
  const onClickHour = (hour: number) => {
    const newWeeklyRanges: WeeklyRanges = {}
    const ranges = Object.values(weeklyRanges)
    const hasAtLeastOneSelected = ranges.some((hours) => hours.has(hour))
    if (hasAtLeastOneSelected) {
      // devo deselezionare tutto
      Object.entries(weeklyRanges).forEach(([day, hours]) => {
        const newHours = new Set(hours)
        newHours.delete(hour)
        newWeeklyRanges[parseInt(day)] = newHours
      })
    } else {
      // devo selezionare tutto
      for (let i = 0; i < 7; i++) {
        const oldRange = weeklyRanges[i]
        if (oldRange) {
          const newHours = new Set(oldRange)
          newHours.add(hour)
          newWeeklyRanges[i] = newHours
        } else {
          newWeeklyRanges[i] = new Set([hour])
        }
      }
    }
    handleChange(newWeeklyRanges)
    setWeeklyRanges(newWeeklyRanges)
  }

  /** gestisce il click su un giorno per selezionare o deselezionare tutta la riga */
  const onClickDay = (day: number) => {
    const newWeeklyRanges: WeeklyRanges = {...weeklyRanges}
    const hasAtLeastOneSelected = weeklyRanges[day]
    if (hasAtLeastOneSelected) {
      // devo deselezionare tutto
      delete newWeeklyRanges[day]
    } else {
      // devo selezionare tutto
      newWeeklyRanges[day] = new Set([...new Array(24)].map((_, i) => i))
    }
    handleChange(newWeeklyRanges)
    setWeeklyRanges(newWeeklyRanges)
  }

  /** gestisce il click su un a cella per selezionare o deselezionare la singola ora un certo giorno */
  const onClickCell = (day: number, hour: number) => {
    const newWeeklyRanges: WeeklyRanges = {...weeklyRanges}
    if (isSelected(day, hour)) {
      // va deselezionato
      newWeeklyRanges[day].delete(hour)
      if (newWeeklyRanges[day].size === 0) delete newWeeklyRanges[day]
    } else {
      // va selezionato
      if (newWeeklyRanges[day]) {
        newWeeklyRanges[day].add(hour)
      } else {
        newWeeklyRanges[day] = new Set([hour])
      }
    }
    handleChange(newWeeklyRanges)
    setWeeklyRanges(newWeeklyRanges)
  }

  const onDragCell = (day: number, hour: number) => {
    if (!startDragPoint) return
    // devo selezionare /deselezionare tutte le celle nell'area tra lo starting point e la posizione attuale
    // per farlo calcolo le misure di quest'area
    const top = Math.min(day, startDragPoint.day)
    const bottom = Math.max(day, startDragPoint.day)
    const right = Math.max(hour, startDragPoint.hour)
    const left = Math.min(hour, startDragPoint.hour)

    const newWeeklyRanges = {...weeklyRanges}

    if (startDragPoint.selected) {
      for (let i = top; i <= bottom; i++) {
        if (!newWeeklyRanges[i]) newWeeklyRanges[i] = new Set()
        for (let j = left; j <= right; j++) {
          newWeeklyRanges[i].add(j)
        }
      }
    } else {
      for (let i = top; i <= bottom; i++) {
        for (let j = left; j <= right; j++) {
          if (newWeeklyRanges[i]) {
            newWeeklyRanges[i].delete(j)
            if (newWeeklyRanges[i].size === 0) delete newWeeklyRanges[i]
          }
        }
      }
    }

    handleChange(newWeeklyRanges)
    setWeeklyRanges(newWeeklyRanges)
  }

  /** parsa weeklyRanges nel formato "giorno|inizio-fine", ci sarà una entry per ogni giorno e
   * per ogni intervallo contiguo di ore. Per esempio: 0: [1,2,6,7] diventa ["0|1-2", "0|6-7"]*/
  const handleChange = (wr = weeklyRanges) => {
    const result = []
    for (let [day, hours] of Object.entries(wr)) {
      const hoursArr = [...hours.values()].sort((a, b) => a - b)
      hoursArr.push(Infinity)
      let currStart = hoursArr[0]
      for (let i = 1; i < hoursArr.length; i++) {
        if (hoursArr[i] !== hoursArr[i - 1] + 1) {
          // si è trovato un intervallo da salvare
          result.push(`${day}|${currStart}-${hoursArr[i - 1]}`)
          currStart = hoursArr[i]
        }
      }
    }
    field.onChange(result)
  }

  const selectAll = () => {
    const newWeeklyRanges: WeeklyRanges = {}
    for (let i = 0; i < 7; i++) newWeeklyRanges[i] = new Set([...new Array(24)].map((_, i) => i))
    handleChange(newWeeklyRanges)
    setWeeklyRanges(newWeeklyRanges)
  }

  const deselectAll = () => {
    const newWeeklyRanges = {}
    handleChange(newWeeklyRanges)
    setWeeklyRanges(newWeeklyRanges)
  }

  const hasSomeRange = useMemo(() => {
    return Object.keys(weeklyRanges).length > 0
  }, [weeklyRanges])

  return (
    <>
      <TextField
        fullWidth
        label={translation.input.timeBands}
        variant="standard"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        InputProps={{startAdornment: <HistoryToggleOffRounded/>}}
        value={hasSomeRange ? 'Impostato' : ''}
        sx={sx}
      />
      <Popover
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <Box display="flex" justifyContent={'space-between'}>
          <Typography variant={'subtitle2'} sx={{color: 'primary.dark', p: 1, pb: 0}}>
            Seleziona fasce orarie
          </Typography>
          <Button size="small" onClick={() => setAnchorEl(null)}>
            Ok
          </Button>
        </Box>
        <TimeGrid>
          <Empty>
            <IconButton
              size="small"
              sx={{display: 'grid', placeSelf: 'center'}}
              onClick={hasSomeRange ? deselectAll : selectAll}>
              {hasSomeRange ? <BorderClearRounded/> : <BorderAllRounded/>}
            </IconButton>
          </Empty>
          <DaysContainer>
            {Info.weekdays('short').map((label, day) => (
              <Label
                key={`weekday-${day}`}
                onMouseDown={() => onClickDay(day)}
                onMouseEnter={(e) => {
                  if (e.buttons === 1) {
                    onClickDay(day)
                  }
                }}>
                {label}
              </Label>
            ))}
          </DaysContainer>
          <HoursContainer>
            {[...new Array(24)].map((_, hour) => (
              <Label
                key={`hour-${hour}`}
                onMouseDown={() => onClickHour(hour)}
                onMouseEnter={(e) => {
                  if (e.buttons === 1) {
                    onClickHour(hour)
                  }
                }}>
                {
                  amPmCountries.includes(navigator.language) ?
                    String(hour).length === 1 ? `0${hour}` : (hour - (hour > 12 ? 12 : 0))
                    :
                    String(hour).length === 1 ? `0${hour}` : hour
                }
              </Label>
            ))}
          </HoursContainer>
          <Cells>
            {[...new Array(7)].map((_, day) =>
              [...new Array(24)].map((_, hour) => (
                <Box
                  key={`${day}, ${hour}`}
                  onMouseDown={() => {
                    if (!startDragPoint) setStartDragPoint({day, hour, selected: !isSelected(day, hour)})
                    onClickCell(day, hour)
                  }}
                  onMouseUp={() => setStartDragPoint(null)}
                  onMouseEnter={(e) => {
                    if (e.buttons === 1) {
                      onDragCell(day, hour)
                    }
                  }}
                  sx={{
                    bgcolor: isSelected(day, hour) ? 'primary.main' : 'background.default',
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: isSelected(day, hour) ? 'primary.light' : 'action.hover'
                    }
                  }}
                />
              ))
            )}
          </Cells>
        </TimeGrid>
      </Popover>
    </>
  )
}

//region Types

// TODO: customizzabili?
const defaultRanges = {
  // 0: new Set([8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]),
  // 1: new Set([8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]),
  // 2: new Set([8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]),
  // 3: new Set([8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]),
  // 4: new Set([8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20])
}

type WeeklyRanges = {
  [key: number]: Set<number>
}

//endregion

//region Style

const TimeGrid = styled(Card)`
  display: grid;
  grid-template-areas: 'empty hours' 'days cells';
  grid-template-columns: 50px 1fr;
  grid-template-rows: 30px 1fr;
  width: 100%;
  height: 100%;
  padding: ${(props) => props.theme.spacing(1)};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`

const DaysContainer = styled.div`
  grid-area: days;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, 1fr);
  place-content: center;
  font-size: 0.8rem;
  text-align: right;
`

const HoursContainer = styled.div`
  grid-area: hours;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(24, 1fr);
  place-content: center;
  font-size: 0.8rem;
  grid-column-gap: 5px;
`

const Cells = styled.div`
  grid-area: cells;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-gap: 2px;
`

const Empty = styled.div`
  grid-area: empty;
  display: grid;
  place-content: center;
  width: 50px;
  height: 30px;
`

const Label = styled.div`
  cursor: pointer;
  display: grid;
  text-transform: uppercase;
  place-content: center;
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`
//endregion
