import { Tabs } from '../../../common'
import { Card } from '@mui/material'
import {setHelpPage, setTitle} from '../../../../store/actions'
import { getTranslation } from '../../../../store/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Calls from './calls/Calls'
import Chats from './chats/Chats'
import Favourites from './Favourites'
import Inbounds from './inbounds/Inbounds'
import Outbounds from './outbounds/Outbounds'
import Users from './users/Users'
import Callbacks from "./callbacks/Callbacks"
import Predictive from "./predictive/Predictive"

const Stats: React.FC = () => {
  const translation = useSelector(getTranslation)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setTitle(`${translation.menu.tools}/${translation.menu.stats}`))
  }, [dispatch, translation])
  useEffect(() => {
    dispatch(setHelpPage({title: translation.pages.STATS.help.title, tabs: [{label: translation.help.overview, content: translation.pages.STATS.help.overview}]}))
  }, [translation, dispatch])

  return (
    <Container>
      <Card sx={{ width: '100%', height: '100%' }}>
        <Tabs
          tabs={[
            { value: 0, label: translation.stats.tabs.favourites, component: <Favourites /> },
            { value: 1, label: translation.stats.tabs.calls, component: <Calls /> },
            { value: 2, label: translation.stats.tabs.inbounds, component: <Inbounds /> },
            { value: 3, label: translation.stats.tabs.outbounds, component: <Outbounds /> },
            { value: 4, label: translation.stats.tabs.predictive, component: <Predictive /> },
            { value: 5, label: translation.stats.tabs.callbacks, component: <Callbacks /> },
            { value: 6, label: translation.stats.tabs.chats, component: <Chats /> },
            { value: 7, label: translation.stats.tabs.users, component: <Users /> }
          ]}
        />
      </Card>
    </Container>
  )
}

export default Stats

//region Style

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`

//endregion
