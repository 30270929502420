import { RootState } from './types'

// Login
export const getLogin = (state: RootState) => state.login

export const getRenewTimeout = (state: RootState) => state.login.renewTimeout
export const getToken = (state: RootState) => state.login.token
export const getAuthenticated = (state: RootState) => state.login.isAuthenticated
export const getUser = (state: RootState) => state.login.user
export const getError = (state: RootState) => state.login.error
export const getLoginMethods = (state: RootState) => state.login.methods
export const getIsAuthenticated = (state: RootState) => state.login.isAuthenticated
export const getSelectedMethod = (state: RootState) => state.login.selectedMethod
export const getActions = (state: RootState) => state.login.actions
export const getOtpRequired = (state: RootState) => state.login.otpRequired

//applicationState
export const getTitle = (state: RootState) => state.applicationState.title
export const getBreadCrumb = (state: RootState) => state.applicationState.breadcrumb
export const getTheme = (state: RootState) => state.applicationState.theme
export const getPbxs = (state: RootState) => state.applicationState.pbxs
export const getSelectedPbx = (state: RootState) => state.applicationState.selectedPbx
export const getRestNotification = (state: RootState) => state.applicationState.restNotification
export const getSelectedTableRows = (state: RootState) => state.applicationState.selectedTableRows
export const getWarnings = (state: RootState) => state.applicationState.warnings
export const getLoading = (state: RootState) => state.applicationState.loading
export const getButtonLoading = (state: RootState) => state.applicationState.buttonLoading
export const getHelpPage = (state: RootState) => state.applicationState.helpPage
export const getOCClientRef = (state: RootState) => state.applicationState.ocClientRef

//Translation
export const getTranslation = (state: RootState) => state.language.translation
export const getMenuTranslation = (state: RootState) => state.language.translation.menu
export const getStatusTranslation = (state: RootState) => state.language.translation.status
export const getButtonTranslation = (state: RootState) => state.language.translation.button
export const getLanguage = (state: RootState) => state.language.selected

// Stats Favourites
export const getStatsFavourites = (state: RootState) => state.stats.favourites
