import { PaletteMode, ThemeOptions } from '@mui/material'

const mode: PaletteMode = 'light'

export const lightTheme: ThemeOptions = {
  palette: {
    mode,
    primary: {
      dark: '#005f7a',
      main: '#0A8DA9',
      light: '#57bddb',
      contrastText: '#ffffff'
    },
    secondary: {
      dark: '#ff9800',
      main: '#ffb74d',
      light: '#ffe97d'
    },
    background: {
      paper: '#fff',
      default: '#fafafa'
    },
    text: {
      primary: '#565656',
      secondary: '#62727b',
      disabled: '#eceff1'
    },
    action: {
      active: '#565656',
      hover: 'rgba(2, 136, 209, 0.2)'
    }
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: `"Open Sans", sans-serif`
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    },
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    }
  }
}
