import { IconButton, Tooltip, Typography } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import React from 'react'
import styled from 'styled-components'
import { getTranslation } from '../../store/selectors'
import { useSelector } from 'react-redux'


export const PasswordView: React.FC<{showPassword: boolean, password: string}> = ({ showPassword, password }) => {

  const translation = useSelector(getTranslation)

  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setTimeout(() =>setOpen(false), 300)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    !showPassword ?
      <PasswordContainer>
        <Tooltip
          onClose={handleTooltipClose}
          title={open ? translation.input.contentCopied : translation.input.copy}
        >
          <IconButton onClick={() => {
            handleTooltipOpen()
            navigator.clipboard.writeText(password)
          }}>
            <ContentCopy />
          </IconButton>
        </Tooltip>
        <Typography>***</Typography>
      </PasswordContainer>
      :
      <Typography>{password}</Typography>
  )
}

// region style
const PasswordContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
// endregion