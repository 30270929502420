import { logger } from '../helpers'
import { notify } from '../store/actions'
import {useCallback, useState} from 'react'
import { useDispatch } from 'react-redux'
import { AnyObject } from './types'
import { CallsOptions } from './useRest'

/**
 * Gestisce filtri e paginazione, unico comportamento condizionale vale per le pagine delle statistiche
 * @param remove
 * @param get funzione per eseguire il retrieve dei dati con i filtri attuali
 * @param getStats funzione per eseguire il retrieve dei dati con filtri + aggregazioni
 * @param filters filtri attualmente impostati
 * @param aggs aggregati attualmente impostati
 */
function usePaginationAndFilters<TFilters>({
  remove,
  get,
  getStats,
  filters
}: Props<TFilters>): PaginationAndFiltersHookReturn<TFilters> {
  const [page, setPage] = useState<number>(0)
  const [oldPage, setOldPage] = useState<number | null>(null)
  const [oldPageLimit, setOldPageLimit] = useState<number | null>(null)
  const [pageLimit, setPageLimit] = useState<number>(25)
  const [sortBy, setSortBy] = useState<string>('')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [lastUsedFilters, setLastUsedFilters] = useState<Partial<TFilters>>(filters)

  const dispatch = useDispatch()

  const search = useCallback(
    (filters: TFilters, aggs?: AnyObject) => {
      // Sto cambiando i filters
      // se viene passato getStats vanno ricalcolate anche le aggregazioni
      if (aggs) {
        if (!getStats) throw new Error('getStats is required if aggs are passed as props')
        getStats({
          filters: {
            ...filters,
            _pagenumber: 1,
            _pagelimit: pageLimit,
            _sortby: sortBy,
            _sortorder: sortOrder
          },
          aggs
        }).catch((e) => logger.error(e))
      } else {
        // altrimenti si sta ricercando nello storico senza aggregati
        get({
          filters: {
            ...filters,
            _pagenumber: 1,
            _pagelimit: pageLimit,
            _sortby: sortBy,
            _sortorder: sortOrder
          }
        }).catch((e) => logger.error(e))
      }
      setPage(0)
      setLastUsedFilters(filters)
    },
    [get, setPage, setLastUsedFilters, pageLimit, sortBy, sortOrder, getStats]
  )

  /** se si sta cambiando pagina in una pagina delle statistiche va comunque usato
   * get per non ricalcolare le aggregazioni in quanto non necessario*/
  const changePage = (newPage: number) => {
    get({
      filters: {
        ...lastUsedFilters,
        _pagenumber: newPage + 1,
        _pagelimit: pageLimit,
        _sortby: sortBy,
        _sortorder: sortOrder
      }
    }).catch((e) => logger.error(e))
    setOldPage(page)
    setPage(newPage)
  }

  const changePageLimit = (newLimit: number) => {
    get({
      filters: {
        ...lastUsedFilters,
        _pagenumber: 1,
        _pagelimit: newLimit,
        _sortby: sortBy,
        _sortorder: sortOrder
      }
    }).catch((e) => logger.error(e))
    setOldPageLimit(pageLimit)
    setPageLimit(newLimit)
    setPage(0)
  }

  const sort = useCallback((key: string, sortOrder: 'asc' | 'desc' = 'asc', aggs?: AnyObject) => {
    if( oldPage !== null && oldPage !== page) return
    if( oldPageLimit !== null && oldPageLimit !== pageLimit) return
    if(aggs) {
      if (!getStats) throw new Error('getStats is required if aggs are passed as props')
      getStats({
        filters: {
          ...lastUsedFilters,
          _pagenumber: page + 1,
          _pagelimit: pageLimit,
          _sortby: key,
          _sortorder: sortOrder
        },
        aggs
      },).catch((e) => logger.error(e))
    }
    else {
      get({
        filters: {
          ...lastUsedFilters,
          _pagenumber: page + 1,
          _pagelimit: pageLimit,
          _sortby: key,
          _sortorder: sortOrder
        }
      }).catch((e) => logger.error(e))
    }
    setSortBy(key)
    setSortOrder(sortOrder)
  }, [get, getStats, lastUsedFilters, page, pageLimit, oldPage, oldPageLimit])

  const searchAndSort = useCallback(
    (filters: TFilters, key: string, sortOrder: 'asc' | 'desc' = 'asc', aggs?: AnyObject) => {
      // Sto cambiando i filters
      // se viene passato getStats vanno ricalcolate anche le aggregazioni
      if (aggs) {
        if (!getStats) throw new Error('getStats is required if aggs are passed as props')
        getStats({
          filters: {
            ...filters,
            _pagenumber: 1,
            _pagelimit: pageLimit,
            _sortby: key,
            _sortorder: sortOrder
          },
          aggs
        }).catch((e) => logger.error(e))
      } else {
        // altrimenti si sta ricercando nello storico senza aggregati
        get({
          filters: {
            ...filters,
            _pagenumber: 1,
            _pagelimit: pageLimit,
            _sortby: key,
            _sortorder: sortOrder
          }
        }).catch((e) => logger.error(e))
      }
      setPage(0)
      setSortBy(key)
      setSortOrder(sortOrder)
      setLastUsedFilters(filters)
    },
    [get, setPage, setLastUsedFilters, setSortBy, setSortOrder, pageLimit, getStats]
  )

  const removeSelectedRows = async (selectedRowIds: string[] | number[]) => {
    if (remove) {
      await Promise.all(selectedRowIds.map((id) => remove(id)))
      get({
        filters: {
          ...lastUsedFilters,
          _pagenumber: page,
          _pagelimit: pageLimit,
          _sortby: sortBy,
          _sortorder: sortOrder
        }
      }).catch((e) => {
        logger.error(e)
        dispatch(notify(e))
      })
    }
  }

  return { removeSelectedRows, search, changePage, changePageLimit, page, pageLimit, sort, searchAndSort, sortBy, sortOrder }
}

export type PaginationAndFiltersHookReturn<TFilters> = {
  search: (f: TFilters, aggs?: AnyObject) => void
  changePage: (newPage: number) => void
  changePageLimit: (newLimit: number) => void
  page: number
  pageLimit: number
  sort: (key: string, sortOrder?: 'asc' | 'desc', aggs?: AnyObject) => void
  searchAndSort: (f: TFilters, key: string, sortOrder?: 'asc' | 'desc', aggs?: AnyObject) => void
  sortBy: string
  sortOrder: 'asc' | 'desc'
  removeSelectedRows: (selectedRowIds: string[] | number[]) => Promise<void>
}

type Props<TFilters> = {
  remove?: (id: string | number, opts?: CallsOptions) => Promise<AnyObject>
  get: (args?: { filters: Partial<TFilters> & Pagination & Sorting }) => Promise<{ payload: Array<any>; total: number }>
  getStats?: (args?: {
    filters: Partial<TFilters> & Pagination & Sorting
    aggs: AnyObject
  }) => Promise<{ payload: Array<any>; total: number }>
  filters: TFilters
}

type Pagination = {
  _pagelimit: number
  _pagenumber: number
}

type Sorting = {
  _sortby: string
  _sortorder: 'asc' | 'desc' | ''
}

export default usePaginationAndFilters
