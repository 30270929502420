import { Reducer } from 'redux'
import * as t from './types'
import { Theme } from './types'

const initialState: t.ApplicationState = {
  title: '',
  breadcrumb: [],
  path: '/login',
  theme: (sessionStorage.getItem('theme') as Theme) || 'light',
  pbxs: [],
  selectedPbx: null,
  restNotification: {
    open: false,
    message: '',
    severity: 'success'
  },
  selectedTableRows: [],
  warnings: [],
  loading: false,
  buttonLoading: false,
  helpPage: {title: '', tabs: []},
  ocClientRef: null
}

type ApplicationStateReducer = Reducer<t.ApplicationState, t.ApplicationStateActionTypes>

const applicationStateReducer: ApplicationStateReducer = (
  state = initialState,
  action: t.ApplicationStateActionTypes
): t.ApplicationState => {
  switch (action.type) {
    case t.SET_TITLE:
      return {
        ...state,
        title: action.payload.title,
        breadcrumb: [...action.payload.breadcrumb]
      }
    case t.SET_PATH:
      return { ...state, path: action.payload }
    case t.SET_THEME:
      return { ...state, theme: action.payload }
    case t.SET_PBXS:
      return { ...state, pbxs: action.payload }
    case t.SELECT_PBX:
      return { ...state, selectedPbx: action.payload }
    case t.NOTIFY:
      return { ...state, restNotification: action.payload }
    case t.RESET_NOTIFICATION:
      return {
        ...state,
        restNotification: { ...initialState.restNotification, severity: state.restNotification.severity }
      }
    case t.SET_SELECTED_TABLE_ROWS:
      return { ...state, selectedTableRows: action.payload }
    case t.SET_WARNINGS:
      return { ...state, warnings: action.payload }
    case t.SET_LOADING:
      return { ...state, loading: action.payload }
    case t.SET_BUTTON_LOADING:
      return { ...state, buttonLoading: action.payload }
    case t.SET_HELP_PAGE:
      return { ...state, helpPage: action.payload }
    case t.SET_OCCLIENT_REF:
      return { ...state, ocClientRef: action.payload }
    default:
      return state
  }
}

export default applicationStateReducer
