import { AxiosRequestConfig, ResponseType } from 'axios'
import { AnyObject } from '../types'

export const makeHeader = (responseType?: ResponseType, params?: AnyObject, noCache?: boolean) => {
  const token = sessionStorage.getItem('token')
  const header: AxiosRequestConfig = {
    headers: {
      Authorization: 'Bearer ' + token },
  }
  if(noCache && header.headers){
    header.headers['Cache-Control'] = 'no-cache'
  }
  if (responseType) {
    header['responseType'] = responseType
  }
  if (params) {
    header.params = params
  }

  return header
}
