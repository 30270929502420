import NotificationsIcon from '@mui/icons-material/Notifications'
import {Badge, Box, Divider, IconButton, ListItemText, Menu, Tooltip, Typography} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {getTranslation, getWarnings} from '../../../store/selectors'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {WarningItem} from '../../../store/applicationState/types'
import {DateTime} from 'luxon'

export const NotificationButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpened = Boolean(anchorEl)

  const warnings = useSelector(getWarnings)
  const navigate = useNavigate()
  const translation = useSelector(getTranslation)

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const showBadge = useMemo(() => {
    return !!warnings?.length
  }, [warnings])

  return (
    <>
      <Tooltip title={translation.input.notifications}>
        <IconButton size='large' edge='start' aria-label='menu' onClick={handleClick}>
          {
            showBadge ?
              <Badge badgeContent={warnings.length} max={99} color='primary'>
                <NotificationsIcon sx={{color: 'error.dark'}}/>
              </Badge>
              :
              <NotificationsIcon/>
          }
        </IconButton>
      </Tooltip>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={menuOpened}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0.5,
            p: 1
          }
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <Box sx={{display: 'grid', gridTemplateRows: '1fr auto'}}>
          {
            warnings.sort(compareFunction).slice(0, 2).map((notification, index) => (
              <div key={index}>
                <ListItemText primary={<Typography
                  sx={{color: notification.severity, fontWeight: 600}}>{notification.message}</Typography>}
                              secondary={<Typography
                                sx={{
                                  fontSize: '0.7rem',
                                  color: 'text.secondary'
                                }}>{notification.timestamp}</Typography>}/>
                {(index !== warnings.slice(0, 2).length - 1) && <Divider/>}
              </div>
            ))
          }
          <Typography sx={{textAlign: 'end', width: '100%', fontSize: '0.9rem', cursor: 'pointer'}} onClick={() => {
            navigate('/notifications')
            handleClose()
          }}>{translation.input.showNotifications}</Typography>
        </Box>
      </Menu>
    </>

  )
}

const compareFunction = (a: WarningItem, b: WarningItem) => DateTime.fromFormat(a.timestamp, 'dd-MM-yyyy HH:mm:ss').toMillis() - DateTime.fromFormat(b.timestamp, 'dd-MM-yyyy HH:mm:ss').toMillis()