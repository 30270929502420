import { CloseRounded } from '@mui/icons-material'
import { Backdrop, Fade, Modal as MuiModal, Paper } from '@mui/material'
import React, {ReactNode} from 'react'

type ModalProps = {
  closeModal: () => void
  isVisible: boolean
  width?: string
  height?: string
  container?: HTMLElement
  style?: React.CSSProperties
  children?: ReactNode
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { children, closeModal, isVisible, width, height, container, style = {} } = props

  return (
    <MuiModal
      onClose={closeModal}
      open={isVisible}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{ backdrop: { timeout: 500 } as any }}
      disablePortal
      style={{ display: 'grid', placeItems: 'center' }}
      container={container}>
      <Fade in={isVisible} mountOnEnter unmountOnExit>
        <Paper elevation={10} style={{ ...defaultStyle(width, height), ...style }}>
          <CloseRounded
            onClick={closeModal}
            style={{ width: 24, height: 24, cursor: 'pointer', position: 'absolute', top: 5, right: 5 }}
          />
          {children}
        </Paper>
      </Fade>
    </MuiModal>
  )
}

const defaultStyle = (width?: string, height?: string): React.CSSProperties => ({
  boxSizing: 'border-box',
  position: 'relative',
  width: width || 'fit-content',
  height: height || 'fit-content',
  placeSelf: 'center',
  overflowY: 'auto',
  outline: 'none',
  padding: 10,
  minWidth: 200,
  minHeight: 200
})
