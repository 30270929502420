import {AddIcCallRounded, PhoneRounded} from '@mui/icons-material'
import {IconButton, Menu, MenuItem, Tooltip} from '@mui/material'
import React, {useMemo, useState} from 'react'
import {Statuses, User, UserVoip, Voip} from '../../api/types'
import {getOCClientRef, getTranslation, getUser} from "../../store/selectors";
import {useSelector} from "react-redux";
import {verifyOCClientOpenedAndExecuteAction} from "../../api/helpers";

type Props = {
  user: User
  transferType?: 'add' | 'transfer'
}

type InnerProps = {
  user: User
  userVoips: UserVoip[]
  isDisabled: boolean
  callVoip: Function
  transferType: 'add' | 'transfer'
}
export const UserVoipsMenu: React.FC<Props> = ({user, transferType = 'add'}) => {
  const ocClientRef = useSelector(getOCClientRef)
  const translation = useSelector(getTranslation)
  const currentUser = useSelector(getUser)

  const isDisabled = useMemo(() => {
    return user.state?.id !== 0 || currentUser?.id === user.id
  }, [user, currentUser?.id])

  const callVoip = (user: User, voip: Voip) => {
    verifyOCClientOpenedAndExecuteAction(ocClientRef, translation, () => ocClientRef?.occlient?.phone.call({number: String(voip.extension), user}))
  }

  const notDeletedVoips = (user.userVoips || []).filter(({deleted}) => !deleted)

  return notDeletedVoips.length > 1 ? (
    <MultiVoip
      user={user}
      userVoips={notDeletedVoips}
      isDisabled={isDisabled}
      callVoip={callVoip}
      transferType={transferType}
    />
  ) : (
    <SingleVoip
      user={user}
      userVoips={notDeletedVoips}
      isDisabled={isDisabled}
      callVoip={callVoip}
      transferType={transferType}
    />
  )
}

const MultiVoip: React.FC<InnerProps> = ({user, userVoips, isDisabled, callVoip}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.target as any)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const translation = useSelector(getTranslation)
  return (
    <div>
      <Tooltip title={translation.input.makeCall}>
        <span>
          <IconButton
            id="dial-button"
            size="small"
            color="primary"
            disabled={isDisabled}
            onClick={(e) => !isDisabled && handleClick(e)}>
          <AddIcCallRounded/>
        </IconButton>
        </span>
      </Tooltip>
      <Menu
        id="dial-button"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        {userVoips.map(({voip, type}: any) => (
          <MenuItem
            key={voip.id}
            disabled={voip.state?.id !== Statuses.idle}
            onClick={() => {
              callVoip(user, voip)
              handleClose()
            }}>
            {voip.extension} - {type.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const SingleVoip: React.FC<InnerProps> = ({user, userVoips, isDisabled, callVoip}) => {
  const translation = useSelector(getTranslation)
  return (
    <Tooltip title={translation.input.makeCall}>
      <span>
        <IconButton
          size="small"
          color="primary"
          disabled={isDisabled}
          onClick={() => !isDisabled && user.userVoips && callVoip(user, userVoips[0].voip)}>
        <PhoneRounded/>
      </IconButton>
      </span>
    </Tooltip>
  )
}
