import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import { IconButton } from '@mui/material'
import { setSelectedTableRows } from '../../store/actions'
import { getSelectedTableRows, getTranslation } from '../../store/selectors'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmDialog } from './ConfirmDialog'

export const DeleteRowsButton: React.FC<Props> = ({ remove, hasPermission , alert = true }) => {
  const selectedRows = useSelector(getSelectedTableRows)
  const translation = useSelector(getTranslation)
  const dispatch = useDispatch()
  const [alertOpened, setAlertOpened] = useState<boolean>(false)

  const removeSelectedRows = useCallback(() => {
    remove && remove(selectedRows).then(() => {
      dispatch(setSelectedTableRows([]))
    })
  }, [remove, selectedRows, dispatch])

  const handleConfirmation = useCallback((result: boolean) => {
    if (result && remove) {
      removeSelectedRows()
    }
    setAlertOpened(false)
  },[remove, removeSelectedRows, setAlertOpened])

  return (
    <>
      {(selectedRows.length > 0 && hasPermission) && (
        <>
          <IconButton
            size={'small'}
            onClick={() => {
              if (alert) setAlertOpened(true)
              else removeSelectedRows()
            }}>
            <DeleteOutlineRoundedIcon sx={{ color: 'error.main' }} />
          </IconButton>
          {alert && (
            <ConfirmDialog
              show={alertOpened}
              hide={handleConfirmation}
              title={translation.input.deleteConfirmTitle}
              message={translation.input.deleteConfirm}
            />
          )}
        </>
      )}
    </>
  )
}

// region types
type Props = {
  remove?: (a: any[]) => any //Promise<void>
    hasPermission: boolean
  alert?: boolean
}
// endregion
