import {Button, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {getMenuTranslation, getOCClientRef, getTranslation} from '../../store/selectors'
import {setHelpPage} from '../../store/actions'
import {getConfigAndOpenClient} from "../../api/helpers";

const ClientOnly: React.FC = () => {
  const ocClientRef = useSelector(getOCClientRef)
  const translation = useSelector(getTranslation)
  const currentTranslation = useSelector(getMenuTranslation)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setHelpPage({ title: translation.pages.TOOL_CLIENTONLY.help.title, tabs: [{label: translation.help.overview, content: translation.pages.TOOL_CLIENTONLY.help.overview}] }))
  }, [translation, currentTranslation, dispatch])

  return (
      <Container>
          <Typography variant={'h4'}>{translation.pages.CLIENT_ONLY.canOnlyAccessClient}</Typography>
          <Typography variant={'h5'}>
              {translation.pages.CLIENT_ONLY.allowPopupPermissionFirstPart}
              <br />
              {translation.pages.CLIENT_ONLY.allowPopupPermissionFirstSecondPart}
          </Typography>
          <br />
          <Button variant="contained" onClick={async () => {
              await getConfigAndOpenClient(ocClientRef)
             }} sx={{ fontSize: '1.5rem' }}>
              {translation.button.open}
          </Button>
      </Container>

  )
}

export default ClientOnly

//region Style

const Container = styled.div`
  font-size: 2rem;
  opacity: 0.8;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  color: ${(props) => props.theme.palette.text.primary};
`

//endregion
