export function calcColumnWidth(index: number, columns: any, tableWidth: any) {
  const column = columns[index]

  let width = getDeterministicColumnWidth(column, tableWidth)

  if (width) {
    return width
  }

  const totalAllocatedWidth = columns.reduce(
    (result: any, c: any) => result + (getDeterministicColumnWidth(c, tableWidth) || 0),
    0
  )

  // TODO: CONTROLLARE
  // Evenly distribute remaining width amoungst columns (accounting for minWidths)
  const variableWidthColumns = columns.filter((c: any) => typeof c.width !== 'number' && typeof c.width !== 'string')
  const initialDistributedWidthPerColumn = (tableWidth - totalAllocatedWidth) / variableWidthColumns.length
  const activeMinWidthColumns = variableWidthColumns.filter((c: any) =>
    c.minWidth > initialDistributedWidthPerColumn ? c.minWidth : 0
  )
  const allocatedMinWidth = activeMinWidthColumns.reduce((result: any, c: any) => result + c.minWidth, 0)
  const remainingWidth = tableWidth - totalAllocatedWidth - allocatedMinWidth

  return Math.max(column.minWidth || 0, remainingWidth / (variableWidthColumns.length - activeMinWidthColumns.length))
}

export function getDeterministicColumnWidth(column: any, tableWidth: any) {
  if (typeof column.width === 'number') {
    // Fixed width
    return column.width
  } else if (typeof column.width === 'string') {
    // Percentage width
    const percentageBasedWidth = percentToFixedWidth(column.width, tableWidth)
    return Math.max(percentageBasedWidth, column.minWidth || 0)
  } else {
    // Variable width
    return null
  }
}

export function percentToFixedWidth(percentAsString: any, tableWidth: any) {
  return (parseFloat(percentAsString) / 100) * tableWidth
}
