import { HelpOutlineRounded } from '@mui/icons-material'
import { Card, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material'
import React, {ReactNode} from 'react'
import styled from 'styled-components'

type Props = {
  title?: string
  content?: string
  sx?: React.CSSProperties
  children?: ReactNode
}

export const Hint: React.FC<Props> = (props) => {
  const { title, content, children, sx } = props

  if (children) {
    return (
      <HtmlTooltip title={children}>
        <HelpOutlineRounded
          color="action"
          sx={{ gridArea: 'hint', width: 16, height: 16, placeSelf: 'center', mx: 1, ...sx }}
        />
      </HtmlTooltip>
    )
  } else {
    return (
      <HtmlTooltip
        title={
          <Container $withTitle={!!title}>
            {title && <Typography variant="subtitle2" sx={{fontWeight: '700'}}>{title}</Typography>}
            <Typography variant="body2">{content || ''}</Typography>
          </Container>
        }>
        <HelpOutlineRounded
          color="action"
          sx={{ gridArea: 'hint', width: 16, height: 16, placeSelf: 'center', mx: 1, ...sx }}
        />
      </HtmlTooltip>
    )
  }
}

//region Style

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} >{props.children}</Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    border: 'none'
  }
}))

const Container = styled(Card)<{ $withTitle: boolean }>`
  display: grid;
  grid-template-rows: ${(props) => props.$withTitle && 'auto'} 1fr;
  padding: 10px;
  background-color: ${(props) => props.theme.palette.background.default};
`

//endregion
