export const SET_STATS_FAVOURITES = 'SET_STATS_FAVOURITES'

export type ChartType = 'line' | 'pie' | 'donut' | 'bar' | 'table'
export type Entities = 'calls' | 'inbounds' | 'outbounds' | 'users' | 'chats' | 'callbacks' | 'predictive'
export type StatsFavourite = {
  type: ChartType
  entity: Entities
  title: string
  description: string
  path: string
}
export interface StatsState {
  favourites: StatsFavourite[]
}

export interface SetStatsFavourites {
  type: typeof SET_STATS_FAVOURITES
  payload: StatsFavourite[]
}

export type StatsActionTypes = SetStatsFavourites
