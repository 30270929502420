import { DateTime } from 'luxon'

export type StatusesLabels =
  | 'ON_HOLD'
  | 'IN_CALL_IN'
  | 'NOT_FOUND'
  | 'IDLE'
  | 'BUSY_OUT'
  | 'IN_CALL_OUT'
  | 'IN_CALL'
  | 'DISCONNECTED'
  | 'BUSY_IN'

export enum Statuses {
  notFound = -1,
  idle = 0,
  inCall = 1,
  busyOut = 2,
  disconnected = 4,
  busyIn = 8,
  onHold = 16,
  inCallIn = 51,
  inCallOut = 52
}

export interface ActionLog {
  elasticId: string
  action: string
  entity?: string[] | string
  user: User | any
  before?: any
  after?: any
  timestamp?: number
  ipaddress: string
  result?: string
}

export type LanguageCode = 'it_IT' | 'en_EN'

export type TranslatedLabel = {
  [key in LanguageCode]: string
}

export interface Language {
  code: LanguageCode
  name: string
}

export interface User {
  id: number
  username: string
  name: string
  surname: string
  email: string
  excludedFromLicenses: boolean
  operatorCode: string
  customers?: Customer[]
  deleted: boolean
  externalNumber: string
  canCall: boolean
  provider: Provider
  office?: Office
  company?: Company
  divisions?: Division[]
  userVoips?: UserVoip[]
  state?: Pause | Status
  userRoles?: UserRole[]
  authenticationMethods: AuthenticationMethod[]
  language?: Language
  outboundRule?: OutboundRule
  theme: 'light' | 'dark'
  hidden: boolean
  recording: RecordingMode
  tenants?: Tenant[]
  directInboundNumber?: string
  fallbackDestination?: string
  keepPauseOnDisconnection?: boolean
}

export interface AuthenticationMethod {
  id?: number
  name: string
  loginUrl: string
  authorizeUrl?: string
  tokenUrl?: string
  meUrl?: string
  syncUrl?: string
  clientId?: string
  clientSecret?: string
  tenant?: string
  type: string
  syncInterval?: number
  apiKey?: string
}

export interface Provider {
  id?: string
  pbx: Pbx
  name: string
  channels?: number
  busyChannels?: number
  prefix: string
  caps: number
  isDefault?: boolean
  trunks?: Trunk[]
}

export interface Office {
  id?: number
  name: string
  companies: Company[]
  deleted: boolean
}

export interface Company {
  id?: number
  name: string
  offices: Office[]
  deleted: boolean
}

export interface Division {
  id?: number
  name: string
  company: Company | number
  office: Office | number
  users?: User[]
  deleted: boolean
}

export interface UserVoip {
  type: VoipType
  voip: Voip
  addressVisible?: boolean
  deleted?: boolean
}

export type UserServices = {
  outbounds: Array<{ id: string; name: string; voip: Voip }>
  queues: Array<{ id: string; name: string; voip: Voip; penalty: number }>
  departments: Array<{ id: number; name: string; penalty: number }>
}

export interface VoipType {
  id?: number
  name: string
  softVoip?: boolean
  defaultPenalty?: number
}

export interface Voip {
  id: string
  extension: number
  pbx: Pbx
  password?: string
  name: string
  outboundcid?: string
  voicemail?: string
  grptime?: number
  grplist?: string
  followme?: boolean
  callerid?: string
  ipaddress?: string
  context?: string
  status?: Status
  client?: string
  pause?: Pause
  state?: Status | Pause
  userId?: number
  user?: User
  deleted?: boolean
  differenceFromPbx?: string
  blocked?: boolean
  lastBlocked?: string
}

export interface Pbx {
  id: number
  name: string
  shortname: string
  host: string
  port: string
  channels?: number
  needReload?: boolean
  reloadTimestamp?: string
}

export interface Status {
  id: number
  isStatus: boolean
  lastchange: string
  name: StatusesLabels
  label?: string
  translations: TranslatedLabel
}

export interface PauseGroup {
  id: number
  name: string
  translations: TranslatedLabel
}

export interface Pause {
  busyOther: boolean
  callsEnabled: boolean
  calluid: null
  color: string
  cssClass: string
  hidden: boolean
  id: number
  isStatus: boolean
  label: string
  lastchange: string | null
  name: string
  pauseGroup: PauseGroup | null
  translations: TranslatedLabel
}

export interface UserRole {
  role: Role
  services?: Service[]
  inbounds?: Inbound[]
  outbounds?: Outbound[]
}

export interface Service {
  id?: number
  customer: Customer
  tenant?: Tenant
  name: string
  deleted?: boolean
  addressBooks?: AddressBook[] | number[]
  callsInQueue?: number
  callsInProgress?: number
}

export interface Inbound {
  id?: number
  service: Service
  name: string
  deleted?: boolean
  callsInQueue?: number
  callsInProgress?: number
  inboundRoutes?: InboundRoute[]
  queues?: Queue[]
  crm: any
}

/*inbound routes per i PBX*/
export interface InboundRoute {
  id?: string
  alertinfo: string
  callrecording: string
  delay_answer: number
  description: string
  destination: string
  display: string
  fanswer: string
  grppre: string
  inboundId: number
  indication_zone: string
  mohclass: string
  pmmaxretries: string
  pmminlength: string
  pricid: string
  privacyman: number
  reversal: string
  ringing: string
  rvolume: string
  extension: string
  cidnum: string
  queueExtension?: string
  pbx: {
    id: string
  }
}

export interface Trunk {
  trunkid?: number
  tech?: 'sip' |'pjsip'
  channelid: string
  name: string
  usercontext: string
  requiresServicePrefix?: boolean
  requiresProviderPrefix?: boolean
  pbx: Pbx
  priorityNumber?: number
  differenceFromPbx?: string
  waitingForApply?: boolean
  disabled: boolean
}

export interface Tenant {
  id?: number
  name: string,
  users: User[]
}

export interface Outbound {
  id?: string
  prefix: number
  pbx: Pbx
  service: Service
  crm: {
    id: string
  }
  name: string
  strategy: 'random' | 'round-robin'
  recordable?: boolean
  hidden?: boolean
  autoPause?: Pause
  provider: Provider
  recording?: RecordingMode
  deleted?: boolean
  externalNumbers?: string[]
  members?: OutboundMember[]
  callsInProgressOut?: number
  voips?: Voip[]
  freeOperators?: number
  busyOperators?: number
  inConversationOperators?: number
  pauseOperators?: number
  outboundRule?: OutboundRule
}

export interface OutboundMember {
  voip: Voip
  deleted?: boolean
}

export interface OutboundRule {
  id?: number,
  name: string,
  description?: string;
  rules?: OutboundRulesRegex[],
}

export interface OutboundRulesRegex {
  id?: number,
  regex: string,
  outboundRuleId?: string,
  action: 'PASS' | 'DENY',
  priorityNumber?: number,
}

export interface CallBackCall {
  callId: string
  callBackNumber: string
  startDate: number
  queueId: string
  endDate: number
  maxCallbackAttempts?: number
  serviceNumber: string
  callAttempts: number
  inProgress: boolean
}

export interface PredictiveCampaign {
  id?: string
  prog?: number
  name: string
  queue?: Queue | string
  outbound: Outbound | string
  agiScript?: string
  isPaused: boolean
  start: string
  end: string
  attempts: number
  retryInterval: number
  concurrentCalls: number
  defaultPriority: number
  callsInProgress: number
  totalCalls: number
  successfulCalls: number
  failedCalls: number
  openingTimes: OpeningTimesInfo
}

export interface Role {
  id: number
  name: string
  label: string
  hasVoip?: boolean
  hasService?: boolean
  isUnique?: boolean
  licenseType: string
  isAdmin?: boolean
  isSuperAdmin?: boolean
  isTeamLeader?: boolean
  numbersVisibility?: boolean
  actions?: Action[]
  translations: TranslatedLabel
}

export type ActionName = 'view' | 'get' | 'create' | 'update' | 'delete' | 'retrieve' | 'spy'

export interface Action {
  id?: number
  name: ActionName
  entity: string
  description: TranslatedLabel
}

export interface Customer {
  id: number
  name: string
  deleted?: boolean
  services?: Service[]
  callsInQueue?: number
  callsInProgress?: number
  callsInPreQueue?: number
}

export interface AddressBook {
  id?: string
  name: string
  contacts?: Contact[] | number[]
  services?: Service[] | number[]
}

export interface Contact {
  id?: string
  firstName: string
  lastName: string
  addresses?: Address[] | number[]
  addressBooks?: AddressBook[] | number[]
}

export interface Address {
  id?: string
  value: string
  addressType: AddressType | number
}

export interface AddressType {
  id?: string
  name: string
  actions: string
}

export type EngagementStrategy = 'ringall' | 'leastrecent' | 'fewestcalls' | 'rrmemory' | 'wrandom'
export type SkipJoinannounce = '' | 'always' | 'ready'
export type Ringing = '0' | '1' | '2'
export type Autofill = 'yes' | 'no'
export type RecordingMode = 'force' | 'yes' | 'dontcare' | 'no' | 'never'
export type TimeoutPriority = 'conf' | 'app'

export interface Queue {
  id?: string
  agentannounce_id?: number
  alertinfo?: string
  callback_id?: string
  callconfirm?: number
  callconfirm_id?: number
  cwignore?: number
  descr: string
  dest?: string
  extension: string
  grppre?: string
  isService: boolean
  ivr_id?: string
  joinannounce_id?: string
  maxwait?: string
  members: QueueMember[]
  monitor_heard?: number
  monitor_spoken?: number
  monitor_type?: string
  password?: string
  qnoanswer?: number
  qregex?: string
  announce_frequency: string
  announce_holdtime: string
  announce_position: string
  answered_elswere: string
  autofill: Autofill
  autopause: string
  autopausebusy: string
  autopausedelay: string
  autopauseunavail: string
  availableOperators?: number
  cron_dom: string
  cron_dow: string
  cron_hour: string
  cron_minute: string
  cron_month: string
  cron_random: string
  cron_schedule: string
  eventmemberstatus: string
  eventwhencalled: string
  joinempty: string
  leavewhenempty: string
  maxlen: string
  memberdelay: string
  min_announce_frequency: string
  monitor_join: string
  music: string
  penaltymemberslimit: string
  periodic_announce_frequency: string
  queue_callswaiting: string
  queue_thankyou: string
  queue_thereare: string
  queue_youarenext: string
  recording: RecordingMode
  reportholdtime: string
  retry: string
  ringinuse: string
  rvol_mode: string
  rvolume: string
  servicelevel: string
  warningThreshold: number
  skip_joinannounce: SkipJoinannounce
  strategy: EngagementStrategy
  timeout: number
  timeoutpriority: TimeoutPriority
  timeoutrestart: string
  weight: number
  wrapuptime: string
  queuewait?: number
  ringing?: Ringing
  togglehint?: number
  use_queue_context?: number
  pbx?: Pbx
  deleted: boolean
  inbounds?: Inbound[]
  name?: string
  voips?: Voip[]
  freeOperators?: number
  busyOperators?: number
  inConversationOperators?: number
  pauseOperators?: number
  callsInProgressIn: number
  warningTimestamp?: string
  postCallPause?: number
  callbackThreshold?: number
  maxCallbackAttempts?: number
  canHaveStats: boolean
  callbackOutboundId?: string
  callsInQueue?: number
}

export interface QueueMember {
  voip: Voip
  pause?: Pause | number
  penalty: number
  deleted?: boolean
}

export interface Extension {
  extension: string
  name: string
  callerid?: string
  followme?: boolean
  grplist?: string
  pre_ring?: number
  grptime?: number
  outboundcid?: any
  voicemail?: any
  userId?: number
  password?: string
  differenceFromPbx?: string
  driver: 'SIP' | 'PJSIP'
  deviceStateBusyAt: number
  maxContacts: number
  blocked?: boolean
  voipType: string
  lastBlocked?: string
}

export interface MusicOnHold {
  category: string
  files: string[]
  id: number
  random: 0 | 1
  type: string
}

export interface RingGroup {
  alertinfo: string
  annmsg_id: any
  cfignore: string
  cpickup: string
  cwignore: string
  description: string
  elsewhere: string
  grplist: string
  grpnum: string
  grppre: string
  grptime: string
  needsconf: string
  postdest: string
  progress: string
  recording: string
  remotealert_id: number
  ringing: string
  rvolume: string
  strategy: string
}

export interface Announcement {
  announcement_id: number
  description: string
  recording_id: string
  repeat_msg: string
  return_ivr: boolean
  post_dest: string
}

export interface Recording {
  id: number | string
  displayname: string
  description: string
  files: string[]
}

export interface Ivr {
  id: string
  name: string
  description: string
  announcement: string
  strict_dial_timeout: number
  timeout_time: number
  invalid_loops: number
  invalid_retry_recording: string
  invalid_append_announce: number | boolean
  invalid_ivr_ret: number
  invalid_recording: string
  invalid_destination: string
  timeout_loops: number
  timeout_retry_recording: string
  timeout_append_announce: number | boolean
  timeout_ivr_ret: number
  timeout_recording: string
  timeout_destination: string
  ivrEntries: {
    selection: string
    dest: string
    ivr_ret: boolean
  }[]
}

export interface TimeCondition {
  displayname: string
  timeconditions_id: string
  time: string
  truegoto: string
  falsegoto: string
}

export interface TimeGroup {
  id?: number
  description: string
  entries?: TimeGroupEntry[]
}

export interface TimeGroupEntry {
  id?: number
  timeStart: string
  timeEnd: string
  weekDayStart: string
  weekDayEnd: string
  monthDayStart: string
  monthDayEnd: string
  monthStart: string
  monthEnd: string
}

export interface MonitoringPanel {
  id?: number
  name: string
  rows?: number
  columns?: number
  widgets?: MonitoringPanelWidget[]
}

export interface MonitoringWidget {
  id: WidgetTypes
  name: string
  minWidth: number
  minHeight: number
}

export interface MonitoringPanelWidget {
  id?: number
  positionX: number
  positionY: number
  width: number
  height: number
  payload?: any
  widget?: Partial<MonitoringWidget> | number
}

export enum WidgetTypes {
  RealtimeDataPaginated = 1,
  RealtimeData,
  HistoryDataPaginated,
  Label,
  Clock,
  Image,
  OnlineOperators,
  OperatorsStatuses,
  CallsStatus,
  ChatsStatus,
  CallsLastIntervalPie,
  CallsLastIntervalPlot,
  HandledCallsPercentages,
  OccupancyPercentage,
}

export type MonitoringChat = {
  id: string,
  customer?: number
  services: number[]
  departments: number[]
  operator: number | null
  guest: any
  startDate: any
}

export interface Chat {
  id: string
  guest: Guest
  startDate: number
  endDate: number
  departments: Department[]
  operators?: User[] | number[]
  operator?: User
  users?: User[] | number[]
  watchers?: User[] | number[]
  closed?: boolean
  pendingOperator?: number
  transferer?: User | number
  transferee?: User | number
  events?: ChatEvent[]
  guestDisconnectedAt?: string | number
  duration?: number
  timeSpentOnQueue?: number
  operatorMessages: number
  guestMessages: number
  aiMessages: number
  services?: Service[]
  customers?: Customer[]
  '@timestamp'?: string
  version?: number
  closedByAI?: boolean
  handledByAI?: boolean
}

export interface Guest {
  id?: string
  info?: any
  chat?: string
  name?: string
  surname?: string
  email?: string
  lastActivity?: number
}

export interface ChatEvent {
  date?: DateTime | string | number
  chat: string
  type: string
  subtype?: string
  user?: User | Guest | any
  text?: string
  title?: string
  link?: string
  filename?: string
  file?: string
  tempID?: string
  eventName?: string
  department?: Department
  service?: number
  customer?: number
  guest?: any
  startDate?: string
}

export interface CallEvent {
  date: number
  type: string
  callType?: string
  called?: string
  queue?: Queue | string
  caller?: string
  callerUser?: User
  calledUser?: User
  dialstatus: string
  inbound?: number
  outbound?: string
  service?: number
  customer?: number
  call: string
  filename?: string
}

export interface MonitoringCall {
  calluid?: string | null
  type?: string
  customer?: number
  services: number[]
  inbounds: number[]
  outbound?: string | null
  queues: string[]
  users: string []
}

export interface Call {
  calluid: string
  uuid?: string
  startTime: number
  endTime: number
  from: string
  to: string
  type: string
  source?: string
  filename: string
  voicemail: boolean
  outcome: string
  status?: 'in-queue' | 'in-progress'
  queue?: Queue
  date?: string
  services: Service[]
  inbounds: Inbound[]
  outbound: Outbound
  customer: Customer
  queues: Queue[]
  users: User[]
  pbx: Pbx
  events: CallEvent[]
  answerTime: number
  queueTime: number
  talkTime: number
  preQueueTime: number
  holdTime: number
  pauseTime: number
  '@timestamp': string
}

export type TimeBands = Array<`${number}|${number}-${number}`>

export interface OpeningTimesInfo {
  schedule: WeekDaysSchedules
  extra: YearlySchedule
}

export interface WeekDaysSchedules {
  _0: TimeSlot[]
  _1: TimeSlot[]
  _2: TimeSlot[]
  _3: TimeSlot[]
  _4: TimeSlot[]
  _5: TimeSlot[]
  _6: TimeSlot[]
}

export interface TimeSlot {
  from: number
  to: number
  open: boolean
}

export interface YearlySchedule {
  1?: MonthlySchedule
  2?: MonthlySchedule
  3?: MonthlySchedule
  4?: MonthlySchedule
  5?: MonthlySchedule
  6?: MonthlySchedule
  7?: MonthlySchedule
  8?: MonthlySchedule
  9?: MonthlySchedule
  10?: MonthlySchedule
  11?: MonthlySchedule
  12?: MonthlySchedule
}

export interface MonthlySchedule {
  1?: TimeSlot[]
  2?: TimeSlot[]
  3?: TimeSlot[]
  4?: TimeSlot[]
  5?: TimeSlot[]
  6?: TimeSlot[]
  7?: TimeSlot[]
  8?: TimeSlot[]
  9?: TimeSlot[]
  10?: TimeSlot[]
  11?: TimeSlot[]
  12?: TimeSlot[]
  13?: TimeSlot[]
  14?: TimeSlot[]
  15?: TimeSlot[]
  16?: TimeSlot[]
  17?: TimeSlot[]
  18?: TimeSlot[]
  19?: TimeSlot[]
  20?: TimeSlot[]
  21?: TimeSlot[]
  22?: TimeSlot[]
  23?: TimeSlot[]
  24?: TimeSlot[]
  25?: TimeSlot[]
  26?: TimeSlot[]
  27?: TimeSlot[]
  28?: TimeSlot[]
  29?: TimeSlot[]
  30?: TimeSlot[]
  31?: TimeSlot[]
}

export interface DepartmentMember {
  user: User
  penalty: number
  connectedClients?: string[]
}

export interface Survey {
  id?: number
  name: string
  questions?: SurveyQuestion[]
}

export interface SurveyQuestion {
  id?: number
  question: string
  answers?: string
  type: SurveyQuestionType
  order?: number
}

export interface SurveyQuestionType {
  id?: number
  name: string
  label: {
  [key: string]: string
}
}

export interface Crm {
  id?: number
  name: string
  baseurl: string
  callsChatsEvents?: CrmCallsChatsEvent[]
}

export interface CrmCallsChatsEvent {
  callsChatsEvent: Omit<CallsChatsEvent, 'description'>
  type?: 'inbound' | 'outbound'
  path: string
}

export interface CallsChatsEvent {
  name: string
  class: 'call' | 'chat'
  description: TranslatedLabel
}

export interface PrivacyMessage {
  id?: number
  shortDescription: string
  fullText: string
}

type MessageType = 'auto' | 'manual'

export interface CustomizedMessage {
  id?: number
  shortDescription: string
  fullText: string
  timeout: number
  type: MessageType
}

export interface Department {
  id?: number
  name: string
  isActive?: boolean
  service?: Service
  weight?: number
  deleted?: boolean
  openingTimes?: OpeningTimesInfo
  backupPolicy?: string
  backupEmail?: string
  backupDepartmentId?: number
  members?: DepartmentMember[]
  survey?: Survey
  crm?: Crm
  privacyMessage?: PrivacyMessage
  welcomeMessage?: CustomizedMessage
  chatsInProgress?: number
  chatsInQueue?: number
  freeOperators?: number
  busyOperators?: number
  inConversationOperators?: number
  pauseOperators?: number
  warningThreshold: number
  warningTimestamp: string
  aiHandlesChats?: boolean
  maxChatAiQuestions?: number
  maxAiQuestions?: number
  aiQuestions?: number
  aiModelId?: string
}

export interface TurnServer {
  host: string
  user: string
  password: string
}

export interface BlacklistNumber {
  number: string
  description: string
}
