/**
 * controlla che il campo sia un numero
 * @param value
 */
export const shouldBeNumber = (value: any) => {
  if (typeof value === 'number') return true
  // isNaN con una stringa FUNZIONA come ci si aspetta, qui il ts-ignore è d'obbligo.
  // @ts-ignore
  if (!isNaN(value)) return true
  return 'shouldBeNumber'
}
