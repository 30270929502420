import { SearchRounded } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'
import React, { KeyboardEventHandler, useState } from 'react'

type Props = TextFieldProps & {
  handleChange?: (v: string) => any
  handleSubmit?: (v: string) => any
}

export const Searchbar: React.FC<Props> = (props) => {
  const { variant = 'standard', handleChange, handleSubmit, ...rest } = props

  const [value, setValue] = useState('')

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      handleSubmit && handleSubmit(value)
    }
  }

  return (
    <TextField
      value={value}
      onChange={(event) => {
        setValue(event.target.value)
        handleChange && handleChange(event.target.value)
      }}
      variant={variant}
      fullWidth
      sx={{ ...rest.style, placeSelf: 'center', px: 2 }}
      onKeyDown={onKeyDown}
      {...rest}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              aria-label="search"
              onClick={() => handleSubmit && handleSubmit(value)}
              edge="start"
              size="small"
              disabled={rest.disabled}>
              <SearchRounded style={{ width: 18, height: 18 }} />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}
