import { AddCircleOutlineRounded, FitnessCenterRounded, RemoveCircleOutlineRounded } from '@mui/icons-material'
import React from 'react'
import styled from 'styled-components'

type Props = {
  value: number
  onChange: (newValue: number) => void
  min?: number
  max?: number
}
export const Penalty: React.FC<Props> = ({ value, onChange, ...props }) => {
  const { min = 0, max = 10 } = props
  const handleClick = (action: 'minus' | 'plus') => (e: React.MouseEvent<SVGSVGElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (action === 'minus') value - 1 >= min && onChange(value - 1)
    else value + 1 <= max && onChange(value + 1)
  }

  return (
    <Container>
      <FitnessCenterRounded sx={{ width: '0.9rem', height: '0.9rem', placeSelf: 'center' }} />
      <RemoveCircleOutlineRounded
        onClick={handleClick('minus')}
        sx={{ width: '0.8rem', height: '0.8rem', placeSelf: 'center' }}
      />
      <span style={{ placeSelf: 'center' }}>{value}</span>
      <AddCircleOutlineRounded
        onClick={handleClick('plus')}
        sx={{ width: '0.8rem', height: '0.8rem', placeSelf: 'center' }}
      />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  place-content: center;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0.25rem;
  background-color: ${(props) => props.theme.palette.action.disabled};
  border-radius: 15px;
  padding: 0 10px;
  text-align: center;
  font-size: 0.8rem;
`
