import { Reducer } from 'redux'
import { LanguageCode } from '../../api/types'
import * as t from './types'

const initialState: t.LanguageState = {
  translation: { menu: {}, status: {}, button: {}, pages: {} },
  selected: (sessionStorage.getItem('selectedTranslation') as LanguageCode) || 'it_IT'
}

type TranslationReducer = Reducer<t.LanguageState, t.TranslationActionTypes>

const language: TranslationReducer = (state = initialState, action: t.TranslationActionTypes): t.LanguageState => {
  switch (action.type) {
    case t.SET_LANGUAGE:
      return {
        selected: action.payload.selected,
        translation: { ...action.payload.translation }
      }
    default:
      return state
  }
}

export default language
