import React, {MouseEvent} from 'react'
import styled from 'styled-components'
import {Pause, Status, StatusesLabels} from '../../api/types'
import statusesColors from '../../constants/states-colors'
import {Tooltip} from "@mui/material";
import {getLanguage} from "../../store/selectors";
import {useSelector} from "react-redux";

export type NameToColor = {
  [key in StatusesLabels]?: string
}

type Props = {
  width?: string
  height?: string
  size?: string
  color?: string
  style?: React.CSSProperties
  stroke?: string
  strokeWidth?: string
  status?: Partial<Pause> | Partial<Status>
  onClick?: (e: MouseEvent) => any
}

export const StatusCircle = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { width, height, style, stroke, strokeWidth, size, status, onClick } = props
  const language = useSelector(getLanguage)

  const getColor = () => {
    if (!status) return '#000'
    if (status.isStatus) {
      return statusesColors[(status as Status).name || 'NOT_FOUND']
    } else {
      return (status as Pause).color
    }
  }

  return (
    <Tooltip title={status?.translations ? status?.translations[language] : ''} placement={'left'}>
      <Svg
        ref={ref}
        viewBox="0 0 24 24"
        widthCustom={size || width || '20px'}
        heightCustom={size || height || '20px'}
        style={
          style ? { cursor: onClick ? 'pointer' : 'default', ...style } : { cursor: onClick ? 'pointer' : 'default' }
        }
        {...(onClick ? { onClick } : {})}>
        <circle
          cx="12"
          cy="12"
          r="10"
          fill={getColor()}
          {...(stroke ? { stroke } : {})}
          {...(strokeWidth ? { strokeWidth } : {})}
        />
      </Svg>
    </Tooltip>
  )
})
type SvgProps = {
  widthCustom: string
  heightCustom: string
}
const Svg = styled.svg<SvgProps>`
  width: ${({ widthCustom }) => widthCustom};
  height: ${({ heightCustom }) => heightCustom};
`
