import { useMonitoringCalls } from '../../../../hooks'
import { Card, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../../../store/selectors'

type Props = {
  showCallsInPreQueue?: boolean
  showColors?: boolean
}

const CallsStatus: React.FC<Props> = ({ showCallsInPreQueue = true, showColors = true }) => {
  const theme = useTheme()
  const translation = useSelector(getTranslation)
  const calls = useMonitoringCalls()

  const inboundCalls = useMemo(() => [...calls.values()].filter(c => c.type === 'inbound' && c.inbounds.length && c.users.length), [calls])

  const preQueueCalls = useMemo(() => [...calls.values()].filter(c => c.type === 'inbound' && c.inbounds.length && !c.queues.length && !c.users.length), [calls])

  const queueCalls = useMemo(() => [...calls.values()].filter(c => c.type === 'inbound' && c.inbounds.length && c.queues.length && !c.users.length), [calls])

  const outboundCalls = useMemo(() => [...calls.values()].filter(c => c.type === 'outbound' && c.outbound), [calls])

  const predictiveCalls = useMemo(() => [...calls.values()].filter(c => c.type === 'predictive'), [calls])

  const callBackCalls = useMemo(() => [...calls.values()].filter(c => c.type === 'callback' && c.outbound), [calls])

  return (
    <Container>
      <Typography variant={'subtitle1'} color={'primary.dark'} fontWeight={600}>
        {translation.input.callsStatus}
      </Typography>
      <StatsContainer>
        {showCallsInPreQueue && <Stat>
          <Typography component={'span'} fontSize={'4rem'} color={showColors ? theme.palette.secondary.main : theme.palette.text.primary} fontWeight={600}>
            {preQueueCalls.length}
          </Typography>
          <Typography component={'span'} fontSize={'1rem'} color={showColors ? 'primary.main' : theme.palette.text.primary}>
            {translation.input.preQueue}
          </Typography>
        </Stat>}
        <Stat>
          <Typography component={'span'} fontSize={'4rem'} color={showColors ? theme.palette.error.main : theme.palette.text.primary} fontWeight={600}>
            {queueCalls.length}
          </Typography>
          <Typography component={'span'} fontSize={'1rem'} color={showColors ? 'primary.main' : theme.palette.text.primary}>
            {translation.input.inQueue}
          </Typography>
        </Stat>
        <Stat>
          <Typography component={'span'} fontSize={'4rem'} color={showColors ? theme.palette.success.main : theme.palette.text.primary} fontWeight={600}>
            {inboundCalls.length}
          </Typography>
          <Typography component={'span'} fontSize={'1rem'} color={showColors ? 'primary.main' : theme.palette.text.primary}>
            {translation.input.inbound}
          </Typography>
        </Stat>
        <Stat>
          <Typography component={'span'} fontSize={'4rem'} color={showColors ? theme.palette.success.main : theme.palette.text.primary} fontWeight={600}>
            {outboundCalls.length}
          </Typography>
          <Typography component={'span'} fontSize={'1rem'} color={showColors ? 'primary.main' : theme.palette.text.primary}>
            {translation.input.outbound}
          </Typography>
        </Stat>
        <Stat>
          <Typography component={'span'} fontSize={'4rem'} color={showColors ? 'primary.main' : theme.palette.text.primary} fontWeight={600}>
            {predictiveCalls.length}
          </Typography>
          <Typography component={'span'} fontSize={'1rem'} color={showColors ? 'primary.main' : theme.palette.text.primary}>
            {translation.input.predictive}
          </Typography>
        </Stat>
        <Stat>
          <Typography component={'span'} fontSize={'4rem'} color={showColors ? 'primary.main' : theme.palette.text.primary} fontWeight={600}>
            {callBackCalls.length}
          </Typography>
          <Typography component={'span'} fontSize={'1rem'} color={showColors ? 'primary.main' : theme.palette.text.primary}>
            {translation.input.callback}
          </Typography>
        </Stat>
      </StatsContainer>
    </Container>
  )
}

export default CallsStatus

//region Style
const Container = styled(Card)`
  grid-column: 2 / 4;
  grid-row: 1;
  padding: ${(props) => props.theme.spacing(1)};

  display: grid;
  grid-template-rows: auto 1fr auto;
`
const Stat = styled.div`
  font-size: 2rem;
  place-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const StatsContainer = styled.div`
  justify-content: space-around;
  display: flex;
  align-items: center;
`
//endregion
