import { DateTime } from 'luxon'

export type LevelsLabels = keyof Levels

export type Levels = {
  error: number
  warn: number
  info: number
  verbose: number
  debug: number
  silly: number
}

export const levels: Levels = {
  error: 0,
  warn: 1,
  info: 2,
  verbose: 3,
  debug: 4,
  silly: 5
}

interface Style {
  oc: string[]
  error: string
  warn: string
  info: string
  verbose: string
  debug: string
  silly: string
  separator: string
  date: string
}

const styleLight: Style = {
  oc: [
    'color: #f54700; font-size: 18px; font-weight: bold;',
    'color: #133553; font-size: 16px; font-weight: bold; font-family: verdana'
  ],
  error: 'color: red; ',
  warn: 'color: yellow; ',
  info: 'color: blue; ',
  verbose: 'color: darkgrey; ',
  debug: 'color: green; ',
  silly: 'color: black; ',

  separator: 'color: black; font-weight: normal',
  date: 'color: grey; font-style: italic; font-weight: normal'
}

const styleDark: Style = {
  oc: [
    'color: #FFFFFF; font-size: 18px; font-weight: bold;',
    'color: #f54700; font-size: 16px; font-weight: bold; font-family: verdana'
  ],
  error: 'color: red; ',
  warn: 'color: yellow; ',
  info: 'color: #8585f7; ',
  verbose: 'color: darkgrey; ',
  debug: 'color: green; ',
  silly: 'color: #f1f1f1; ',

  separator: 'color: white; font-weight: normal',
  date: 'color: grey; font-style: italic; font-weight: normal'
}

class Logger {
  private loglevel: number
  private readonly style: Style
  public error: (...params: any[][] | any[]) => void = () => {}
  public warn: (...params: any[][] | any[]) => void = () => {}
  public info: (...params: any[][] | any[]) => void = () => {}
  public debug: (...params: any[][] | any[]) => void = () => {}
  public verbose: (...params: any[][] | any[]) => void = () => {}
  public silly: (...params: any[][] | any[]) => void = () => {}

  constructor() {
    const localLogLevel = sessionStorage.getItem('loglevel')
    this.loglevel = localLogLevel ? parseInt(localLogLevel) : levels.silly
    this.style =
      (window as any).chrome && window.matchMedia('(prefers-color-scheme: dark)').matches ? styleDark : styleLight

    if (process.env.NODE_ENV === 'test') {
      this.error = (...params: any[][]) => this.log('error', ...params)
      return
    }
    Object.keys(levels).forEach((level: string) => {
      const levelLabel = level as LevelsLabels
      this[levelLabel] = (...params: any[][]) => this.loglevel >= levels[levelLabel] && this.log(levelLabel, ...params)
    })
  }

  log(level: LevelsLabels, ...message: any): void {
    // Se si sta loggando una semplice stringa aggiungiamo i decoratori per i colori e la formattazione
    if (message.length === 1 && typeof message[0] === 'string') {
      const buffer = []
      buffer.push(`%cocdashboard`)
      buffer.push(`%c[${level}]`)
      buffer.push(`%c${message[0]}`)
      buffer.push('%c~')
      buffer.push(`%c${DateTime.now().toFormat('HH:mm:ss.SSS')}`)

      const styles = ['color: #098DA9; ', this.style[level] + 'font-weight: bold;', this.style.silly, this.style.separator, this.style.date]

      console.log(buffer.join(' '), ...styles)
    } else {
      // Altrimenti, se il primo elemento è una stringa lo scriviamo direttamente nel messaggio del group e dentro
      // mettiamo tutti gli altri argomento passati alla funzione
      // Se il primo messaggio è un errore invece mettiamo il message nel group e il restante (stacktrace e forse altro)
      // dentro al group, visibile una volta espanso
      const firstIsString = typeof message[0] === 'string'
      const firstIsError = message[0] instanceof Error
      const buffer = []
      buffer.push(`%cocdashboard`)
      buffer.push(`%c[${level}]`)
      if (firstIsString) buffer.push(`%c${message[0]}`)
      if (firstIsError) buffer.push(`%c${message[0].message}`)
      buffer.push('%c~')
      buffer.push(`%c${DateTime.now().toFormat('HH:mm:ss.SSS')}`)

      const styles = ['color: #098DA9; ', this.style[level] + 'font-weight: bold;']
      if (firstIsString) styles.push(this.style.silly)
      styles.push(this.style.separator, this.style.date)

      console.groupCollapsed(buffer.join(' '), ...styles)

      for (let i = firstIsString ? 1 : 0; i < message.length; i++) {
        console.log(message[i])
      }

      console.groupEnd()
    }
  }

  oc(...messages: any) {
    if (typeof process !== 'undefined' && process.env.NODE_ENV === 'test') {
      return
    }
    console.log(messages.map((m: string) => `%c${m}`).join(' '), ...this.style.oc)
  }

  setLogLevel = (level: LevelsLabels) => {
    if (levels[level] !== undefined) {
      this.loglevel = levels[level]
      this.info(`Current log level: ${level}`)
    } else {
      this.error(`log level ${level} is not valid. Available levels:`, Object.keys(levels))
    }
  }

  getLogLevel = () => {
    const entry = Object.entries(levels).find(([_key, value]) => value === this.loglevel)
    return entry ? entry[0] : null
  }
}

const logger = new Logger()
export default logger
