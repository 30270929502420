import { getErrorMessage } from '../../helpers'
import { ErrorMessage } from '@hookform/error-message'
import { TextField, TextFieldProps } from '@mui/material'
import { getTranslation } from '../../store/selectors'
import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import { useController, UseControllerProps, useFormState } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Hint } from './Hint'
import {FieldValues} from "react-hook-form/dist/types";
import {useTheme} from "@mui/material/styles";

type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> &
  TextFieldProps & {
    hint?: string
    leaveSpaceForHint?: boolean
    leaveSpaceForError?: boolean
    containerStyle?: React.CSSProperties
    hasPermission?: boolean
  }

/** Wrapper per gestire input unendo HookForm e MUI */
export function Input<TFieldValues extends FieldValues>(props: PropsWithChildren<Props<TFieldValues>>) {
  const {
    control,
    name,
    rules,
    variant = 'outlined',
    hint,
    containerStyle,
    leaveSpaceForHint = true,
    leaveSpaceForError = true,
    InputProps,
    hasPermission,
    ...rest
  } = props

  const { field } = useController({ control, name, rules })
  const { errors } = useFormState<TFieldValues>({ control, name })

  const translation = useSelector(getTranslation)
  const theme = useTheme()

  const getMessage = (message = '') => getErrorMessage(translation, errors, name, message)

  const isReadOnly = useMemo(()=> InputProps?.readOnly || hasPermission === false,[InputProps?.readOnly, hasPermission])

  const findFieldErrors = useCallback(() => {
    const [father, child, grandChild] = name.split('.')

    if(grandChild) return ((errors[father] as any)?.[child] as any)?.[grandChild]
    if (child) return ((errors[father] as any)?.[child] as any)
    return errors[father]
  }, [errors, name])

  return (
    <Container style={containerStyle} leaveSpaceForHint={leaveSpaceForHint} leaveSpaceForError={leaveSpaceForError}>
      <TextField
        variant={variant}
        {...field}
        {...rest}
        sx={{ ...rest.sx, gridArea: 'input', ...(!!findFieldErrors() ? {fieldset: {borderColor: theme.palette.error.main}} : {}) }}
        fullWidth={true}
        InputProps={{ ...InputProps, readOnly: isReadOnly }}
      />
      <ErrorMessage errors={errors} name={name as any} render={({ message }) => <Error>{getMessage(message)}</Error>} />
      {hint && <Hint title={typeof rest.label === 'string' ? rest.label : ''} content={hint} />}
    </Container>
  )
}

//region Style

const Container = styled.div<{ leaveSpaceForHint: boolean; leaveSpaceForError: boolean }>`
  display: grid;
  grid-template-areas: 'input hint' 'error error';
  grid-template-columns: 1fr ${(props) => (props.leaveSpaceForHint ? '30px' : '')};
  grid-template-rows: ${(props) => (props.leaveSpaceForError ? 'min-content 20px' : '1fr')};
`

const Error = styled.div`
  grid-area: error;
  color: ${(props) => props.theme.palette.error.main};
  font-size: 0.8rem;
  margin-left: ${(props) => props.theme.spacing(1)};
`

//endregion
