import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import applicationState from './applicationState/reducer'
import language from './language/reducer'
import login from './login/reducer'
import stats from './stats/reducer'

const rootReducer = combineReducers({
  login,
  applicationState,
  language,
  stats
})

const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const middlewares = []
middlewares.push(thunk)

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({ collapsed: true })
  middlewares.push(logger)
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))

export default store
