import { PaletteMode } from '@mui/material'

const mode: PaletteMode = 'dark'

export const darkTheme = {
  palette: {
    mode,
    primary: {
      dark: '#77d5ff',
      main: '#3AA4DD',
      light: '#0075ab'
    },
    secondary: {
      dark: '#ff9800',
      main: '#ffb74d',
      light: '#ffe97d'
    },
    background: {
      paper: '#2E2E2E',
      default: '#303030'
    },
    text: {
      primary: '#ffffff',
      secondary: '#DFDFDF',
      disabled: '#adabab'
    },
    action: {
      active: '#DFDFDF'
    }
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: `"Open Sans", sans-serif`
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    },
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    }
  }
}
