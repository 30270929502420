import {Autocomplete, AutocompleteProps, Checkbox, FormControl, ListItemText, MenuItem, TextField} from '@mui/material'
import {getTranslation} from '../../store/selectors'
import React, {PropsWithChildren, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {Hint} from './Hint'

export interface RawOptionsType {
  key: string | number
  label: string
  value: string | number
  deleted?: boolean
}

type Props = Partial<AutocompleteProps<any, true, any, any>> & {
    label: string
    options: RawOptionsType[]
    variant?: 'outlined' | 'filled' | 'standard'
    hint?: string
    leaveSpaceForHint?: boolean
    leaveSpaceForError?: boolean
    containerStyle?: React.CSSProperties
    hasPermission?: boolean
    withSelectAll?: boolean
    placeholder?: string
    onChange: (data: any) => void
  }

/**
 * Componente comune per la MultipleAutocomplete
 * @constructor
 * @param props
 */
export function RawMultipleAutoComplete(props: PropsWithChildren<Props>) {
  const {
    variant = 'standard',
    options,
    label,
    containerStyle,
    hint,
    leaveSpaceForHint = true,
    leaveSpaceForError = true,
    defaultValue = [],
    onChange
  } = props

  const translation = useSelector(getTranslation)

  const [values, setValues] = useState<RawOptionsType[]>([])

  useEffect(() => {
    const newValue = defaultValue?.map((val) => options.find((o) => val === o.value)) || []
    setValues(newValue)
  }, [defaultValue, options, setValues])

  return (
    <Container style={containerStyle} leaveSpaceForHint={leaveSpaceForHint} leaveSpaceForError={leaveSpaceForError}>
      <FormControl fullWidth>
        <Autocomplete
          value={values}
          multiple
          fullWidth={true}
          getOptionLabel={(option) => {
            if (!option) return ''
            return option.label
          }}
          isOptionEqualToValue={(option, value) => value && option.value === value.value}
          renderOption={(props, option) => {
            return (
              <MenuItem {...props} key={option.key} value={option.key}>
                <Checkbox
                  checked={!!values.find((value) => value.key === option.key) || options.length === values.length}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                />
                <ListItemText
                  primary={option.label}
                  sx={{ marginLeft: '0.3rem', color: option.deleted ? 'action.disabled' : 'inherit' }}
                />
              </MenuItem>
            )
          }}
          renderInput={({ InputProps, ...params }) => {
            const startAdornment =
              values.length > 0 ? (
                <div
                  style={{
                    maxHeight: `calc((24px + 5px) * 2)`,
                    overflowY: 'auto'
                  }}>
                  {InputProps.startAdornment}
                </div>
              ) : null
            return (
              <TextField
                variant={variant}
                color="primary"
                {...params}
                fullWidth
                InputProps={{
                  ...InputProps,
                  startAdornment: startAdornment
                }}
                label={label}
              />
            )
          }}
          options={
            [{ key: 'select-all-option', label: translation.input.selectAll, value: 'all' }, ...options]
          }
          onChange={(_, data) => {
            if (data.find((dataItem) => dataItem.value === 'all')) {
              if (values.length === options.length) {
                setValues([])
                onChange([])
              }
              else {
                setValues([...options])
                onChange([...options.map((o) => o.value)])
              }
            } else {
              setValues(data)
              onChange(data.map((o) => o.value))
            }
          }}
        />
      </FormControl>
      {hint && <Hint title={label} content={hint} />}
    </Container>
  )
}

// region style
const Container = styled.div<{ leaveSpaceForHint: boolean; leaveSpaceForError: boolean }>`
  display: grid;
  grid-template-areas: 'input hint';
  grid-template-columns: 1fr ${(props) => (props.leaveSpaceForHint ? '30px' : 'auto')};
`
// endregion
