import { expandedDrawerWidth, IMenuItem } from '../../constants'
import { Alert, Box, Snackbar } from '@mui/material'
import { resetNotification } from '../../store/actions'
import { getActions, getRestNotification } from '../../store/selectors'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { DefaultTheme } from 'styled-components'
import Navbar from './Navbar/Navbar'
import Topbar from './Topbar/Topbar'
import { Outlet } from "react-router-dom";

/**
 * Componente Template del Front end
 * @param props.children content della pagina
 * @constructor
 */
const Template: React.FC<PropsWithChildren<{ menuItems: IMenuItem[], setTheme: (theme: DefaultTheme) => void }>> = (props) => {
  const [minimize, setMinimize] = useState(false)
  const dispatch = useDispatch()
  const { message, severity, open } = useSelector(getRestNotification)
  const actions = useSelector(getActions)

  useEffect(() => {
    if (props.menuItems.length === 0) setMinimize(true)
    else setMinimize(false)
  }, [props.menuItems])

  const disableMinimize = () => {
    const views = actions.get('view')
    return !views || views.size === 0
  }
  return (
    <MainContainer>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={() => dispatch(resetNotification())}>
        <Alert
          onClose={() => dispatch(resetNotification())}
          variant="filled"
          severity={severity}
          sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Navbar minimize={minimize} menuItems={props.menuItems} />
      <Container minimize={minimize}>
        <Topbar minimize={minimize} setMinimize={setMinimize} disableMinimize={disableMinimize()} />
        <ContentContainer minimize={minimize}>
          <>
            <Box sx={{ p: 2, height: '100%' }}>
              <Outlet />
            </Box>
          </>
        </ContentContainer>
      </Container>
    </MainContainer>
  )
}

//region styles
const Container = styled.div<{ minimize: boolean }>`
  width: 100%;
  grid-area: ${'main-area'};
  flex-grow: 1;
  margin-left: ${({ minimize }) => (minimize ? `-${expandedDrawerWidth}` : `0px`)};
  display: grid;
  grid-template-rows: var(--header-height) calc(100vh - var(--header-height));
  grid-template-areas: "header" "main";
  transition: margin ${({ minimize, theme }) =>
    !minimize
      ? `${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp}`
      : `${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut}`} 0ms;
}
`

const MainContainer = styled.div`
  display: flex;
  background: ${({ theme }) => theme.palette.primary.main};
`

const ContentContainer = styled.div<{ minimize: boolean }>`
  grid-area: main;
  height: calc(100vh - var(--header-height));
  box-sizing: border-box;
  background-color: ${({ theme }) => (theme.palette.mode === 'light' ? '#F3F3F3' : '#121212')};
  overflow: hidden;
  
`

//endregion

export default Template
