import React from 'react'
import styled from 'styled-components'

const NoRole: React.FC = () => {
  return <Container>Non ti è stato assegnato alcun ruolo, contatta l'amministratore del sistema</Container>
}

export default NoRole

//region Style

const Container = styled.div`
  font-size: 2rem;
  opacity: 0.8;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
`

//endregion
