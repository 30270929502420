import { Hint } from './Hint'
import { getErrorMessage } from '../../helpers'
import { ErrorMessage } from '@hookform/error-message'
import { Chip, TextField, TextFieldProps } from '@mui/material'
import { getTranslation } from '../../store/selectors'
import Downshift, { ControllerStateAndHelpers } from 'downshift'
import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { useController, UseControllerProps, useFormState } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {FieldValues} from "react-hook-form/dist/types";
import {useTheme} from "@mui/material/styles";

export function MultipleInput<TFieldValues extends FieldValues>(props: PropsWithChildren<Props<TFieldValues>>) {
  const {
    control,
    name,
    rules,
    variant = 'outlined',
    hint,
    containerStyle,
    leaveSpaceForHint = true,
    disabled,
    InputProps,
    hasPermission,
    ...rest
  } = props

  const [inputValue, setInputValue] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState<string[]>([])

  const translation = useSelector(getTranslation)
  const theme = useTheme()

  const { field } = useController({ control, name, rules })
  const { errors } = useFormState<TFieldValues>({ control, name })

  const getMessage = (message = '') => getErrorMessage(translation, errors, name, message)

  const findFieldErrors = useCallback(() => {
    const [father, child, grandChild] = name.split('.')

    if(grandChild) return ((errors[father] as any)?.[child] as any)?.[grandChild]
    if (child) return ((errors[father] as any)?.[child] as any)
    return errors[father]
  }, [errors, name])

  const isEnabled = useMemo((): boolean => {
    // inverte disabled se passato come prop
    const enabled = typeof disabled === 'boolean' ? !disabled : true
    // prende readOnly se è defined, oppure enabled che è true di default
    const fromProps = InputProps?.readOnly ?? enabled
    // se non viene passato hasPermission mi affido alle altre prop
    if (hasPermission === undefined) {
      return fromProps
    } else {
      // altrimenti deve anche avere il permesso oltre a dipendere dalle altre props
      return hasPermission && fromProps
    }
  }, [hasPermission, disabled, InputProps])

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const newSelectedItem = [...selectedItem]
      const duplicatedValues = newSelectedItem.indexOf(event.target.value.trim())

      if (duplicatedValues !== -1) {
        setInputValue('')
        return
      }
      if (!event.target.value.replace(/\s/g, '').length) return

      newSelectedItem.push(event.target.value.trim())
      field.onChange(newSelectedItem)
      setInputValue('')
    }
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      field.onChange(selectedItem.slice(0, selectedItem.length - 1))
    }
  }
  function handleChange(item: string[] | null, _: ControllerStateAndHelpers<string[]>) {
    if (!item || !item.length) return
    setInputValue('')
    field.onChange(item)
  }
  const handleDelete = (item: string) => () => {
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    field.onChange(newSelectedItem)
  }

  function handleInputChange(event: any) {
    setInputValue(event.target.value)
  }

  useEffect(() => {
    setSelectedItem(field.value as string[])
  }, [field.value])

  return (
    <Container style={containerStyle} leaveSpaceForHint={leaveSpaceForHint}>
      <Downshift<string[]>
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={selectedItem}>
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder: props.placeholder,
            onFocus: props.onFocus
          })
          return (
            <div>
              <TextField
                {...field}
                {...rest}
                sx={{...(!!findFieldErrors() ? {fieldset: {borderColor: theme.palette.error.main}} : {})}}
                InputProps={{
                  startAdornment: selectedItem.map((item) => (
                    <Chip
                      key={item}
                      tabIndex={-1}
                      label={item}
                      sx={{
                        chip: {
                          mx: 0.25,
                          my: 0.5
                        }
                      }}
                      onDelete={handleDelete(item)}
                    />
                  )),
                  onBlur: (event) => {
                    const newSelectedItem = [...selectedItem]
                    const duplicatedValues = newSelectedItem.indexOf(event.target.value.trim())

                    if (duplicatedValues !== -1) {
                      setInputValue('')
                      return
                    }
                    if (!event.target.value.replace(/\s/g, '').length) return

                    newSelectedItem.push(event.target.value.trim())
                    field.onChange(newSelectedItem)
                    setInputValue('')
                  },
                  onChange: (event) => {
                    handleInputChange(event)
                    onChange && onChange(event)
                  },
                  onFocus,
                  readOnly: !isEnabled,
                  ...(inputProps as any)
                }}
                fullWidth={true}
                variant={variant}
                disabled={!isEnabled}
              />
            </div>
          )
        }}
      </Downshift>
      <ErrorMessage errors={errors} name={name as any} render={({ message }) => <Error>{getMessage(message)}</Error>} />
      {hint && <Hint title={typeof rest.label === 'string' ? rest.label : ''} content={hint} />}
    </Container>
  )
}

// region types
type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> &
  TextFieldProps & {
    hint?: string
    leaveSpaceForHint?: boolean
    containerStyle?: React.CSSProperties
    hasPermission?: boolean
  }
// endregion

//region Style

const Container = styled.div<{ leaveSpaceForHint: boolean }>`
  display: grid;
  grid-template-areas: 'input hint' 'error error';
  grid-template-columns: 1fr ${(props) => (props.leaveSpaceForHint ? '30px' : 'auto')};
  grid-template-rows: min-content 20px;
`

const Error = styled.div`
  grid-area: error;
  color: ${(props) => props.theme.palette.error.main};
  font-size: 0.8rem;
  margin-left: ${(props) => props.theme.spacing(1)};
`

//endregion
