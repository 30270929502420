import {log} from '../helpers'
import {useCallback, useEffect, useRef, useState} from 'react'
import SocketManager from '../api/socket'
import {Channels, Entities} from './types'


/**
 * Hook per la gestione dei dati provenienti dalla socket
 * @param channels {string[]} array di canali da sottoscrivere
 * @param [lazy = false] {boolean} specifica se si vogliono aggiungere i listener "di default" sulla socket
 * @returns { {entities: Partial<Entities>} }
 */

function useSocket(channels: Channels[], lazy: boolean = false): UseSocketHookReturn {
  const [entities, setEntities] = useState<Partial<Entities>>({})
  /** collezione contenente gli observers divisi per canale */
  const observers = useRef<Map<Channels, UseSocketObserver<any>[]>>(new Map())

  // funzione per l'aggiornamento dell'entità in seguito alla ricezione dell'evento channelName-update
  const updateEntity = useCallback(
    (channel: Channels, updated: any) => {
      setEntities((entities) => {
        const entityToUpdate = entities[channel] as OcEntity[]
        // se non mi sono iscritto al canale in precedenza non faccio nulla
        if (!entityToUpdate) return entities

        emitChange(updated, channel)
        //filtro la lista dell'entità eliminando l'entità aggiornata
        const filteredList = entityToUpdate.filter((e: any) => {
          //se ho l'evento di chiusura della chiamata, filtro per le chiamate con id diverso da quella chiusa
          if(channel === 'calls' && updated.type === 'call-closed') return e.call !== updated.call
          // caso generico
          if (e.id && updated.id) return e.id !== updated.id
          // caso caso callback
          if (e.key && updated.key) return e.key !== updated.key
          //caso code
          if (e.extension && e.pbx && e.pbx.id) return e.extension !== updated.extension || e.pbx.id !== updated.pbx.id
          // altrimenti
          return true
        })
        //reinserisco l'entita aggiornata se è diverso l'evento dal call-closed
        if(updated?.type !== 'call-closed') {
          filteredList.push(updated)
        }
        //ricreo l'oggetto per il salvataggio nello state
        return {...entities, [channel]: filteredList}
      })
    },
    [setEntities]
  )

  // funzione per l'aggiornamento dell'entità in seguito alla ricezione dell'evento channelName-update
  const addEntity = useCallback(
    (channel: Channels, newEntity: any) => {
      setEntities((entities) => {
        const entityToUpdate = entities[channel] as OcEntity[]

        // se non mi sono iscritto al canale in precedenza non faccio nulla
        if (!entityToUpdate) return entities

        emitChange(newEntity, channel)
        entityToUpdate.push(newEntity)

        //ricreo l'oggetto per il salvataggio nello state
        return {...entities, [channel]: entityToUpdate}
      })
    },
    [setEntities]
  )

  // funzione per l'aggiornamento dell'entità in seguito alla ricezione dell'evento channelName-update
  const removeEntity = useCallback(
    (channel: Channels, removed: any) => {
      setEntities((entities) => {
        const entityToUpdate = entities[channel] as OcEntity[]

        // se non mi sono iscritto al canale in precedenza non faccio nulla
        if (!entityToUpdate) return entities

        emitChange(removed, channel)
        //filtro la lista dell'entità eliminando l'entità aggiornata
        const filteredList = entityToUpdate.filter((e: any) => {
          // caso generico
          if (e.id && removed.id) return e.id !== removed.id
          // caso callback
          if (e.key && removed.key) return e.key !== removed.key
          //caso code
          if (e.extension && e.pbx && e.pbx.id) return e.extension !== removed.extension || e.pbx.id !== removed.pbx.id
          //altrimenti
          return true
        })

        //ricreo l'oggetto per il salvataggio nello state
        return {...entities, [channel]: filteredList}
      })
    },
    [setEntities]
  )

  // aggiunge i listener per gli eventi update/list che mi arrivano sulla socket
  const addListeners = useCallback(() => {
    new Set(channels).forEach((channel: keyof Entities) => {
      SocketManager.on(`${channel}-list`, (payload: any[]) => {
        log.silly(`Got list for ${channel}`, payload)
        //devo usare Ref perche sono in un listener
        setEntities((entities) => {
          return {...entities, [channel]: payload}
        })
      })
      SocketManager.on(`${channel}-update`, (payload: any) => {
        log.silly(`Got update for ${channel}`, payload)
        updateEntity(channel, payload)
      })
      // Evento che il core invia quando aggiunge un'entità
      SocketManager.on(`${channel}-add`, (payload: any) => {
        log.silly(`Got add for ${channel}`, payload)
        addEntity(channel, payload)
      })
      // Evento che il core invia quando elimina un'entità (valido solo per proactiveGuests)
      SocketManager.on(`${channel}-remove`, (payload: any) => {
        log.silly(`Got remove for ${channel}`, payload)
        removeEntity(channel, payload)
      })
      // Evento che il core invia quando elimina un'entità
      SocketManager.on(`${channel}-delete`, (payload: any) => {
        log.silly(`Got delete for ${channel}`, payload)
        removeEntity(channel, payload)
      })
    })
  }, [channels, updateEntity, addEntity, removeEntity, setEntities])

  const removeListeners = useCallback(() => {
    channels.forEach((channel: keyof Entities) => {
      ;[`${channel}-list`, `${channel}-update`].forEach((e) => {
        SocketManager.removeAllListeners(e)
      })
    })
  }, [channels])

  /** aggiorna tutti gli observer su ciò che si è aggiornato */
  const emitChange = (payload: any, channel: Channels) => {
    const observersArr = observers.current.get(channel)
    if (observersArr) {
      log.silly(`Emitting changes, got ${observersArr.length} observers`, payload)
      observersArr.forEach((o) => o && o(payload))
    }
  }

  /** permette di registrare degli observer per recuperare gli update puntuali su un singolo oggetto di un canale */
  function observe<T>(o: UseSocketObserver<T>, channel: Channels): () => void {
    const observersArr = observers.current.get(channel)
    if (observersArr) {
      observersArr.push(o)
    } else {
      observers.current.set(channel, [o])
    }
    return () => {
      const observersArr = observers.current.get(channel)
      if (observersArr)
        observers.current.set(
          channel,
          observersArr.filter((t) => t !== o)
        )
    }
  }

  //mi iscrivo ai canali
  useEffect(() => {
    !lazy && addListeners()
    if(SocketManager.socketReady) {
      SocketManager.subscribe(channels)
    }
    else {
      SocketManager.on('websocket-open', () => {
        SocketManager.subscribe(channels)
      })
    }

    return () =>{
      removeListeners()
      SocketManager.unsubscribe(channels)
      SocketManager.removeListener(`websocket-open`, () => {SocketManager.subscribe(channels)})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels, addListeners, removeListeners])

  return {
    entities,
    observe
  }
}

export default useSocket

//region Types

interface UseSocketHookReturn {
  entities: Partial<Entities>
  observe: <T>(o: UseSocketObserver<T>, channel: Channels) => () => void
}

interface OcEntity {
  id?: number
  extension?: number
  pbx?: {
    id: number
  }
}

export type UseSocketObserver<T> = ((entity: T) => void) | null

//endregion
