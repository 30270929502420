import {LoginPayload} from '../../store/login/types'
import EventEmitter from 'events'
import {LevelsLabels} from '../../helpers/logger'
import {User} from '../types'
import {verifyOCClientOpenedAndExecuteAction} from "../helpers";
import {Translation} from "../../store/language/types";

export type SpyType = 'anonymous' | 'support' | 'complete'

export interface OCClientSettings {
  /** Indirizzo verso le api di OC */
  baseUrl: string
  /** Larghezza di partenza del client esteso */
  width: number
  /** Altezza di partenza del client esteso */
  height: number
  /** Flag per decidere se il client parte visibile o meno */
  visible: boolean
  /** Flag per ritardare il mount del client nel dom, se true deve essere montato
   *  manualmente richiamando la funzione mount */
  delayMount: boolean
  /** Abilita e disabilita il rinnovo automatico del token */
  tokenRenew: boolean
  /** Oggetto per configurare la versione minificata */
  minified: {
    /** Decreta l'utilizzo della versione fluttuante o ancorata */
    version: 'floating' | 'attached'
    /** Larghezza della versione minificata */
    width?: number
    /** Altezza della versione minificata */
    height?: number
  }
  /** Oggetto per configurare le informazioni da mostrare sul display del client per le chiamate ricevute */

  /** Indica se mostrare o meno l'opzione per chiudere il client, di default è si quando embedded e no quando desktop */
  showCloseOption: boolean
  showChooseTranslations: boolean
  showLogoutButtonOption: boolean
  showThemeButton: boolean
  /** Indica se il client è in modalità popup, dunque deve prendere tutta la dimensione della finestra possibile nel caso web */
  popup: boolean
}

export interface OCClient extends EventEmitter {
  ref: WindowProxy | null
  width: number
  height: number
  visible: boolean
  setLogLevel: (lvl: LevelsLabels) => void
  setDimensions: (opts: { width: number; height: number }) => void
  setTheme: (type: 'light' | 'dark') => void
  show: () => void
  hide: () => void
  mount: () => void
  unmount: () => void
  logout: () => void
  login: (payload: LoginPayload, janusInit?: boolean) => void
  popupLogin: (payload: Partial<LoginPayload>) => void
  renewToken: (payload: LoginPayload) => void
  loginWithToken: (token: string) => void
  setLanguage: (language: string) => void
  phone: {
    call: (data: { number: string; outbound?: string; info?: any; user?: User }) => void
    spy: (data: { type: SpyType; extension: number; pbxId: number }) => void
  }
  messenger: {
    goToConversation: (username: string) => void
  }
  chat: {
    spy: (data: {chatId: string, userId: number}) => void
    startProactiveChat: (data: {departmentId: number, guestId: string}) => void
  }
  mounted: boolean
  settings: OCClientSettings
}

export const openPopup = (payload: Partial<LoginPayload>) => {
  const windowName = 'OpenCommunicationClient'
  const innerWidth = localStorage.getItem('@occlient/appWidth') || 280
  const innerHeight = localStorage.getItem('@occlient/appHeight') || 525
  const windowProperties = [
    'popup',
    'scrollbars=0',
    'resizable=1',
    'status=no',
    'location=no',
    'toolbar=no',
    'titlebar=no',
    'menubar=no',
    `innerWidth=${localStorage.getItem('@occlient/minimized') === 'true' ? 280 : innerWidth}`,
    // Non si capisce come mai ma la finestra viene creata di un pixel piu piccola rispetto alle dimensioni date in altezza
    `innerHeight=${localStorage.getItem('@occlient/minimized') === 'true' ? 126 : innerHeight}`
  ].join(', ')
  // serve per il client per settarsi sulla dimensione della finestra
  // esegue il login, non è asincrono perché il client in questo caso si fida del payload passato
  window.occlient?.popupLogin(payload)
  // crea il popup
  const handle = window.open(
    `${process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : window.location.origin}/client`,
    windowName,
    windowProperties
  )
  if (window.occlient) {
    window.occlient.ref = handle
    window.occlient.emit('popup-handle-change', handle)
  }
}

export const goToConversation = (username: string, ocClientRef: Window | null, translation: Translation) => {
  verifyOCClientOpenedAndExecuteAction(ocClientRef, translation, () => ocClientRef!.occlient?.messenger.goToConversation(username))
}
