import { Reducer } from 'redux'
import { ActionName } from '../../api/types'
import * as t from './types'

const initialState: t.LoginState = {
  user: null,
  token: sessionStorage.getItem('token'),
  error: '',
  methods: [],
  selectedMethod: null,
  isAuthenticated: false,
  actions: new Map(),
  otpRequired: true,
  renewTimeout: null

}

type LoginReducer = Reducer<t.LoginState, t.LoginActionTypes>

const loginReducer: LoginReducer = (state = initialState, action: t.LoginActionTypes): t.LoginState => {
  switch (action.type) {
    case t.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        actions: new Map(
          Object.entries(action.payload.user!.actions).map(([key, value]) => [
            key as ActionName,
            new Set(value as string[])
          ])
        ),
        isAuthenticated: true,
        error: '',
        selectedMethod: null
      }
    case t.RENEW_TOKEN:
      return {
        ...state,
        token: action.payload.token
      }
    case t.PAGE_REFRESHED:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        actions: new Map(
          Object.entries(action.payload.user!.actions).map(([key, value]) => [
            key as ActionName,
            new Set(value as string[])
          ])
        ),
        isAuthenticated: true
      }
    case t.LOGOUT:
      return { ...state, user: null, token: null, isAuthenticated: false }
    case t.LOGIN_ERROR:
      return { ...state, error: action.payload, token: null, isAuthenticated: false }
    case t.SET_LOGIN_METHODS:
      return { ...state, methods: action.payload.methods, otpRequired: action.payload.otp }
    case t.SELECT_LOGIN_METHOD:
      return { ...state, selectedMethod: action.payload }
    case t.RENEW_TOKEN_TIMEOUT:
      return { ...state, renewTimeout: action.payload }

    default:
      return state
  }
}

export default loginReducer
