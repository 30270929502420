import { EditOutlined } from '@mui/icons-material'
import {Checkbox, IconButton, Tooltip} from '@mui/material'
import { setSelectedTableRows } from '../../store/actions'
import {getSelectedTableRows, getTranslation} from '../../store/selectors'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AutoSizeTable } from './AutoSizeTable'
import { MuiTableProps } from './VirtualizedTable'

function SelectableTable<RowData>({ showCheckboxes = true, ...props }: Props<RowData>) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedRowIds = useSelector(getSelectedTableRows)
  const translation = useSelector(getTranslation)

  // Dato cancellato sul core e sull'agent
  const isDataDeleted = useCallback(
    (rowData: RowData) => (rowData as any).hasOwnProperty('deleted') && (rowData as any).deleted,
    []
  )
  // Dato cancellato sul core e sull'agent ma asterisk ancora non riavviato
  const isDataToBeEdited = useCallback(
    (rowData: RowData) =>
      (rowData as any).hasOwnProperty('differenceFromPbx') && (rowData as any).differenceFromPbx !== '',
    []
  )

  const checkboxes = useMemo(() => {
    if (showCheckboxes) {
      return {
        name: 'checkbox',
        header: (
          <Checkbox
            size="small"
            sx={{width: 40, height: 40}}
            checked={selectedRowIds.length > 0}
            onChange={() => {
              if (selectedRowIds.length > 0) {
                // deselect all
                dispatch(setSelectedTableRows([]))
              } else {
                // select all
                dispatch(
                  setSelectedTableRows(
                    props.data
                      .filter((row) => !(isDataDeleted(row) || isDataToBeEdited(row)))
                      .map((d) => d[props.idField])
                  )
                )
              }
            }}
            {...(selectedRowIds.length > 0 &&
              selectedRowIds.length !== props.data.length && {
                indeterminate: true,
                color: 'default'
              })}
          />
        ),
        cell: (rowData: RowData) => {
          return (
            <Checkbox
              size="small"
              sx={{width: 40, height: 40}}
              checked={selectedRowIds.some((id: any) => rowData[props.idField] === id)}
              disabled={isDataDeleted(rowData) || isDataToBeEdited(rowData)}
              onChange={(e) => {
                if (!e.target.checked) {
                  dispatch(setSelectedTableRows(selectedRowIds.filter((sr: any) => sr !== rowData[props.idField])))
                } else {
                  dispatch(setSelectedTableRows(selectedRowIds.concat(rowData[props.idField])))
                }
              }}
            />
          )
        },
        width: 50
      }
    }
  }, [showCheckboxes, selectedRowIds, props.data, props.idField, dispatch, isDataDeleted, isDataToBeEdited])

  const editButton = useMemo(() => {
    if (props.detailPageBasePath) {
      return {
        header: '',
        name: 'edit',
        cell: (rowData: RowData) => (
          <Tooltip title={translation.input.edit}>
            <IconButton
              size="small"
              disabled={isDataDeleted(rowData) || isDataToBeEdited(rowData)}
              onClick={() => navigate (`${props.detailPageBasePath}/${rowData[props.idField]}`)}>
              <EditOutlined />
            </IconButton>
          </Tooltip>
        ),
        width: 60
      }
    }
  }, [props.detailPageBasePath, navigate, props.idField, isDataDeleted, isDataToBeEdited, translation.input.edit])
  const columns = useMemo(() => {
    const completeColumns = [...props.columns]
    checkboxes && completeColumns.unshift(checkboxes)
    editButton && completeColumns.push(editButton)
    return completeColumns
  }, [checkboxes, props.columns, editButton])

  const otherProps = useMemo(() => {
    const { detailPageBasePath, ...other } = props
    return other
  }, [props])

  useEffect(() => {
    dispatch(setSelectedTableRows([]))
  }, [dispatch])

  return (
    <AutoSizeTable
      {...otherProps}
      columns={columns}
      isCellSelected={(column: any, rowData: any) =>
        selectedRowIds.some((id: any) => rowData && rowData[props.idField] === id)
      }
      isDataDeleted={isDataDeleted}
      isDataToBeDeleted={isDataToBeEdited}
    />
  )
}

type Props<RowData> = Omit<MuiTableProps<RowData>, 'height' | 'width'> & {
  showCheckboxes?: boolean
  detailPageBasePath?: string
}

export { SelectableTable }
