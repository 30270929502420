import {getErrorMessage} from '../../helpers'
import {ErrorMessage} from '@hookform/error-message'
import {InputLabel} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import {getTranslation} from '../../store/selectors'
import React, {PropsWithChildren, useMemo} from 'react'
import {useController, UseControllerProps, useFormState} from 'react-hook-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {Hint} from './Hint'
import {FieldValues} from "react-hook-form/dist/types";

type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> &
  React.InputHTMLAttributes<HTMLInputElement> & {
    hasPermission?: boolean
    leaveSpace?: boolean
    label?: string
    hint?: string
  }

export function RichTextEditor<TFieldValues extends FieldValues>(props: PropsWithChildren<Props<TFieldValues>>) {
  const { name, control, rules, disabled, hasPermission, leaveSpace = true, label, hint } = props

  const { field } = useController({ control, name, rules })
  const { errors } = useFormState<TFieldValues>({ control, name })

  const translation = useSelector(getTranslation)

  const getMessage = (message = '') => getErrorMessage(translation, errors, name, message)

  const isEnabled = useMemo((): boolean => {
    // inverte disabled se passato come prop
    // prende readOnly se è defined, oppure enabled che è true di default
    const fromProps = typeof disabled === 'boolean' ? !disabled : true
    // se non viene passato hasPermission mi affido alle altre prop
    if (hasPermission === undefined) {
      return fromProps
    } else {
      // altrimenti deve anche avere il permesso oltre a dipendere dalle altre props
      return hasPermission && fromProps
    }
  }, [hasPermission, disabled])

  const muiTheme = useTheme()

  return (
    <Container leaveSpace={leaveSpace}>
      {label && <InputLabel sx={{ gridArea: 'label', gridColumn: '1 / -1' }}>{label}</InputLabel>}
      <ReactQuill
        theme="bubble"
        {...field}
        value={field.value as any}
        onChange={(v) => isEnabled && field.onChange(v)}
        style={{
          gridArea: 'input',
          gridColumn: '1 / -1',
          width: '100%',
          border: '0',
          borderRadius: '4px',
          background: isEnabled ? muiTheme.palette.background.default : muiTheme.palette.action.disabledBackground,
          color: isEnabled ? muiTheme.palette.text.primary : muiTheme.palette.action.disabled,
          opacity: isEnabled ? 1 : muiTheme.palette.action.disabledOpacity
        }}
      />
      <ErrorMessage errors={errors} name={name as any} render={({ message }) => <Error>{getMessage(message)}</Error>} />
      {hint && <Hint title={typeof label === 'string' ? label : ''} content={hint} />}
    </Container>
  )
}

//region Style

const Container = styled.div<{ leaveSpace: boolean }>`
  display: grid;
  grid-template-areas: 'label label' 'input input' 'hint error';
  grid-template-rows: auto 1fr ${(props) => (props.leaveSpace ? '30px' : 'auto')};
  grid-template-columns: auto 1fr;
  width: 100%;
`

const Error = styled.div`
  grid-area: error;
  color: ${(props) => props.theme.palette.error.main};
  font-size: 0.8rem;
  margin-left: ${(props) => props.theme.spacing(1)};
  display: grid;
  place-self: center start;
`

//endregion
