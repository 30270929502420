import { Input } from '../../common'
import { logger } from '../../../helpers'
import { CloseRounded } from '@mui/icons-material'
import { Button, Paper, Typography } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { authUrl, coreUrl } from '../../../configuration'

const Wizard: React.FC = () => {
  const [error, setError] = useState('')
  const { state } = useLocation()
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...defaultValues },
    shouldUnregister: false
  })

  const onSubmit = (data: FormData) => {
    axios
      .post(`${coreUrl}/wizard/authentication-method`, data, { headers: { Authorization: 'Bearer ' + state.token } })
      .then(() => {
        navigate('/login')
      })
      .catch((e) => {
        logger.error(e)
        setError(e.response?.data?.message ?? 'Something went wrong')
      })
  }

  return (
    <Container>
      <Paper sx={{ display: 'grid', placeSelf: 'center', width: 400, p: 1 }}>
        <p>
          Benvenuto nel wizard di installazione di OpenCommunication.
          <br />
          Devi creare il primo Client su OCAuth,{' '}
          <a href={authUrl} rel="noopener" style={{ display: 'inline-block' }}>
            cliccando qui.
          </a>
        </p>
        <p>Una volta ottenuti Client ID e Client Secret incollali qui sotto e invia.</p>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'grid' }}>
          <Input<FormData>
            name="clientId"
            control={control}
            label="Client ID"
            leaveSpaceForHint={false}
            rules={{ required: true }}
          />
          <Input<FormData>
            name="clientSecret"
            control={control}
            label="Client Secret"
            leaveSpaceForHint={false}
            rules={{ required: true }}
          />
          <Button disabled={!isValid} variant="contained" sx={{ width: '40%', placeSelf: 'center' }} type={'submit'}>
            Invia
          </Button>
          {error && (
            <Typography color="error">
              {error}
              <CloseRounded color="error" />
            </Typography>
          )}
        </form>
      </Paper>
    </Container>
  )
}

export default Wizard

//region Form

type FormData = {
  clientId: string
  clientSecret: string
}
const defaultValues = {
  cliendId: '',
  clientSecret: ''
}
//endregion
//region Style

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  background: var(--section-background);
`
//endregion
