import { Reducer } from 'redux'
import * as t from './types'
import { StatsFavourite } from './types'

let startingFavs = []
const favsFromLS = sessionStorage.getItem('statsFavourites')

if (favsFromLS) startingFavs = JSON.parse(favsFromLS)

const initialState: t.StatsState = {
  favourites: startingFavs as StatsFavourite[]
}

type StatsReducer = Reducer<t.StatsState, t.StatsActionTypes>

const stats: StatsReducer = (state = initialState, action: t.StatsActionTypes): t.StatsState => {
  switch (action.type) {
    case t.SET_STATS_FAVOURITES:
      return {
        favourites: action.payload
      }
    default:
      return state
  }
}

export default stats
