import {setLoginMethods} from '../../store/actions'
import {getTheme} from '../../store/selectors'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {DefaultTheme} from 'styled-components'
import {darkTheme, lightTheme} from '../../themes'
import {createTheme} from '@mui/material'
import axios from 'axios'
import {coreUrl} from '../../configuration'
import {logger} from '../../helpers'
import {history} from '../../constants'

const UnauthenticatedAppStateManager: React.FC<{setTheme: (theme: DefaultTheme) => void}> = ({setTheme}): null => {
  const themeName = useSelector(getTheme)

  const dispatch = useDispatch()

  // login methods e preferenza delayClientMount
  useEffect(() => {
    axios
      .get(`${coreUrl}/authentication/methods`)
      .then(({ data }) => {
        logger.debug('Got login methods from core')
        dispatch(setLoginMethods(data.payload))
      })
      .catch((e) => {
        if (e.response.status === 301 && e.response.data) {
          logger.warn('Login methods needs to be initialized')
          history.push('/wizard', { token: e.response.data.message.split(': ')[1] })
          logger.debug(e.response.data)
        } else {
          logger.error(e)
        }
      })

    const saveLastPath = () => {
      sessionStorage.setItem('pathBeforeUnload', window.location.pathname)
    }
    window.addEventListener('beforeunload', saveLastPath)

    // Se viene premuto da dentro il client il tasto "chiudi" viene da qui intercettato e interpretato
    // come preferenza nel non aprirlo in automatico al login
    return () => {
      window.removeEventListener('beforeunload', saveLastPath)
    }
  }, [dispatch])

  // tema
  useEffect(() => {
    let newTheme
    if (themeName === 'light') newTheme = createTheme(lightTheme)
    else newTheme = createTheme(darkTheme)
    setTheme(newTheme)
    window.occlient?.setTheme(themeName)
  }, [themeName, setTheme])

  return null
}

export default UnauthenticatedAppStateManager
