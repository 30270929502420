import {useEffect, useState} from "react"
import {Chat, ChatEvent, MonitoringChat} from "../api/types"
import { logger } from "../helpers"
import SocketManager from "../api/socket"
import {Channels, useSocket} from "./index"

const chatsChannel: Channels[] = ['chats']

const useMonitoringChats = () => {
  const [chats, setChats] = useState<Map<string, MonitoringChat>>(new Map())

  useSocket(chatsChannel, true)

  useEffect(() => {
    const chatsListCallback = (payload: Chat[]) => {
      const chatsMap = new Map()

      payload?.forEach((chat) => {
        if (chat.id && !chat.closed) {
          chatsMap.set(chat.id, {
            id: chat.id,
            customer: chat.departments.length && chat.departments[0].service?.customer.id,
            services: chat.departments.map((department) => department.service?.id!).filter((serviceId) => !!serviceId),
            departments: [chat.departments.map((department) => department.id!).pop()] || [],
            operator: chat.operator?.id || null,
            guest: chat.guest || null,
            startDate: chat.startDate
          })
        }
      })

      setChats(chatsMap)
    }

    const chatsUpdateCallback = (payload: ChatEvent)=> {
      switch (payload.type) {
        case 'department-enter':
          setChats((prevState) => {
            const targetChat: MonitoringChat = {
              id: payload.chat,
              departments: [payload.department?.id!],
              services: [payload.service!],
              customer: payload.customer,
              operator: null,
              guest: payload.guest,
              startDate: payload.startDate
            }
            prevState.set(payload.chat, targetChat)
            return new Map(prevState)
          })
          break
        case 'chat-transfer':
          setChats(prevState => {
            // Considero l'evento solo se si tratta di un trasferimento a un dipartimento
            if (!payload.department?.id) return prevState
            const targetChat = prevState.get(payload.chat)
            if(!targetChat) {
              logger.error(`Could not find chat ${payload.chat} for event ${payload.type}`)
              return prevState
            }
            targetChat.departments = [payload.department.id]
            prevState.set(payload.chat, targetChat)
            return new Map(prevState)
          })
          break
        case 'booking-accepted':
          setChats(prevState => {
            const targetChat = prevState.get(payload.chat)
            if(!targetChat) {
              logger.error(`Could not find chat ${payload.chat} for event ${payload.type}`)
              return prevState
            }
            targetChat.operator = payload.user?.id!
            prevState.set(payload.chat, targetChat)
            return new Map(prevState)
          })
          break
        case 'chat-end':
          setChats(prevState => {
            prevState.delete(payload.chat)
            return new Map(prevState)
          })
          break
        default:
          return
      }
    }

    SocketManager.on('chats-list', chatsListCallback)
    SocketManager.on('chats-update', chatsUpdateCallback)

    return () => {
      SocketManager.removeAllListeners(`chats-list`)
      SocketManager.removeAllListeners(`chats-update`)
    }
  }, [])

  return chats
}

export default useMonitoringChats
