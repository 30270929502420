import {DoubleArrowRounded, KeyboardArrowLeftRounded, KeyboardArrowRightRounded} from '@mui/icons-material'
import {Box, Button, Grid, Tooltip} from '@mui/material'
import {SxProps} from '@mui/system'
import React, {PropsWithChildren, useCallback, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {Hint} from './Hint'
import {useSelector} from "react-redux";
import {getTranslation} from "../../store/selectors";

/**
 * Componente di material riadattato alle necessità della dashboard.
 * REGOLE:
 * - LEFT per noi è il valore da assegnare, quindi onchange restituisce questo
 * - RIGHT: possibili valori da assegnare, filtrati quando vengono selezionati
 * @param props { Object }
 * @param props.left colleziona da visualizzare a sinistra, rappresenta i valori attualmente validi nella form
 * @param props.right collezione da visualizzare a destra, rappresenta gli elementi disponibili all'inserimento
 * @param props.renderLeft funzione che renderizza la card a sinistra
 * @param props.renderRight funzione che renderizza la card a destra
 * @param props.idField nome del campo da usare come chiave
 * @param props.onChange funzione chiamata quando vengono spediti elementi da destra e sinistra o da sinistra a destra
 */
export function TransferList<TItem>(props: PropsWithChildren<Props<TItem>>) {
  const {
    left,
    right,
    idField,
    onChange,
    renderLeft,
    renderRight,
    buttons = {...defaultButtons},
    buttonsTooltips,
    disabled,
    hasPermission,
    hint,
    error,
    sx = {}
  } = props

  type IdType = TItem[typeof idField]

  const translation = useSelector(getTranslation)

  const getIds = useCallback(
    (items: TItem[]): IdType[] => {
      return items.map((i) => i[idField])
    },
    [idField]
  )

  const [checked, setChecked] = React.useState<IdType[]>([])
  const [leftIds, setLeftIds] = React.useState<IdType[]>([])
  const [rightIds, setRightIds] = React.useState<IdType[]>([])
  const [leftView, setLeftView] = React.useState<IdType[]>([])
  const [rightView, setRightView] = React.useState<IdType[]>([])

  const leftChecked = intersection(checked, leftIds)
  const rightChecked = intersection(checked, rightIds)

  useEffect(() => {
    const newLeftIds = getIds(left)
    setLeftIds(newLeftIds)
    setLeftView(newLeftIds)
  }, [left, getIds])

  useEffect(() => {
    const newRightIds = not(getIds(right), leftIds)
    setRightIds(newRightIds)
    setRightView(newRightIds)
  }, [right, leftIds, getIds])

  const isEnabled = useMemo((): boolean => {
    // inverte disabled se passato come prop
    const enabled = typeof disabled === 'boolean' ? !disabled : true
    // se non viene passato hasPermission mi affido alle altre prop
    if (hasPermission === undefined) {
      return enabled
    } else {
      // altrimenti deve anche avere il permesso oltre a dipendere dalle altre props
      return hasPermission && enabled
    }
  }, [hasPermission, disabled])

  const handleToggle = (value: IdType) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    const newRightIds = rightIds.concat(leftIds)
    setRightIds(newRightIds)
    setRightView(newRightIds)
    setLeftIds([])
    setLeftView([])
    // rimuove tutti gli elementi, quindi come removed vengono passati tutti gli item a sinistra prima della modifica
    onChange([], [], left)
  }

  /** sposta i selezionati da sinistra a destra */
  const handleCheckedRight = () => {
    const newRightIds = rightIds.concat(leftChecked)
    setRightIds(newRightIds)
    setRightView(newRightIds)

    const newLeftIds = not(leftIds, leftChecked)
    const newLeft = newLeftIds.map((l) => getItemById(left, l))
    // qui non si aggiunge nulla si rimuovono tutti i selezionati
    onChange(
      newLeft,
      [],
      leftChecked.map((l) => getItemById(left, l))
    )

    setLeftIds(newLeftIds)
    setLeftView(newLeftIds)
    setChecked(not(checked, leftChecked))
  }

  /** sposta i selezionati da destra a sinistra */
  const handleCheckedLeft = () => {
    const newLeftIds = leftIds.concat(rightChecked)
    setLeftIds(newLeftIds)
    setLeftView(union(newLeftIds, leftView))

    const rightCheckedItems = rightChecked.map((r) => getItemById(right, r))
    const newLeft = left.concat(rightCheckedItems)
    // qui non si rimuove nulla, si aggiungono tutti i selezionati di destra
    onChange(newLeft, rightCheckedItems, [])

    const newRightIds = not(rightIds, rightChecked)
    setRightIds(newRightIds)
    setRightView(newRightIds)
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    const newLeftIds = leftIds.concat(rightIds)
    setLeftIds(newLeftIds)
    setLeftView(union(newLeftIds, leftView))
    setRightIds([])
    setRightView([])

    const newLeft = left.concat(rightIds.map((r) => getItemById(right, r)))
    // aggiunge tutti gli elementi, quindi come added vengono passati tutti gli item a destra prima della modifica
    onChange(newLeft, right, [])
  }

  const getItemById = useCallback(
    (items: TItem[], id: IdType): TItem => {
      return items.find((i) => i[idField] === id)!
    },
    [idField]
  )

  const removeSingleItem = (id: IdType) => {
    const newRightIds = rightIds.concat(id)
    setRightIds(newRightIds)
    setRightView(newRightIds)

    const newLeftIds = leftIds.filter((lid) => lid !== id)
    const newLeft = newLeftIds.map((l) => getItemById(left, l))
    onChange(newLeft, [], [getItemById(left, id)])

    setLeftIds(newLeftIds)
    setLeftView(newLeftIds)
    setChecked(not(checked, leftChecked))
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
      sx={{width: '100%', height: '100%', ...sx}}>
      <NoPaddingGrid item sx={{width: '40%', height: '100%'}}>
        {renderLeft({
          itemdIds: leftIds,
          itemIdsView: leftView,
          setItemIdsView: setLeftView,
          items: left,
          checked,
          getItemById,
          handleToggle,
          removeSingleItem,
          disabled: !isEnabled
        })}
      </NoPaddingGrid>
      <NoPaddingGrid item sx={{width: '10%', height: '100%'}}>
        <NoPaddingGrid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{position: 'relative', height: '100%'}}>
          {buttons.allRight && (
            <Tooltip title={buttonsTooltips?.allRight || translation.transferList.removeAll} placement={'right'}>
              <span>
                <Button
                  sx={{my: 0.5, minWidth: 0}}
                  variant="outlined"
                  size="small"
                  onClick={handleAllRight}
                  disabled={leftIds.length === 0 || !isEnabled}
                  aria-label="move all right">
                <DoubleArrowRounded/>
              </Button>
              </span>
            </Tooltip>
          )}
          {buttons.right && (
            <Tooltip title={buttonsTooltips?.allRight || translation.transferList.remove} placement={'right'}>
              <span>
                <Button
                sx={{my: 0.5, minWidth: 0}}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0 || !isEnabled}
                aria-label="move selected right">
                <KeyboardArrowRightRounded/>
              </Button>
              </span>
            </Tooltip>
          )}
          {buttons.left && (
            <Tooltip title={buttonsTooltips?.allRight || translation.transferList.assign} placement={'right'}>
              <span>
                <Button
                  sx={{my: 0.5, minWidth: 0}}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0 || !isEnabled}
                  aria-label="move selected left">
                <KeyboardArrowLeftRounded/>
              </Button>
              </span>
            </Tooltip>
          )}
          {buttons.allLeft && (
            <Tooltip title={buttonsTooltips?.allRight || translation.transferList.assignAll} placement={'right'}>
              <span>
                <Button
                  sx={{my: 0.5, minWidth: 0}}
                  variant="outlined"
                  size="small"
                  onClick={handleAllLeft}
                  disabled={rightIds.length === 0 || !isEnabled}
                  aria-label="move all left">
                <DoubleArrowRounded sx={{transform: 'scaleX(-1)'}}/>
              </Button>
              </span>
            </Tooltip>
          )}
          {hint && <Hint content={hint} sx={{position: 'absolute', bottom: 2, left: -10}}/>}
        </NoPaddingGrid>
      </NoPaddingGrid>
      <NoPaddingGrid item sx={{width: '40%', height: '100%'}}>
        {renderRight({
          itemdIds: rightIds,
          itemIdsView: rightView,
          setItemIdsView: setRightView,
          items: right,
          checked,
          getItemById,
          handleToggle,
          removeSingleItem,
          disabled: !isEnabled
        })}
      </NoPaddingGrid>
      {error && (
        <Box sx={{width: '100%', p: 1, ml: 1}}>
          <Error>{error}</Error>
        </Box>
      )}
    </Grid>
  )
}

//region Types

export type TransferListChangeFunction<TItem> = (items: TItem[], added: TItem[], removed: TItem[]) => void

type RenderProps<TItem> = {
  itemdIds: any[]
  itemIdsView: any[]
  setItemIdsView: (v: any[]) => void
  items: TItem[]
  checked: any[]
  getItemById: (items: TItem[], value: any) => TItem
  handleToggle: (v: any) => any
  removeSingleItem: (id: any) => void
  disabled: boolean
}

type Props<TItem> = {
  left: TItem[]
  right: TItem[]
  renderLeft: (props: RenderProps<TItem>) => React.ReactElement
  renderRight: (props: RenderProps<TItem>) => React.ReactElement
  onChange: TransferListChangeFunction<TItem>
  idField: keyof TItem
  disabled?: boolean
  hasPermission?: boolean
  hint?: string
  error?: string
  buttons?: {
    allRight?: boolean
    right?: boolean
    left?: boolean
    allLeft?: boolean
  }
  buttonsTooltips?: {
    allRight?: string
    right?: string
    left?: string
    allLeft?: string
  }
  sx?: SxProps
}

//endregion

//region Helpers

function not<IdType>(a: IdType[], b: IdType[]) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection<IdType>(a: IdType[], b: IdType[]) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union<IdType>(a: IdType[], b: IdType[]) {
  return [...a, ...not(b, a)]
}

const defaultButtons = {
  allRight: true,
  right: true,
  left: true,
  allLeft: true
}

//endregion

//region Style

const Error = styled.div`
  grid-area: error;
  color: ${(props) => props.theme.palette.error.main};
  font-size: 0.8rem;
  margin-left: ${(props) => props.theme.spacing(1)};
`

const NoPaddingGrid = styled(Grid)`
  padding-top: 0 !important;
`

//endregion
