import {getActions, getUser} from '../store/selectors'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ActionName } from '../api/types'

function usePermissions(entity?: string): HookReturnObject {
  const actions = useSelector(getActions)
  const user = useSelector(getUser)

  /** ritorna tutti i permessi dell'utente sull'entity corrente */
  const userCan: AllPermissions = useMemo(() => {
    const permissions: AllPermissions = { view: false, get: false, create: false, update: false, delete: false, retrieve: false, spy: false }
    if (!entity) return permissions
    actions.forEach((entities, action) => {
      permissions[action] = entities.has(entity)
    })
    return permissions
  }, [actions, entity])

  /** funzione per recuperare i permessi su una qualsiasi entity */
  const hasPermissionTo: HasPermissionToFunction = useCallback(
    (action, entity) => {
      if(user?.roles.find((role) => role.isAdmin)) return true
      const entities = actions.get(action)
      if (!entities || !entities.size) return false
      return entities.has(entity)
    },
    [actions, user]
  )

  return { userCan, hasPermissionTo }
}

export default usePermissions

type HookReturnObject = {
  userCan: AllPermissions
  hasPermissionTo: HasPermissionToFunction
}

type AllPermissions = {
  [key in ActionName]: boolean
}

export type HasPermissionToFunction = (action: ActionName, entity: string) => boolean
