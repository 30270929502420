import {DarkModeOutlined, LogoutOutlined, TranslateOutlined, WbSunnyOutlined} from '@mui/icons-material'
import {Menu} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import {handleLogout, setTheme} from '../../../store/actions'
import {getActions, getLanguage, getOCClientRef} from '../../../store/selectors'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {getTranslation} from '../../../helpers/getTranslation'
import {ReactComponent as OCLogo} from '../../../images/Oc.svg'
import {ReactComponent as OCLogoDark} from '../../../images/OC_white.svg'
import {setTranslation} from '../../../store/language/actions'
import MenuItem from '../../common/MenuItem'
import {useRest} from "../../../hooks"
import {logger} from '../../../helpers'
import {getConfigAndOpenClient} from "../../../api/helpers";

const DropdownMenu: React.FC<Props> = ({anchorEl, setAnchorEl}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const language = useSelector(getLanguage)
  const actions = useSelector(getActions)
  const menuOpened: boolean = Boolean(anchorEl)
  const views = actions.get('view')
  const clientOnly = !views || views.size === 0
  const ocClientRef = useSelector(getOCClientRef)

  const {post: changeLanguage} = useRest('change-my-language')
  const {post: changeTheme} = useRest('change-my-theme')

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clientItem = {
    label: 'occlient',
    src: theme.palette.mode === 'light' ? <OCLogo/> : <OCLogoDark/>,
    alt: 'occlient',
    onClick: async () => {
      await getConfigAndOpenClient(ocClientRef)
    }
  }
  const userDropdownMenuItems = [
    {
      label: language === 'it_IT' ? 'english' : 'italiano',
      img: TranslateOutlined,
      alt: 'changeLanguage',
      onClick: () => {
        const newLanguage = language === 'it_IT' ? 'en_EN' : 'it_IT'
        getTranslation(newLanguage).then((res) => {
          dispatch(setTranslation(newLanguage, res))
          changeLanguage( {language: newLanguage}, {withoutNotification: true}).catch((e)=> logger.error(e))
        })
      }
    },
    {
      label: theme.palette.mode === 'dark' ? 'lightMode' : 'darkMode',
      img: theme.palette.mode === 'dark' ? WbSunnyOutlined : DarkModeOutlined,
      alt: 'theme',
      onClick: () => {
        changeTheme({theme: theme.palette.mode === 'light' ? 'dark' : 'light'}, {withoutNotification: true}).then(()=>{
          dispatch(setTheme(theme.palette.mode === 'light' ? 'dark' : 'light'))
        }).catch((e)=> logger.error(e))
      }
    },
    {
      label: 'logout',
      img: LogoutOutlined,
      alt: 'logout',
      onClick: () => {
        ocClientRef?.close()
        dispatch(handleLogout() as any)
        navigate('/login')
      }
    }
  ]

  return (
    <Menu
      anchorEl={anchorEl}
      open={menuOpened}
      onClick={handleClose}
      onClose={handleClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5
          }
        }
      }}
      transformOrigin={{horizontal: 'right', vertical: 'top'}}
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
      {(clientOnly ? userDropdownMenuItems : [clientItem, ...userDropdownMenuItems]).map((item) => {
        return <MenuItem key={item.label} item={item} depth={0}/>
      })}
    </Menu>
  )
}

// region types
type Props = {
  anchorEl: HTMLElement | null
  setAnchorEl: (anchorEl: HTMLElement | null) => void
}
// endregion

export default DropdownMenu
