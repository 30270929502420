import { Table, TableBody, TableCell, TableContainer, TableHead, TableProps, TableRow } from '@mui/material'
import { SxProps } from '@mui/system'
import React from 'react'

export type Column<RowData> = {
  name: string
  header: string
  cell?: (row: RowData, index: number) => React.ReactNode
  sx?: SxProps
}
type Props<RowData> = TableProps & {
  data: RowData[]
  columns: Column<RowData>[]
  rowHeight?: number
  headerMaxWidth?: number
  lastRow?: React.ReactNode
  defaultValue?: string
}
/**
 * Componente per la visualizzazzione di una tabella
 */
export function SimpleTable<RowData = any>(props: Props<RowData>) {
  const { data, columns, rowHeight, headerMaxWidth, lastRow = null, defaultValue, ...tableProps } = props

  return (
    <TableContainer sx={{ maxHeight: '100%' }}>
      <Table stickyHeader padding="none" {...tableProps}>
        <TableHead>
          <TableRow>
            {columns.map(({ header, name, sx }) => (

              <TableCell
                key={name}
                sx={{
                  fontSize: 'inherit',
                  bgcolor: 'background.paper',
                  ...(headerMaxWidth
                    ? { maxWidth: headerMaxWidth, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
                    : {}),
                  ...sx
                }}>
                {header}
              </TableCell>

            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={`row-${index}`}
              sx={{ height: rowHeight || 'fit-content', '&:hover': { bgcolor: 'action.hover' } }}>
              {columns.map(({ cell, name }) => (
                <TableCell variant={'body'} key={name} sx={{ fontSize: 'inherit' }}>
                  {(cell ? cell(row, index) : row[name as keyof RowData] ?? defaultValue ?? '-') as string}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {lastRow}
        </TableBody>
      </Table>
    </TableContainer >
  )
}
