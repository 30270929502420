import {Box, Switch as MuiSwitch, SwitchProps, Typography} from '@mui/material'
import React, {PropsWithChildren, useMemo} from 'react'
import {useController, UseControllerProps, useFormState} from 'react-hook-form'
import styled from 'styled-components'
import {FieldValues} from "react-hook-form/dist/types";
import {Hint} from "./Hint";
import {ErrorMessage} from "@hookform/error-message";

type Position = 'left' | 'right'

type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> &
  SwitchProps & {
  hint?: string
  label?: string
  hasPermission?: boolean
  labelPosition?: Position
  leaveSpaceForHint?: boolean
  leaveSpaceForError?: boolean
  containerStyle?: React.CSSProperties
}

/** Wrapper per gestire input unendo HookForm e MUI */
export function Switch<TFieldValues extends FieldValues>(props: PropsWithChildren<Props<TFieldValues>>) {
  const {
    control,
    name,
    rules,
    sx,
    hint,
    label = '',
    hasPermission,
    disabled,
    labelPosition = 'left',
    leaveSpaceForHint = true,
    leaveSpaceForError = true,
    containerStyle,
    ...rest
  } = props
  const {field} = useController({control, name, rules})
  const { errors } = useFormState<TFieldValues>({ control, name })

  const isEnabled = useMemo((): boolean => {
    // inverte disabled se passato come prop
    const enabled = typeof disabled === 'boolean' ? !disabled : true
    // se non viene passato hasPermission mi affido alle altre prop
    if (hasPermission === undefined) {
      return enabled
    } else {
      // altrimenti deve anche avere il permesso oltre a dipendere dalle altre props
      return hasPermission && enabled
    }
  }, [hasPermission, disabled])

  const getMessage = (message = '') => {
    // @ts-ignore
    if (!errors[name]) return message
    // @ts-ignore
    if (!errors[name].type) return message
    // @ts-ignore
    if (!translation.errors[errors[name].type]) {
      // @ts-ignore
      return message
    }

    // @ts-ignore
    return translation.errors[errors[name].type]
  }

  return (
    <Container style={containerStyle} leaveSpaceForHint={leaveSpaceForHint} leaveSpaceForError={leaveSpaceForError}>
      <Box sx={{display: 'grid', gridTemplateColumns: 'auto 1fr auto', width: '100%', ...sx}}>
        {labelPosition === 'left' && <Typography variant='body1' style={{placeSelf: 'center start'}}>
          {label}
        </Typography>}
        <MuiSwitch
          onChange={(ev, value) => field.onChange(value)}
          {...rest}
          checked={!!field.value}
          disabled={!isEnabled}
          sx={{placeSelf: `center ${labelPosition === 'left' ? 'end' : 'start'}`}}
        />
        {labelPosition === 'right' && <Typography variant='body1' style={{placeSelf: 'center end'}}>
          {label}
        </Typography>}
      </Box>
      {hint && (
        <div style={{placeSelf: 'center', marginTop: 5}}>
          <Hint title={label || ''} content={hint}/>
        </div>
      )}
      <ErrorMessage errors={errors} name={name as any} render={({ message }) => <Error>{getMessage(message)}</Error>} />
    </Container>
  )
}

//region Style

const Container = styled.div<{ leaveSpaceForHint: boolean; leaveSpaceForError: boolean }>`
  display: grid;
  grid-template-areas: 'input hint' 'error error';
  grid-template-columns: 1fr ${(props) => (props.leaveSpaceForHint ? '30px' : 'auto')};
  grid-template-rows: min-content ${(props) => (props.leaveSpaceForError ? '20px' : 'auto')};
`

const Error = styled.div`
  grid-area: error;
  color: ${(props) => props.theme.palette.error.main};
  font-size: 0.8rem;
  margin-left: ${(props) => props.theme.spacing(1)};
`
//endregion
