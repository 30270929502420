import React, {useCallback, useMemo} from 'react'
import {useMatch, useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import {useSelector} from "react-redux";
import {getSelectedPbx, getTranslation} from "../../store/selectors";
import {EntityType, relationsParser, simplifiedEntityBlueprint} from "../../helpers/relationsParser";
import {Accordion, AccordionDetails, AccordionSummary, Chip, Tooltip, Typography} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";


type Props = {
  entity: any
  entityType: string
}

export const Relations: React.FC<Props> = (props) => {
  const {entityType, entity} = props
  const entityTypeChunks = entityType.split('-')
  entityTypeChunks.shift()
  const type = entityTypeChunks.join('-')
  const url = `/configuration/:pbxId/${type === 'inbound-routes' ? type : routes.get(type)}/:id`
  const match = useMatch(url)
  const pbxId = match?.params.pbxId
  const navigate = useNavigate()
  const translation = useSelector(getTranslation)
  const selectedPbx = useSelector(getSelectedPbx)

  const entityRelations: {mapDest: any, mapRel: any, nameMap: Map<string, { name: string , state: string}> } = useMemo( ()=> {
    if(!entityType) return {mapDest: {}, mapRel: {}, nameMap: new Map([])}

    const entityTypeChunks = entityType.split('-')
    entityTypeChunks.shift()
    let type = entityTypeChunks.join('-')
    if(type === 'inbound-routes') type = 'inboundRoutes'
    if(type === 'voips') type = 'extensions'

    const fields = type && simplifiedEntityBlueprint[type as EntityType]
    const idField = fields && fields[0]

    if ( !type || (entityType && !entity[idField] )) return {mapDest: {}, mapRel: {}, nameMap: new Map([])}
    if (Object.keys(entity).length === 0) return {mapDest: {}, mapRel: {}, nameMap: new Map([])}
    const parsed = relationsParser(entity, type as EntityType)

    return {mapDest: [...new Set(parsed.destinations)], mapRel: [...new Set(parsed.relations)], nameMap: parsed.nameMap }
  },[ entity, entityType ])

  const parseRelationsForNavigation = useCallback((relation: string)=> {
    const [type, id] = relation.split(':')
    const entityRoute = routes.get(type)
    return `/configuration/${pbxId}/${entityRoute}/${entityRoute && entityRoute.includes('route') ? `${id}@${selectedPbx}` : id}`
  },[selectedPbx, pbxId])

  return (
  <Container>
    <div style={{width:'50%'}} ><Accordion sx={{'& .Mui-expanded':{ margin: '0 !important'},lineHeight: '1rem', padding: '0', height: 'auto'}}>
      <AccordionSummary
        disabled={entityRelations.mapRel.length === 0}
        sx={{boxShadow: 1, minHeight:'30px', maxHeight: '50px'}}
        expandIcon={ <KeyboardArrowDown/>}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography>{translation.relationsTooltip.previousEntities}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{paddingX: '16px', paddingY: '7px', margin: 0}}>
        <ChipsContainer>
          {entityRelations?.mapRel && entityRelations.mapRel.map((rel: string) =>
            (<Tooltip title={translation.relationsTooltip[rel.split(':')[0]] } arrow placement={'top'}>
              <Chip sx={{marginRight: '5px'}} onClick={() => navigate(parseRelationsForNavigation(rel))}
                   label={entityRelations.nameMap.get(rel)?.name }/>
            </Tooltip>)
          )}
        </ChipsContainer>
      </AccordionDetails>
    </Accordion></div>
    <div style={{width:'50%'}}><Accordion  sx={{'& .Mui-expanded':{ margin: '0 !important'},lineHeight: '1rem', padding: '0', height: 'auto'}}>
      <AccordionSummary
        disabled={entityRelations.mapDest.length === 0}
        sx={{minHeight:'30px', boxShadow: 1, maxHeight: '50px'}}
        expandIcon={<KeyboardArrowDown/>}
        aria-controls="panel2a-content"
        id="panel2a-header">
        <Typography>{translation.relationsTooltip.nextEntities}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{paddingX: '16px', paddingY: '7px'}}>
        <ChipsContainer>
          {entityRelations?.mapDest && entityRelations.mapDest.map((dest: string) =>
            (<Tooltip title={translation.relationsTooltip[dest.split(':')[0]]} arrow placement={'top'}>
              <Chip sx={{marginRight: '5px', bgc: entityRelations.nameMap.get(dest)?.state === 'removed' ? 'red' : ( entityRelations.nameMap.get(dest)?.state === 'added' ? 'green': '' ) }} onClick={() => navigate(parseRelationsForNavigation(dest))}
                   label={ entityRelations.nameMap.get(dest)?.name }/>
            </Tooltip>)
          )}
        </ChipsContainer>
      </AccordionDetails>
    </Accordion></div>
  </Container>)

}

//region Style

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: auto;
`

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  row-gap: 5px;
`


//endregion


const routes = new Map([
  ['extensions', 'extensions'],
  ['voips', 'extensions'],
  ['ring-groups', 'ring-groups'],
  ['inboundRoutes', 'inbound-routes'],
  ['timegroups', 'time-groups'],
  ['timeconditions', 'time-conditions'],
  ['ivrs','ivrs'],
  ['queues','queues'],
  ['recordings','recordings'],
  ['musics-on-hold', 'musics-on-hold'],
  ['announcements', 'announcements']
])

