import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface WithExtraColumn {
  style?: React.CSSProperties
}

type Options = {
  mainPageMinWidth?: number
}
function withExtraColumn<T extends WithExtraColumn = WithExtraColumn>(
  WrappedComponent: React.ComponentType<T>,
  Component: React.ComponentType<any>,
  opts: Options = {}
) {
  return (props: T) => {
    const [width, setWidth] = useState(window.innerWidth)

    const { mainPageMinWidth = 1280 } = opts

    useEffect(() => {
      const resizeCallback = () => {
        setWidth(window.innerWidth)
      }
      window.addEventListener('resize', resizeCallback)
      return () => { window.removeEventListener('resize', resizeCallback) }
    }, [])

    return (
      <Container columns={width >= mainPageMinWidth ? 2 : 1}>
        <WrappedComponent {...(props as T)} />
        {width >= mainPageMinWidth && <Component />}
      </Container>
    )
  }
}

export default withExtraColumn

//region style
const Container = styled.div<{ columns: number}>`
  display: flex;
  
  overflow: hidden;
  height: 100%;
  & > *:first-child{
    flex-grow: 1 !important;
  }
  & > *:nth-child(2){
    flex-shrink: 0 !important;
  }
`

//endregion
