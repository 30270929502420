import { expandedDrawerWidth, IMenuItem } from '../../../constants'
import fullLogoImage from '../../../images/OC_full.svg'
import fullLogoImageDark from '../../../images/OC_full_white.svg'
import { Drawer, List } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import styled from 'styled-components'
import MenuItem from '../../common/MenuItem'

interface NavbarProps {
  minimize: boolean
  menuItems: IMenuItem[]
}

/**
 * Componente menu laterale
 * @param minimize {boolean} indica se il menu laterale è minimizzato
 * @param setMinimize {(menu: boolean) => void} callback per la minimizzazzione del menu
 * @constructor
 */
const Navbar: React.FC<NavbarProps> = ({ minimize, menuItems }) => {
  const [openedMenuItems, setOpenedMenuItems] = useState<string[]>([])

  //per il controllo di quale voce del menu mostrare in base al ruolo dello staff
  const theme = useTheme()

  return (
    <Drawer
      sx={{
        width: expandedDrawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: expandedDrawerWidth,
          boxSizing: 'border-box'
        },
        pointerEvents: minimize ? 'none' : 'all'
      }}
      anchor={'left'}
      open={!minimize}
      variant="persistent">
      <IconContainer>
        <img
          src={theme.palette.mode === 'dark' ? fullLogoImageDark : fullLogoImage}
          style={{ width: '100%', height: 'inherit' }}
          alt={'OC_logo'}
        />
      </IconContainer>
      <ScrollBar>
        <List>
          {menuItems.map((i, index) => {
            return (
              <MenuItem
                key={index}
                item={i}
                depth={0}
                openedMenuItems={openedMenuItems}
                setOpenedMenuItems={setOpenedMenuItems}
              />
            )
          })}
        </List>
      </ScrollBar>
    </Drawer>
  )
}

export default Navbar

//region style

const IconContainer = styled.div`
  height: var(--header-height);
  padding: 0 20px;
  width: 100%;
  display: grid;
  justify-content: center;
`

//endregion
