import { IMenuItem } from '../../constants'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, ListItemIcon, ListItemText, MenuItem as MuiMenuItem, SvgIcon, Typography } from '@mui/material'
import { getMenuTranslation, getSelectedPbx } from '../../store/selectors'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { usePrevious } from '../../hooks'
import logger from '../../helpers/logger'

interface MenuItemProps {
  item: IMenuItem
  depth: number
  submenu?: boolean
  openedMenuItems?: string[]
  setOpenedMenuItems?: (itemIds: string[]) => void
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { item, depth, openedMenuItems, setOpenedMenuItems } = props

  const [selected, setSelected] = useState<boolean>(false)

  const menuTranslation = useSelector(getMenuTranslation)
  const selectedPbx = useSelector(getSelectedPbx)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const previousOpenedMenuItems = usePrevious(openedMenuItems)

  const color = useMemo(() => item.type === 'title' ? 'primary.dark' : 'text.primary', [item])

  const goToPage = useCallback((link: string) => {
    if (!selectedPbx) {
      logger.warn('SelectedPbx is not yet available, skipping navigation')
      return
    }
    const parsedLink = link.replace(/:pbxId/g, String(selectedPbx))
    navigate(parsedLink)
  }, [selectedPbx, navigate])
  const handleClick = useCallback(() => {
    // Azione custom
    item.onClick && item.onClick()
    // naviga verso url
    if (item.pathname) goToPage(item.pathname)
    // apre il menu
    else if (openedMenuItems && setOpenedMenuItems) {
      if (openedMenuItems.includes(`${item.label}-${depth}`)) {
        setOpenedMenuItems(openedMenuItems.filter((mi) => mi !== `${item.label}-${depth}`))
      } else {
        // Chiudo prima tutte le voci di menu che sono allo stesso livello di quella che voglio aprire
        const filteredOpenedMenuItems = openedMenuItems.filter((mi) => String(depth) !== mi.split('-')[1])
        // Apro la voce di menu
        setOpenedMenuItems(filteredOpenedMenuItems.concat(`${item.label}-${depth}`))
      }
    }
  }, [item, openedMenuItems, setOpenedMenuItems, depth, goToPage])

  useEffect(() => {
    // Controlla se il path corrente ed il path del menuitem sono uguali, a meno delle variabili, per capire qual è l'elemento del menu selezionato
    if (item.pathname) {
      let samePathName = true

      const pathNameChunks = pathname.split('/')
      const itemPathNameChunks = item.pathname.split('/')
      const lengthDiff = pathNameChunks.length - itemPathNameChunks.length
      // ciclo sempre sull'array più corto per controllare il path corrente
      for (let i = 0; i < (lengthDiff > 0 ? itemPathNameChunks.length : pathNameChunks.length); i++) {
        const pathNameChunk = pathNameChunks[i]
        const itemPathNameChunk = itemPathNameChunks[i]
        if (itemPathNameChunk[0] !== ':') {
          if (pathNameChunk !== itemPathNameChunk) {
            samePathName = false
            break
          }
        }
      }
      setSelected(samePathName)
    }

    // Apre la sottosezione del menu corrispondente alla pagina corrente
    if (openedMenuItems && setOpenedMenuItems && previousOpenedMenuItems?.length === 0 && openedMenuItems.length === 0) {
      pathname.includes(item.label) && setOpenedMenuItems(openedMenuItems.concat(`${item.label}-${depth}`))
    }
  }, [pathname, item, depth, setOpenedMenuItems, openedMenuItems, previousOpenedMenuItems])

  return (
    <>
      {item.subMenu && item.subMenu.length === 0 ? null : (
        <MuiMenuItem
          onClick={handleClick}
          disableRipple
          sx={{ paddingLeft: `${0.5 + depth}rem`, bgcolor: selected ? 'action.hover' : 'inherit', minWidth: '3rem' }}>
          {item.type !== 'title' && (
            <ListItemIcon style={{ minWidth: '3rem', paddingLeft: '.7rem' }}>
              {item.img ? <SvgIcon component={item.img} fontSize={'small'} /> : <SvgIcon fontSize={'small'}>{item.src}</SvgIcon>}
            </ListItemIcon>
          )}
          <ListItemText>
            <Typography variant={'subtitle2'} sx={{ width: '100%', color, fontSize: '0.8rem', paddingTop: '0.2rem', textOverflow: 'ellipsis', overflow: 'hidden', paddingLeft: 'none' }}>
              {item.type === 'title' ? menuTranslation[item.label].toUpperCase() : menuTranslation[item.label]}
            </Typography>
          </ListItemText>
          {item.subMenu?.length && (
            <SvgIcon sx={{ color }}>
              {openedMenuItems && openedMenuItems.includes(`${item.label}-${depth}`) ? <ExpandLess /> : <ExpandMore />}
            </SvgIcon>
          )}
        </MuiMenuItem >
      )}
      {/*Sottomenu ricorsivi*/}
      {
        item.subMenu &&
        item.subMenu.map((s, index) => {
          return (
            <Collapse
              in={openedMenuItems && openedMenuItems.includes(`${item.label}-${depth}`)}
              key={`${item.label}-${index}`}>
              <MenuItem
                item={s}
                depth={depth + 1}
                openedMenuItems={openedMenuItems}
                setOpenedMenuItems={setOpenedMenuItems}
              />
            </Collapse>
          )
        })
      }
    </>
  )
}
export default MenuItem
