import { DateTime } from 'luxon'
import React from 'react'
import useElapsedTime from '../../hooks/useElapsedTime'

type Props = {
  reference: DateTime | string | number | null
}
export const Counter: React.FC<Props> = ({ reference }) => {
  const { elapsedTimeString } = useElapsedTime(reference)
  return <span>{elapsedTimeString}</span>
}
