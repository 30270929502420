import styled from 'styled-components'

type Props = {
  justifyContent?: string
}
export const OneItemRow = styled.div<Props>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-top: ${({theme}) => theme.spacing(1)};
  & > * {
    width: 100%;
    min-width: 300px;
    max-width: 800px;
    margin-right: 10px;
  }
`

export const TwoItemsRow = styled.div<Props>`
  display: flex;
  width: 100%;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
  padding-top: ${({theme}) => theme.spacing(1)};
  & > * {
    width: 45%;
    min-width: 300px;
    max-width: 800px;
  }
`

export const NestedTwoItemsRow = styled.div<Props>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing(2)};
  padding-top: ${({theme}) => theme.spacing(1)};
  & > * {
    width: 45%;
    min-width: 100px;
    max-width: 400px;
  }
`

export const ThreeItemsRow = styled.div<Props>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
  padding-top: ${({theme}) => theme.spacing(1)};
  &:first-child {
    margin-top: 10px;
  }
  & > * {
    width: 32%;
    min-width: 100px;
    max-width: 800px;
  }
`

export const FourItemsRow = styled.div<Props>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  flex-wrap: wrap;
  width: 100%;
  height: 6rem;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
  &:first-child {
    margin-top: 10px;
  }

  & > * {
    width: 23.88%;
    min-width: 100px;
    max-width: 800px;
  }
`
