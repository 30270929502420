import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {LanguageCode} from './api/types'
import App from './App'
import {getTranslation} from './helpers/getTranslation'
import './index.css'
import store from './store'
import {setTranslation} from './store/language/actions'
import './types'


const systemLanguage = navigator.language === 'it-IT' ? 'it_IT' : 'en_EN'
const LANGUAGE = (sessionStorage.getItem('selectedTranslation') as LanguageCode) || systemLanguage

window.occlient?.setLogLevel('silly')

const RootComponent: React.FC = () => {
  return (
    <Provider store={store}>
      <App/>
    </Provider>
  )
}

const start = () => {
  getTranslation(LANGUAGE).then((data) => {
    store.dispatch(setTranslation(LANGUAGE, {...data}))
    const root = ReactDOM.createRoot(
      document.getElementById('bytewise-oc-dashboard') as HTMLElement
    )
    root.render(<RootComponent/>)
  })
}
start()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(logger.debug)
