import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow } from '@mui/material'
import { getTranslation } from '../../store/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

interface Props {
  show: boolean
  hide: (succes: boolean) => void
  title: string
  message: string
}

export const AlertDialog: React.FC<Props> = ({ show, hide, title, message }) => {
  const translation = useSelector(getTranslation)

  const handleClose = (success: boolean) => {
    hide(success)
  }

  return (
    <Dialog
      open={show}
      TransitionComponent={Grow}
      keepMounted
      onClose={(event, reason) => {
        handleClose(false)
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          {translation.alert.ok}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
