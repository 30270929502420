import {ActionName, LanguageCode, Role, TurnServer, Tenant} from '../../api/types'
import {StatsFavourite} from '../stats/types'

export const LOGIN = 'LOGIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const SET_TOKEN = 'SET_TOKEN'
export const RENEW_TOKEN = 'RENEW_TOKEN'
export const PAGE_REFRESHED = 'PAGE_REFRESHED'
export const SELECT_LOGIN_METHOD = 'SELECT_LOGIN_METHOD'
export const SET_LOGIN_METHODS = 'SET_LOGIN_METHODS'
export const RENEW_TOKEN_TIMEOUT = 'RENEW_TOKEN_TIMEOUT'

export interface LoginInfo {
  username: string
  password: string
}

export interface ResetPasswordInfo {
  username: string
}

export interface LoginMethod {
  id: number
  name: string
  authorize_url: string
  token_url: string
  client_id: string
  client_secret: string
  login_url: string
  type: string
}

export interface LoginState {
  user: LoggedUser | null
  token: string | null
  error: string | null
  isAuthenticated: boolean
  methods: LoginMethod[]
  selectedMethod: LoginMethod | null
  actions: Map<ActionName, Set<string>>
  otpRequired: boolean
  renewTimeout: null | number
}

//region Types

export interface LoggedUser {
  statsFavorites: StatsFavourite[]
  id: number
  profileId: string
  name: string
  surname: string
  roles: Role[]
  language: { code: LanguageCode, name: string }
  actions: { [key in ActionName]: string[] }
  theme: 'light' | 'dark',
  tenants: Tenant[]
}

export interface LoginPayload {
  user: LoggedUser
  token: string
  config: { turn: TurnServer; peers: string[] }
}

//endregion

//region Actions

export interface LoginAction {
  type: typeof LOGIN
  payload: LoginPayload
}

export interface LoginSuccessAction {
  type: typeof LOGIN
  payload: LoginState
}

export interface SetTokenAction {
  type: typeof SET_TOKEN
  payload: string
}

export interface SetRenewTimeout {
  type: typeof RENEW_TOKEN_TIMEOUT
  payload: number | null
}


export interface LogoutAction {
  type: typeof LOGOUT
}

export interface LoginErrorAction {
  type: typeof LOGIN_ERROR
  payload: string
}

export interface RenewTokenAction {
  type: typeof RENEW_TOKEN
  payload: LoginPayload
}

export interface PageRefreshedAction {
  type: typeof PAGE_REFRESHED
  payload: LoginPayload
}

export interface SelectLoginMethodAction {
  type: typeof SELECT_LOGIN_METHOD
  payload: LoginMethod | null
}

export interface SetLoginMethodsAction {
  type: typeof SET_LOGIN_METHODS
  payload: { methods: LoginMethod[]; otp: boolean }
}

export type LoginActionTypes =
  | LoginAction
  | LogoutAction
  | SetTokenAction
  | LoginErrorAction
  | RenewTokenAction
  | LoginSuccessAction
  | SelectLoginMethodAction
  | SetLoginMethodsAction
  | PageRefreshedAction
  | SetRenewTimeout


//endregion
