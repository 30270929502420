const isDev = process.env.NODE_ENV === 'development'
export const host = process.env.REACT_APP_HOST || window.location.origin
// Core
export const coreUrl = isDev ? process.env.REACT_APP_CORE_URL : window.location.origin
export const coreApi = `${coreUrl}/api`

export const socketUrl = `${coreUrl}`
export const socketPath = '/socket'

// Media
export const mediaUrl = isDev ? process.env.REACT_APP_MEDIA_URL : `${window.location.origin}/media`
export const mediaResources = `${mediaUrl}/resources`

// Stats
export const statsUrl = isDev ? process.env.REACT_APP_STATS_URL : `${window.location.origin}/history`
export const statsApi = `${statsUrl}`

// Monitoring panels
export const monitorsUrl = isDev ? process.env.REACT_APP_MONITORS_URL : `${window.location.origin}/monitors`

// Authentication
export const authUrl = isDev ? process.env.REACT_APP_AUTH_URL : `${host}/auth`

// Tranlations
export const translations = `/translations`

// Recordings (10485760 byte = 10MB)
export const maxRecordingFileSize = 52428800
export const maxFileSize = 52428800
export const RecordingAllowedFileType = {'audio/wav': ['.wav']}
