import {getErrorMessage} from '../../helpers'
import {ErrorMessage} from '@hookform/error-message'
import {Autocomplete, AutocompleteProps, ListItemText, MenuItem, Paper, TextField} from '@mui/material'
import {getTranslation} from '../../store/selectors'
import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react'
import {useController, UseControllerProps, useFormState} from 'react-hook-form'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {Hint} from './Hint'
import {FieldValues} from "react-hook-form/dist/types";
import {useTheme} from "@mui/material/styles";

interface OptionsType {
  key: string | number
  label: string
  value: string | number
  warn?: boolean
}

type Props<TFieldValues extends FieldValues> = Omit<UseControllerProps<TFieldValues>, 'defaultValue'> &
  Partial<AutocompleteProps<OptionsType, any, any, any>> & {
  label: string
  options: OptionsType[]
  menuPlacement?: 'bottom' | 'top'
  variant?: 'outlined' | 'filled' | 'standard'
  hint?: string
  leaveSpaceForHint?: boolean
  leaveSpaceForError?: boolean
  containerStyle?: React.CSSProperties
  hasPermission?: boolean
}

/** Componente comune per la AutocompleteSelect */
export function AutocompleteSelect<TFieldValues extends FieldValues>(props: PropsWithChildren<Props<TFieldValues>>) {
  const {
    variant = 'outlined',
    control,
    options,
    label,
    name,
    containerStyle,
    rules,
    menuPlacement,
    leaveSpaceForHint = true,
    leaveSpaceForError = true,
    hint,
    disabled,
    size = 'small',
    hasPermission,
    ...rest
  } = props

  const {field} = useController<TFieldValues>({control, name, rules})
  const {errors} = useFormState<TFieldValues>({control, name})

  const translation = useSelector(getTranslation)
  const themeObject = useTheme()

  const [value, setValue] = useState<OptionsType | null>(null)

  useEffect(() => {
    if (!options.length) return
    const newValue = field.value ? options.find((o) => field.value === o.value) || null : null
    setValue(newValue)
  }, [field.value, options])

  const isEnabled = useMemo((): boolean => {
    // inverte disabled se passato come prop
    const enabled = typeof disabled === 'boolean' ? !disabled : true
    // se non viene passato hasPermission mi affido alle altre prop
    if (hasPermission === undefined) {
      return enabled
    } else {
      // altrimenti deve anche avere il permesso oltre a dipendere dalle altre props
      return hasPermission && enabled
    }
  }, [hasPermission, disabled])

  const getMessage = (message = '') => getErrorMessage(translation, errors, name, message)

  const findFieldErrors = useCallback(() => {
    const [father, child, grandChild] = name.split('.')

    if(grandChild) return ((errors[father] as any)?.[child] as any)?.[grandChild]
    if (child) return ((errors[father] as any)?.[child] as any)
    return errors[father]
  }, [errors, name])

  return (
    <Container style={containerStyle} leaveSpaceForHint={leaveSpaceForHint} leaveSpaceForError={leaveSpaceForError}>
      <Autocomplete
        limitTags={3}
        size={size}
        disableCloseOnSelect
        disablePortal
        fullWidth
        {...rest}
        {...field}
        sx={{...(!!findFieldErrors() ? {fieldset: {borderColor: themeObject.palette.error.main}} : {})}}
        defaultValue={rest.defaultValue as OptionsType | null | undefined}
        disabled={!isEnabled}
        componentsProps={menuPlacement ? {
          popper: {
            placement: menuPlacement === 'bottom' ? 'bottom-start' : 'top-start'
          }
        } : {}}
        options={options}
        getOptionLabel={(option: OptionsType | string) => typeof option === 'string' ? option : option.label}
        value={value}
        PaperComponent={(props) => (<Paper sx={{
          background: 'background.paper',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'
        }} {...props}/>)}
        isOptionEqualToValue={(option, val) => (val ? option.value === val.value : false)}
        onChange={(_, data: any) => {
          field.onChange(data?.value)
        }}
        renderOption={(props, option) => (
          <MenuItem {...props} key={option.key} value={option.key}>
            <ListItemText primary={option.label} style={{marginLeft: '0.3rem'}} sx={{
              color: option.warn ? 'warning.main' : 'text.primary',
              '&[aria-selected="true"]': {color: option.warn ? 'warning.main' : 'text.primary'}
            }}/>
          </MenuItem>
        )}
        renderInput={({InputProps, ...params}) => (
          //InputProps stile textbox
          <TextField
            variant={variant}
            color="primary"
            {...params}
            fullWidth
            InputProps={{
              style: {color: 'text.primary', height: '100%'},
              readOnly: !isEnabled,
              ...InputProps
            }}
            label={label}
            style={{height: '100%', gridArea: 'input'}}
          />
        )}
      />
      <ErrorMessage errors={errors} name={name as any} render={({message}) => <Error>{getMessage(message)}</Error>}/>
      {hint && <Hint title={label} content={hint}/>}
    </Container>
  )
}

// region style
const Container = styled.div<{ leaveSpaceForHint: boolean; leaveSpaceForError: boolean }>`
    display: grid;
    grid-template-areas: 'input hint' 'error error';
    grid-template-columns: 1fr ${(props) => (props.leaveSpaceForHint ? '30px' : 'auto')};
    grid-template-rows: auto ${(props) => (props.leaveSpaceForError ? '20px' : 'auto')};
`

const Error = styled.div`
    grid-area: error;
    color: ${(props) => props.theme.palette.error.main};
    font-size: 0.8rem;
    margin-left: ${(props) => props.theme.spacing(1)};
`
// endregion
