import { amber, red as Ired, teal } from '@mui/material/colors'
import { darkTheme } from './darkTheme'
import { lightTheme } from './lightTheme'

export { lightTheme, darkTheme }

export const green = teal[600]
export const red = Ired[700]
export const blue = '#115e8b'
export const black = '#000000'
export const tableTitleColor = '#d4d4d4'
export const serviceAvatarColor = amber[100]

export const stateColor: { [key: string]: string } = {
  //IDLE
  '0': '#3aa619',
  //IN_CALL
  '1': '#d50d0d',
  //IN_CALL_IN
  '51': '#d50d0d',
  //IN_CALL_OUT
  '52': '#d50d0d',
  //BUSY_OUT
  '2': '#d50d0d',
  //DISCONNECTED
  '4': '#a5a5a5',
  //BUSY_IN
  '8': '#d50d0d',
  //ON_HOLD
  '16': '#3aa619',
  //Pause
  //ACW
  '100': '#2469a4',
  //Other PBX
  '101': '',
  //AFK
  '102': '#6c5274',
  //Training
  '103': '#dcb98b',
  //Meeting
  '104': '#9dceb6'
}
